import {
  JSXElementConstructor,
  ReactElement,
  ReactNodeArray,
  ReactPortal,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { SampleArrowLeft } from '../../components/slider/SampleArrowLeft';
import { SampleArrowRight } from '../../components/slider/SampleArrowRight';
import { useContextUser } from '../../contexts/user';
import { getBlockchainDataFromDB } from '../../utils/functions';
import { SettingsTypes } from '../../views/settings/account/Account';
import { OtherSettingPage } from '../../views/settings/account/OtherAccountSettings';
import { PopUp, PopupSize } from '../Popup';
import { DeleteModal, EditModal } from '../modals';
import { CardItem } from './CardItem';
import { TitleSection } from './TitleSection';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  slidesToShow: 3,
  nextArrow: <SampleArrowRight />,
  prevArrow: <SampleArrowLeft />,
  appendDots: (
    dots:
      | string
      | number
      | boolean
      // | {}
      | ReactElement<any, string | JSXElementConstructor<any>>
      | ReactNodeArray
      | ReactPortal
      | null
      | undefined,
  ) => (
    <div
      style={{
        borderRadius: '10px',
        padding: '10px 5px',
        fontWeight: 'bold',
      }}
    >
      <ul style={{ margin: '0px' }}> {dots} </ul>
    </div>
  ),
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 639,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type Props = {
  type: string;
  hasTitlePlus?: boolean;
};

const ContainerCard = ({ type, hasTitlePlus }: Props) => {
  const { t } = useTranslation(['common']);
  const [width, setWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [btnId, setBtnId] = useState<string>('');

  const { userData } = useContextUser();
  const [blockchains, setBlockchains] = useState<any>(undefined);

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    (async () => {
      const fetchedBlockchains = await getBlockchainDataFromDB();
      if (fetchedBlockchains) setBlockchains(fetchedBlockchains);
    })();

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const updateWidth = (): void => {
    setWidth(window.innerWidth);
  };

  const returnWallets = () => userData?.wallets || [];

  const returnSocialAccounts = () => userData?.socials ?? null;

  const returnBlockchainById = (chainUser: string) => {
    if (blockchains !== null && blockchains) {
      return blockchains.filter((chain: any) => {
        return chain.chain_id === chainUser;
      })[0];
    }
  };

  const numberOfWallets = (): number => returnWallets()?.length;

  const returnComponentsWallets = () => {
    return returnWallets()?.map((wallet) => {
      return (
        <CardItem
        getBtnId={setBtnId}
        this={wallet.walletAddress}
        openModal={openModal}
        key={Math.random() * 0.5}
        type={type}
        setModalType={setModalType}
        numberOfWallets={numberOfWallets()}
        titleCard={
          wallet?.chainId && returnBlockchainById(wallet.chainId)?.chain_desc
        }
        chainImage={
          wallet?.chainId &&
          (returnBlockchainById(wallet.chainId)?.image_url !== ''
            ? returnBlockchainById(wallet.chainId)?.image_url
            : './images/terra_icon.svg')
        }
        sectionTitle={wallet['walletAlias']}
        sectionText={wallet['walletAddress']}
      />
      );
    });
  };

  const hasSocials = () => returnSocialAccounts()?.length || 0;

  const returnComponentsSocials = () => {
    return returnSocialAccounts()?.map((social: any) => {
      if (social.status) {
        return (
      
            <CardItem
              getBtnId={setBtnId}
              this={social.type}
              setModalType={setModalType}
              openModal={openModal}
              key={Math.random() * 0.5}
              type={type}
              titleCard={social['type']}
              sectionTitle={social['username']}
            />
         
        );
      }
    });
  };

  const updateView = () => {
    returnComponentsWallets();
    returnComponentsSocials();
    setShowModal(false);
  };

  return (
    <div className={`w-full px-4 pt-2 sm:pb-8 pb-4`}>
      <div>
        <TitleSection hasTitlePlus={hasTitlePlus} type={type} />
      </div>
      {type === SettingsTypes.wallets &&
        ((width < 639 && numberOfWallets() > 2) || numberOfWallets() > 3 ? (
          <Slider {...settings} className='bg-transparent'>
            {returnComponentsWallets()}
          </Slider>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
            {returnComponentsWallets()}
          </div>
        ))}
      {type === SettingsTypes.otherSettings && (
        <>
          <OtherSettingPage />
        </>
      )}
      {type === SettingsTypes.socialAccounts &&
        (!hasSocials() ? (
          <div className='text-base text-gray-extralight90 pl-6 max-w-xs'>
            {t('settings.missingSocialAccount', { ns: 'common' })}
          </div>
        ) : hasSocials() > 3 || width < 639 ? (
          <Slider {...settings} className='bg-transparent'>
            {returnComponentsSocials()}
          </Slider>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
            {returnComponentsSocials()}
          </div>
        ))}

      {showModal && (
        <div className={`${!showModal ? 'hidden' : ''}`}>
          <PopUp
            closeModal={() => setShowModal(false)}
            size={PopupSize.smallxs}
          >
            {modalType === 'trash' ? (
              <DeleteModal
                id={btnId}
                title={
                  type === SettingsTypes.wallets
                    ? t('settings.walletDelete', { ns: 'common' })
                    : t('settings.deleteSocialAccount', { ns: 'common' })
                }
                textBtnCancel={t('action_cancel', { ns: 'common' })}
                textBtnSubmit={t('action_delete', { ns: 'common' })}
                setShowModal={setShowModal}
                type={
                  type === SettingsTypes.wallets
                    ? 'wallets'
                    : SettingsTypes.socialAccounts
                }
                updateView={updateView}
              />
            ) : (
              <EditModal
                updateView={updateView}
                type={
                  type === SettingsTypes.wallets
                    ? SettingsTypes.wallets
                    : SettingsTypes.socialAccounts
                }
                id={btnId}
                title={
                  type === SettingsTypes.wallets
                    ? t('settings.walletSettings', { ns: 'common' })
                    : t('settings.addNewWallet', { ns: 'common' })
                }
                inputLabel={t('settings.inputLabel', { ns: 'common' })}
                textbtn={t('action_save', { ns: 'common' })}
              />
            )}
          </PopUp>
        </div>
      )}
    </div>
  );
};

export default ContainerCard;
