import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import formats from './formats';

formats.forEach((format) => {
  ReactQuill.Quill.register('formats/' + format.blotName, format);
});

const QuillEditor = (props: ReactQuillProps) => {
  useEffect(() => {
    formats.forEach((format) => {
      const button = document.getElementsByClassName(
        'ql-' + format.blotName,
      )[0];
      if (button)
        createRoot(button).render(
          <FontAwesomeIcon icon={format.icon as IconDefinition} size='sm' />,
        );
    });
  }, []);

  return (
    <ReactQuill
      {...props}
      theme='snow'
      className='bg-white'
      modules={{
        // syntax: true,
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'spoiler'], // toggled buttons
          ['blockquote', 'code-block'],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean'], // remove formatting button
        ],
      }}
    />
  );
};

export default QuillEditor;
