import { useEffect, useState } from 'react';
import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import Loader, { LoaderType } from '../Loader';

import { useTranslation } from 'react-i18next';
import { useContextUser } from '../../contexts/user';
import { api } from '../../shared';
import { instanceOfUserData } from '../../utils/instanceOf';
import { hermesContactUsUrl } from '../../utils/menuUtils';
import { SettingsTypes } from '../../views/settings/account/Account';

type Props = {
  setShowModal: (show: boolean) => void;
  fn?: () => void;
  id?: string;
  title?: string | undefined;
  type?: string;
  description?: string;
  textBtnCancel?: string;
  textBtnSubmit?: string;
  fontWeight?: string;
  boldTitle?: string;
  updateView?: () => void;
};
export const SettingsDeleteModal = ({
  setShowModal,
  fn,
  id,
  title,
  type,
  description,
  textBtnCancel,
  textBtnSubmit,
  updateView,
  fontWeight,
}: Props) => {
  const { t } = useTranslation(['common']);
  const { setModal, clearModal } = useModalContext();
  const [firstModal, setFirstModal] = useState<boolean>(true);
  const { userData, setUser } = useContextUser();
  const [isLoading, setIsLoading] = useState(false);

  const deleteSocialsAccount = async () => {
    setIsLoading(true);
    if (!id) return;

    const response = await api.social.delete(
      {
        socialId: userData?.socials?.filter((s) => s.type === id)[0].id,
      },
      id,
    );

    if (typeof response === 'object' && instanceOfUserData(response)) {
      setUser && setUser(response);
    } else {
      setModal(
        '',
        `${t('error.error_occurred', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.log('Error: ', response?.data?.error ?? response?.error);
    }
    setTimeout(() => clearModal(), 1500);
    if (updateView) updateView();
    setIsLoading(false);
  };

  const deleteWallet = async () => {
    setIsLoading(true);
    if (!id) return;

    const response = await api.wallet.delete(id);

    if (typeof response === 'object' && instanceOfUserData(response)) {
      setUser && setUser(response);
    } else {
      setModal(
        '',
        `${t('error.error_occurred', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.error('Error: ', response?.data?.error ?? response?.error);
    }
    setTimeout(() => clearModal(), 1500);
    if (updateView) updateView();
    setIsLoading(false);
  };

  useEffect(() => {
    boldTransform(title);
  });

  const showWarningModal = () => {
    setFirstModal(false);
    setShowModal(false);
    setModal(
      t('modal_content.warning.title', { ns: 'enumerations' }),
      handleType(type),
      FeedbackType.warning,
      undefined,
      undefined,
      undefined,
      undefined,
      <div className='mt-6'>
        <div className='flex justify-end cursor-pointer'>
          <p className='mx-2 text-base underline' onClick={() => clearModal()}>
            {t('action_cancel', {
              ns: 'common',
            })}
          </p>
          <p
            className='mx-2 text-base underline text-gold-400'
            onClick={() =>
              (type === SettingsTypes.wallets && deleteWallet()) ||
              (type === SettingsTypes.socialAccounts &&
                deleteSocialsAccount()) ||
              (type === SettingsTypes.otherSettings && fn && fn())
            }
          >
            {t('action_delete', { ns: 'common' })}
          </p>
        </div>
      </div>,
    );
  };
  const handleClick = () => {
    fn && fn();
    (type === SettingsTypes.wallets || type === SettingsTypes.socialAccounts) &&
      showWarningModal();
  };

  const boldTransform = (title: string | undefined) => {
    if (title === undefined) return;
    let res = title.replace('[*', '<b>').replace('*]', '</b>');
    res = res.replace('[_', '<u>').replace('_]', '</u>');
    const el = document.getElementById('title');
    if (el) return (el.innerHTML = res);
  };

  const handleType = (type: any) => {
    if (type === 'wallets') {
      return t('modal_content.warning.delete_wallet', {
        ns: 'enumerations',
      });
    } else if (type === 'socialAccounts') {
      return t('modal_content.warning.delete_social_account', {
        ns: 'enumerations',
      });
    } else {
      return t('modal_content.warning.disable_mfa', {
        ns: 'enumerations',
      });
    }
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div>
        {firstModal && (
          <>
            <div
              className={`py-2 px-4 border-solid border-gray-300 rounded-t `}
            >
              <h5
                id='title'
                className={`w-full text-base ${
                  fontWeight && fontWeight
                } px-10-unset text-center`}
              >
                {boldTransform(title)}
              </h5>
              {description && (
                <div
                  className='text-center 
                mt-2 text-sm'
                >
                  <em>{description}</em>
                </div>
              )}
            </div>

            <div className='flex justify-evenly py-2 rounded-b'>
              <button
                className='text-white bg-gold-400 background-transparent px-6 py-2 text-base outline-none lg:hover:shadow-lg rounded focus:outline-none mb-1'
                type='button'
                onClick={() => setShowModal(false)}
              >
                {textBtnCancel ?? t('deleteModal.cancel', { ns: 'common' })}
              </button>
              <button
                className='text-black bg-gray-extralight60 active:bg-yellow-700 text-base px-6 py-2 rounded lg:hover:shadow-lg outline-none focus:outline-none mb-1'
                type='button'
                onClick={handleClick}
              >
                {textBtnSubmit === t('action_contact_us', { ns: 'common' }) && (
                  <a href={hermesContactUsUrl} target='_blank'>
                    {textBtnSubmit ?? t('action_delete', { ns: 'common' })}
                  </a>
                )}
                {(textBtnSubmit !== t('action_contact_us', { ns: 'common' }) &&
                  textBtnSubmit) ??
                  t('action_delete', { ns: 'common' })}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
