import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Make sure you have 'react-router-dom' installed
import { Tooltip } from './Tooltip';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './basics/Tabs';

type Props = {
  name: string;
  tabsInfo: TabsInfo;
  children: ReactNode;
};

export type TabsInfo = Record<string, Info>;

type Info = {
  url: string;
  name: string;
  disabled?: boolean;
};

export const TabMenu: React.FC<Props> = ({ name, tabsInfo, children }) => {
  const { t } = useTranslation(['enumerations']);

  return (
    <Tabs defaultValue={name} className='w-full'>
      <TabsList className='flex justify-evenly md:justify-start gap-6 md:pl-8 border-b-2 mb-8 border-gray-extralight60'>
        {Object.entries(tabsInfo).map(
          ([key, { url, name: tabName, disabled = false }]) => (
            <React.Fragment key={key}>
              {disabled ? (
                <Tooltip content={t('menu.coming_soon', { ns: 'common' })}>
                  <span className='z-10 flex cursor-not-allowed opacity-50'>
                    <TabsTrigger value={key} disabled={disabled}>
                      {t(tabName)}
                    </TabsTrigger>
                  </span>
                </Tooltip>
              ) : (
                <Link to={url} className='z-10 flex'>
                  <TabsTrigger value={key} disabled={disabled}>
                    {t(tabName)}
                  </TabsTrigger>
                </Link>
              )}
            </React.Fragment>
          ),
        )}
      </TabsList>
      <TabsContent value={name}>{children}</TabsContent>
    </Tabs>
  );
};
