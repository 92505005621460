import './i18n';
import './slick-theme.css';
import './style.css';

import { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import Loader, { LoaderType } from './components/Loader';
import ModalHandling from './components/ModalHandling';
import { ModalProvider } from './contexts/modal-handling';
import { UserProvider, useContextUser } from './contexts/user';
import { setCookies } from './utils';
import {
  broadcastCampaignAddUrl,
  broadcastCampaignEditUrl,
  broadcastCampaignStatisticUrl,
  broadcastCampaignsUrl,
  broadcastContactListAddUrl,
  broadcastContactListEditUrl,
  broadcastContactListUrl,
  settingsGeneralUrl,
  settingsBillingUrl,
  settingsTeamUrl,
  settingsAccountUrl,
  transactionalContactListAddUrl,
  transactionalContactListEditUrl,
  transactionalContactListUrl,
} from './utils/menuUtils';
import { CRUContactList } from './views/CRUContactList';
import { ContactList, ContactListScope } from './views/ContactList';
import { LoginPage } from './views/Login';
import { NotFoundErrorPage } from './views/NotFoundErrorPage';
import { AddCampaign } from './views/broadcast/AddCampaign';
import { CampaignStatistics } from './views/broadcast/CampaignStatistics';
import { Campaigns } from './views/broadcast/Campaigns';
import { EditCampaign } from './views/broadcast/EditCampaign';
import { BrandSettings} from './views/settings/General';
import { AccountSettings } from './views/settings/account/Account';
import { BillingSettings } from './views/settings/Billing';


function App() {
  /* Global variables that hold the complete user data. When no user is logged in, userData is null, thus user can't access private data.
  Together with the userData, on login and signup, API sets a cookie with auth token, which must be sent with every request, to validate the user. */

  const { userData, isLoggedIn } = useContextUser();
  const [searchParams] = useSearchParams();

  const refreshToken = searchParams.get('refreshToken') ?? undefined;

  if (refreshToken) setCookies('refreshToken', refreshToken);

  if (import.meta.env.VITE_API_URL === undefined)
    console.log('!!! Missing Environment variables !!!');

  return (
    <Suspense fallback=''>
      <section className='pb-0 antialiased overflow-x-hidden'>
        <div className='flex min-h-full w-screen items-start'>
          {/* Embracing all routes with a validation of the auth global variable allows us to await to check user before the page loads and assigning correct auths to user */}
          {isLoggedIn === null && <Loader type={LoaderType.fullScreen} />}
          {isLoggedIn === false && <Navigate to={'/'} />}
          {isLoggedIn !== null && (
            <Routes>
              <Route
                path={broadcastContactListUrl}
                element={
                  userData !== null ? (
                    <ContactList scope={ContactListScope.broadcast} />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              <Route
                path={broadcastContactListAddUrl}
                element={
                  userData !== null ? (
                    <CRUContactList scope={ContactListScope.broadcast} />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              <Route
                path={`${broadcastContactListEditUrl}/:id`}
                element={
                  userData !== null ? (
                    <CRUContactList scope={ContactListScope.broadcast} />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              <Route
                path={transactionalContactListUrl}
                element={
                  userData !== null ? (
                    <ContactList scope={ContactListScope.transactional} />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              <Route
                path={transactionalContactListAddUrl}
                element={
                  userData !== null ? (
                    <CRUContactList scope={ContactListScope.transactional} />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              <Route
                path={`${transactionalContactListEditUrl}/:id`}
                element={
                  userData !== null ? (
                    <CRUContactList scope={ContactListScope.transactional} />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              {/*<Route
                path={transactionalTemplatesUrl}
                element={
                  userData !== null ? <Templates /> : <Navigate to={"/"} />
                } />
              <Route
                path={transactionalTemplatesAddUrl}
                element={
                  userData !== null ? <AddTemplate /> : <Navigate to={"/"} />
                } />
              <Route
                path={transactionalTemplatesEditUrl}
                element={
                  userData !== null ? <EditTemplate /> : <Navigate to={"/"} />
                }/>*/}
              <Route
                path={broadcastCampaignsUrl}
                element={
                  userData !== null ? <Campaigns /> : <Navigate to={'/'} />
                }
              />
              <Route
                path={broadcastCampaignAddUrl}
                element={
                  userData !== null ? <AddCampaign /> : <Navigate to={'/'} />
                }
              />
              <Route
                path={`${broadcastCampaignEditUrl}/:id`}
                element={
                  userData !== null ? <EditCampaign /> : <Navigate to={'/'} />
                }
              />
              <Route
                path={broadcastCampaignStatisticUrl}
                element={
                  userData !== null ? (
                    <CampaignStatistics />
                  ) : (
                    <Navigate to={'/'} />
                  )
                }
              />
              <Route
                path='/'
                element={
                  userData !== null ? (
                    <Navigate to={broadcastContactListUrl} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              {/* <Route
                path={webhooksUrl}
                element={userData !== null ? <HistoryPage /> : <LoginPage />}

              /> */}
              <Route
                path={settingsGeneralUrl}
                element={
                  userData !== null ? <BrandSettings /> : <Navigate to={'/'} />
                }
                />
              <Route
                path={settingsBillingUrl}
                element={
                  userData !== null ? <BillingSettings /> : <Navigate to={'/'} />
                }
                />
              <Route
                path={settingsAccountUrl}
                element={
                  userData !== null ? 
                    <AccountSettings /> : <Navigate to={'/'} /> 
                }
              />
              {/* <Route
                path={pricingUrl}
                element={
                  userData !== null ? <PricingPage /> : <Navigate to={'/'} />
                }
              /> */}
              <Route path='*' element={<NotFoundErrorPage />} />
            </Routes>
          )}
        </div>
      </section>
      <ModalHandling />
    </Suspense>
  );
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN!,
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // }),
    // new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: import.meta.env.VITE_ENVIRONMENT,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Suspense fallback={<></>}>
      <CookiesProvider>
        <UserProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </UserProvider>
      </CookiesProvider>
    </Suspense>
  </BrowserRouter>,
);
