export const Badge: React.FC<{
  text: string;
  bgColor?: string;
  textColor?: string;
  extraClasses?: string;
}> = ({ text, bgColor = 'gold-400', textColor = 'white', extraClasses = '' }) => {
  return (
    <div
      className={`bg-${bgColor} text-${textColor} px-2 text-xs leading-4 rounded-10px text-center ${extraClasses}`}
    >
      {text}
    </div>
  );
};
