import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FeedbackType, useModalContext } from '../contexts/modal-handling';
import { useContextUser } from '../contexts/user';
import { Clients } from '../models/Interfaces';
import { PopupSize } from './Popup';
import NewOrganizationModal from './modals/NewOrganizationModal';

interface Option {
  clientId: string;
  brandName: string;
  profile: string;
  url: string;
}

const OrganizationMenuMobile: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { userData, selectOrganization, selectedOrganizationId } =
    useContextUser();
  const { setModal } = useModalContext();

  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[] | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  );
  const defaultProfileImage: string = '/images/account.svg';

  const loadOrganizations = async () => {
    if (!userData?.clients) return;

    let allOptions: Option[] = [];
    for (let i = 0; i < userData.clients.length; i++) {
      const client: Clients = userData.clients[i];
      const clientId: string = client.clientId;
      const brandName: string = client.brandName;
      const profile: string = client.imageUrl
        ? client.imageUrl
        : defaultProfileImage;
      allOptions.push({ clientId, brandName, profile, url: '' });
    }
    setOptions(allOptions);

    if (!selectedOrganizationId && allOptions.length)
      selectOrganization(allOptions[0].clientId);

    if (selectedOrganizationId)
      setSelectedOption(
        allOptions?.find((a) => a.clientId === selectedOrganizationId),
      );
  };

  useEffect(() => {
    loadOrganizations();
  }, [selectedOrganizationId, userData]);

  return (
    <div
      onClick={() => setShow(!show)}
      className='z-30'
    >
          <div className='relative w-20 h-20 flex justify-center items-center '>
            <img
              src={
                selectedOption?.profile
                  ? selectedOption.profile
                  : defaultProfileImage
              }
              alt='profile'
              className='absolute -top-4 bg-white w-20 h-20 rounded-full border-2 border-gold-400 shadow-xl'
            />
          </div>
      <div
        className={` absolute flex z-20 -top-24 mx-auto bg-white border border-gray-light70 rounded-xl ${
          !show && `hidden`
        } cursor-default`}
      >
        <div
          className={`w-full flex flex-row justify-center items-center text-center`}
        >
          {options?.map(
            (option) =>
              selectedOrganizationId && (
                <div
                  className={classNames(
                    'w-full flex justify-start items-center py-4 px-4 rounded bg-gold-200',
                    {
                      'active whitespace-nowrap overflow-hidden':
                        selectedOrganizationId === option.clientId,
                      'hover:cursor-pointer':
                        selectedOrganizationId !== option.clientId,
                    },
                  )}
                  onClick={
                    option.clientId === selectedOrganizationId
                      ? () => {}
                      : () => selectOrganization(option.clientId)
                  }
                  key={option.clientId}
                >
                  <div>
                    <div className='w-7 h-7 rounded-full overflow-hidden flex justify-center items-center'>
                      <img src={option?.profile} alt='profile' />
                    </div>
                  </div>
                </div>
              ),
          )}
          <div className='block w-full'>
            <div
              className='w-full flex items-center px-4 py-4 hover:cursor-pointer'
              onClick={() => {
                setModal(
                  t('organizationMenu.modal.newOrganization', { ns: 'common' }),
                  t('organizationMenu.modal.chooseOrganizationName', {
                    ns: 'common',
                  }),
                  FeedbackType.custom,
                  false,
                  () => {},
                  '',
                  PopupSize.medium,
                  <NewOrganizationModal />,
                  <FontAwesomeIcon icon={faBuilding as IconProp} />,
                );
              }}
            >
              <span className='text-gold-400  text-2xl'>+</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationMenuMobile;
