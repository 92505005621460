import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../../components/Button';
import { ImageUploader } from '../../components/ImageUploader';
import Input from '../../components/forms/Input';
import Frame, { Scope as TabsScope } from '../../components/layout/Frame';
import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { Clients } from '../../models/Interfaces';
import { api } from '../../shared';
import { handleImageSelected } from '../../utils/functions';
import { getValidationResult, validateObject } from '../../utils/validation';

interface FormData {
  brandName: string;
  websiteURL: string;
  termsURL: string;
  privacyURL: string;
  imageURL: string;
  isUploadingImage: boolean;
}

export const BrandSettings: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { setModal, setInfoModal } = useModalContext();
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);
  const { userData, setUser, selectedOrganizationId } = useContextUser();
  const [isLoading, setIsLoading] = useState(false);

  /*Form variables*/
  const [formData, setFormData] = useState<FormData>({
    brandName: '',
    websiteURL: '',
    termsURL: '',
    privacyURL: '',
    imageURL: '',
    isUploadingImage: false,
  });

  const [formValidation, setFormValidation] = useState<FormData>({
    brandName: '',
    websiteURL: '',
    termsURL: '',
    privacyURL: '',
    imageURL: '',
    isUploadingImage: false,
  });

  /*Form functions*/
  const handleFormChange = async (name: string, value: string) => {
    validateForm({ [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  /*Validation functions*/
  const validateForm = (fields: any = undefined) => {
    //Create updated validation (based on updated form)
    let updatedValidation;
    if (fields)
      updatedValidation = {
        ...formValidation,
        ...validateObject(t, fields, 'accountSettings.'),
      };
    else updatedValidation = validateObject(t, formData, 'accountSettings.');

    //Update the form validation
    setFormValidation({ ...formValidation, ...updatedValidation });

    //Return the result of the form validation
    return getValidationResult(updatedValidation);
  };

  /*CRUD functions*/
  const handleFormImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({ ...formData, isUploadingImage: true });
    const imageUrl = await handleImageSelected(e);
    if (imageUrl === undefined) {
      setFormData({ ...formData, isUploadingImage: false });
      setModal(
        t('fetch.upload.image.failure.title', { ns: 'common' }),
        t('fetch.upload.image.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
    } else if (imageUrl === 1) {
      setFormData({ ...formData, isUploadingImage: false });
      setModal(
        t('fetch.upload.image.extensionFailure.title', { ns: 'common' }),
        t('fetch.upload.image.extensionFailure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
    } else
      setFormData({ ...formData, isUploadingImage: false, imageURL: imageUrl });
  };

  const handleRemoveImage = () => {
    setFormData({ ...formData, imageURL: '' });
  };

  const handleSaveSettings = async () => {
    //Validate the form
    const isFormInvalid = validateForm();
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (isFormInvalid || !selectedOrganization) return;
    setIsLoading(true);

    const response = await api.client.update(
      {
        brandName: formData.brandName,
        website: formData.websiteURL,
        imageUrl: formData.imageURL,
        termsService: formData.termsURL,
        privacyPolicy: formData.privacyURL,
      },
      selectedOrganization.clientId,
    );

    if (response?.error) {
      setModal(
        t('fetch.accountSettings.update.failure.title', { ns: 'common' }),
        t('fetch.accountSettings.update.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
      console.log('Error: ', response.error);
    } else {
      setUser(response);
      handleBrandNamePopupState();
      setModal(
        t('fetch.accountSettings.update.success.title', { ns: 'common' }),
        t('fetch.accountSettings.update.success.text', { ns: 'common' }),
        FeedbackType.success,
        true,
      );
    }

    setIsLoading(false);
  };

  const handleBrandNamePopupState = async () => {
    if (!userData) return;

    await api.user.update({ noBrandNamePopup: true }, userData.userId);
  };

  useEffect(() => {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (selectedOrganization)
      setFormData({
        ...formData,
        brandName: selectedOrganization?.brandName
          ? selectedOrganization.brandName
          : '',
        websiteURL: selectedOrganization?.website
          ? selectedOrganization.website
          : '',
        termsURL: selectedOrganization?.termsService
          ? selectedOrganization.termsService
          : '',
        privacyURL: selectedOrganization?.privacyPolicy
          ? selectedOrganization.privacyPolicy
          : '',
        imageURL: selectedOrganization.imageUrl
          ? selectedOrganization.imageUrl
          : '',
      });
  }, [userData, selectedOrganizationId]);

  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <Frame name={'general'} scope={TabsScope.settings}>
      <div className='relative md:w-full flex items-center md:px-6 px-4 sm:max-w-6xl'>
        <div className='sm:relative w-full md:w-1/2 mx-4 gap-6 flex flex-col'>
          <h1 className='mt-2  w-full justify-between'>
            {t(`settings.accountSettings`, { ns: 'common' })}
          </h1>

          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.brandName', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) => handleFormChange('brandName', e.target.value)}
              value={
                formData.brandName ||
                t('organizationMenu.defaultName', { ns: 'common' })
              }
              error={formValidation.brandName}
              />
          </div>
          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.website', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) => handleFormChange('websiteURL', e.target.value)}
              value={formData.websiteURL}
              error={formValidation.websiteURL}
              placeholder='https://example.com'
            />
          </div>
          <div >
            <h5 className='font-medium mb-2 '>
              {t('settings.brandLogo', { ns: 'common' })}
            </h5>
            <ImageUploader
              imageUrl={formData.imageURL}
              uploadingImage={formData.isUploadingImage}
              onImageSelected={handleFormImageChange}
              onRemoveImageClick={handleRemoveImage}
            />
          </div>
          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.terms', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) => handleFormChange('termsURL', e.target.value)}
              value={formData.termsURL}
              error={formValidation.termsURL}
              placeholder='https://example.com/termsofservice'
                />
          </div>
          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.privacyPolicy', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) => handleFormChange('privacyURL', e.target.value)}
              value={formData.privacyURL}
              error={formValidation.privacyURL}
              placeholder='https://example.com/privacypolicy'
            />
          </div>
          <div className='button-wrapper mt-8  mb-20 md:mb-0 flex '>
            <div className='w-32 mr-3 '>
              <Button
                type={ButtonType.primary}
                size={ButtonSize.medium}
                justify={ButtonJustify.center}
                fullWidth={true}
                onClick={handleSaveSettings}
                disabled={formData.isUploadingImage}
              >
                {t('action_save', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
};
