import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopUp, PopupSize } from '../Popup';
import { DeleteModal } from '../modals';

export const DeleteAccount: React.FC = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const [modalDeleteAccount, setModalDeleteAccount] = useState<boolean>(false);
  const [modalDeleteAccountConfirm, setModalDeleteAccountConfirm] =
    useState<boolean>(false);

  /* const showModalConfirm = () => {
    if (modalDeleteAccountConfirm) DeleteAccount();
    setModalDeleteAccount(false);
    setModalDeleteAccountConfirm(true);
  }; */

  const returnModal = (
    $text = t('settings.deleteAccountRedirect', { ns: 'common' }),
    textBtnSubmit = t('action_contact_us', { ns: 'common' }),
  ) => {
    return (
      <>
        <DeleteModal
          fontWeight='font-semibold'
          title={$text}
          setShowModal={
            modalDeleteAccount
              ? setModalDeleteAccount
              : setModalDeleteAccountConfirm
          }
          fn={() => setModalDeleteAccount(false)}
          textBtnSubmit={textBtnSubmit}
        />
      </>
    );
  };

  return (
    <div className='px-2 pr-8 py-4 sm:w-3/6'>
      <h4 className='mb-8'>{t(`settings.dangerousZone`, { ns: 'common' })}</h4>
      <div className='relative'>
        <p className='absolute left-0 text-sxm text-gray-500'>
          {`${t('modal_content.delete_account', { ns: 'enumerations' })} `}
        </p>
        <button
          className='absolute right-2 sm:right-0 text-gold-400'
          onClick={() => setModalDeleteAccount(true)}
        >
          {t('action_delete', { ns: 'common' })}
        </button>
      </div>
      {modalDeleteAccount && (
        <PopUp
          closeModal={() => setModalDeleteAccount(false)}
          size={PopupSize.smallxs}
        >
          {returnModal()}
        </PopUp>
      )}
      {/* {modalDeleteAccountConfirm && (
        <PopUp closeModal={() => setModalDeleteAccountConfirm(false)}>
          {returnModal(
            t("settings.deleteAccountConfirm", { ns: "common" }),
            t("settings.deleteAccountButton", { ns: "common" })
          )}
        </PopUp>
      )} */}
    </div>
  );
};
