import { APIService } from './api.service';

export class UserService extends APIService {
  constructor() {
    super();
  }

  public async update(data: any, userId: string): Promise<any> {
    return await this._patch(`/fauna/user/${this.origin}`, data);
  }
}
