import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FeedbackType, useModalContext } from '../contexts/modal-handling';
import { useContextUser } from '../contexts/user';
import { Clients } from '../models/Interfaces';
import { PopupSize } from './Popup';
import NewOrganizationModal from './modals/NewOrganizationModal';

interface Option {
  clientId: string;
  brandName: string;
  profile: string;
  url: string;
}

const OrganizationMenu: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { userData, selectOrganization, selectedOrganizationId } =
    useContextUser();
  const { setModal } = useModalContext();

  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[] | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  );
  const defaultProfileImage: string = '/images/account.svg';

  const loadOrganizations = async () => {
    if (!userData?.clients) return;

    let allOptions: Option[] = [];
    for (let i = 0; i < userData.clients.length; i++) {
      const client: Clients = userData.clients[i];
      const clientId: string = client.clientId;
      const brandName: string = client.brandName;
      const profile: string = client.imageUrl
        ? client.imageUrl
        : defaultProfileImage;
      allOptions.push({ clientId, brandName, profile, url: '' });
    }
    setOptions(allOptions);

    if (!selectedOrganizationId && allOptions.length)
      selectOrganization(allOptions[0].clientId);

    if (selectedOrganizationId)
      setSelectedOption(
        allOptions?.find((a) => a.clientId === selectedOrganizationId),
      );
  };

  useEffect(() => {
    loadOrganizations();
  }, [selectedOrganizationId, userData]);

  return (
    <div
      onClick={() => setShow(!show)}
      className='z-30 w-full relative pl-4 pr-3 flex justify-center items-start bg-white rounded text-center text-sm font-bold cursor-pointer transition-all border border-transparent hover:border-gold-400 hover:shadow-md'
    >
      <p className='w-full justify-between flex items-center py-2 border-gray-light70'>
        <div className='flex items-center'>
          <div className='w-8 h-8 mr-3 rounded-full overflow-hidden flex justify-center items-center'>
            <img
              src={
                selectedOption?.profile
                  ? selectedOption.profile
                  : defaultProfileImage
              }
              alt='profile'
            />
          </div>
          <span>
            {selectedOption?.brandName
              ? `${selectedOption.brandName.substring(0, 16)}${
                  selectedOption.brandName !==
                  selectedOption.brandName.substring(0, 16)
                    ? '...'
                    : ''
                }`
              : t('organizationMenu.defaultName', { ns: 'common' })}
          </span>
        </div>
        <div
          className={`w-4 h-4 ml-3 text-base font-semibold flex items-center ${
            show ? `text-gold-400` : `text-gray-light50`
          }`}
        >
          {!show ? (
            <FontAwesomeIcon icon={faChevronUp as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown as IconProp} />
          )}
        </div>
      </p>
      <div
        className={`w-full absolute z-20 top-12 left-0 bg-white border border-gray-light70 rounded-b ${
          !show && `hidden`
        } cursor-default`}
      >
        <div
          className={`w-full flex flex-col justify-center items-center text-center`}
        >
          {options?.map(
            (option) =>
              selectedOrganizationId && (
                <div
                  className={classNames(
                    'w-full flex justify-start items-center py-4 pl-7 pr-4 rounded organizationMenu-item',
                    {
                      'active whitespace-nowrap overflow-hidden text-ellipsis':
                        selectedOrganizationId === option.clientId,
                      'hover:cursor-pointer':
                        selectedOrganizationId !== option.clientId,
                    },
                  )}
                  onClick={
                    option.clientId === selectedOrganizationId
                      ? () => {}
                      : () => selectOrganization(option.clientId)
                  }
                  key={option.clientId}
                >
                  <div>
                    <div className='w-7 h-7 mr-3 rounded-full overflow-hidden flex justify-center items-center'>
                      <img src={option?.profile} alt='profile' />
                    </div>
                  </div>
                  <div className='"w-full font-semibold text-left'>
                    <span>{`${option.brandName.substring(0, 16)}${
                      option.brandName !== option.brandName.substring(0, 16)
                        ? '...'
                        : ''
                    }`}</span>
                  </div>
                </div>
              ),
          )}
        </div>
        <div className='py-2 px-4'>
          <div className='block w-full py-2'>
            <div className='border-b border-gray-light70' />
          </div>
          <div className='block w-full'>
            <div
              className='w-full flex items-center justify-start pl-7 hover:cursor-pointer'
              onClick={() => {
                setModal(
                  t('organizationMenu.modal.newOrganization', { ns: 'common' }),
                  t('organizationMenu.modal.chooseOrganizationName', {
                    ns: 'common',
                  }),
                  FeedbackType.custom,
                  false,
                  () => {},
                  '',
                  PopupSize.medium,
                  <NewOrganizationModal />,
                  <FontAwesomeIcon icon={faBuilding as IconProp} />,
                );
              }}
            >
              <span className='text-gold-400  text-2xl pb-2 mr-4'>+</span>
              <span className='text-gray-light50'>
                {t('organizationMenu.addOrganization', { ns: 'common' })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationMenu;
