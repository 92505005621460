import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface breadcrumbStructure {
  label: string;
  url?: string;
}

const Breadcrumb: React.FC<{ list: breadcrumbStructure[] }> = ({ list }) => {
  return (
    <nav className='font-medium flex items-center text-gray-light60'>
      {list.map((item: breadcrumbStructure, index) => {
        return (
          <>
            {item.url ? (
              <Link
                to={item.url}
                className={classNames('hover:underline', {
                  'text-gray-light50': index === list.length - 1,
                })}
              >
                {item.label}
              </Link>
            ) : (
              <span
                className={classNames({
                  'text-gray-light40': index === list.length - 1,
                })}
              >
                {item.label}
              </span>
            )}
            {index < list.length - 1 && (
              <FontAwesomeIcon
                className='ml-2 mr-2 text-xs'
                icon={faChevronRight as IconProp}
              />
            )}
          </>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;
