import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SampleArrowLeft: React.FC = (props: any) => {
  return (
    <div
      onClick={props.onClick}
      className={`absolute top-0 md:left-0 -left-7 bottom-0 w-10 btn btn-primary left z-10 ${
        props.children ?? ''
      }`}
    >
      <FontAwesomeIcon
        className={`absolute 
          left-4"
          transform top-0 ${
            props.children ?? 'translate-y-16'
          } text-3xl text-gray-400 `}
        icon={faAngleLeft as IconProp}
      ></FontAwesomeIcon>
    </div>
  );
};
