import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faComments,
  faPlus,
  faTasks,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SettingsTypes } from '../../views/settings/account/Account';
import { Button, ButtonSize, ButtonType } from '../Button';
import Icon from '../Icon';
import { PopUp, PopupSize } from '../Popup';
import { SocialAccounts, WalletsFlow } from '../modals';

type Props = {
  type?: string;
  dynamicTitle?: string;
  stylingTitle?: string;
  hasTitleIcon?: boolean;
  hasTitlePlus?: boolean;
};

export const TitleSection = ({
  type,
  hasTitleIcon = true,
  hasTitlePlus,
  stylingTitle,
  dynamicTitle,
}: Props) => {
  const { t } = useTranslation(['common', 'enumerations', '']);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div className='flex'>
        <div className='flex w-full'>
          {hasTitleIcon && (
            <div
              className={classNames(
                'flex justify-center items-center mr-2 border-2 border-white rounded-full h-9 w-9',
                { 'bg-walletsContainer': type === SettingsTypes.wallets },
                {
                  'bg-socialAccountsContainer':
                    type === SettingsTypes.socialAccounts,
                },
                {
                  'bg-otherSettingContainer':
                    type === SettingsTypes.otherSettings,
                },
              )}
            >
              <FontAwesomeIcon
                className={classNames(
                  '',
                  {
                    'text-walletsIcon ': type === SettingsTypes.wallets,
                  },
                  {
                    'text-socialAccountsIcon ':
                      type === SettingsTypes.socialAccounts,
                  },
                  {
                    'text-otherSettingIcon ':
                      type === SettingsTypes.otherSettings,
                  },
                )}
                icon={
                  type === SettingsTypes.wallets
                    ? (faWallet as IconProp)
                    : type === SettingsTypes.socialAccounts
                    ? (faComments as IconProp)
                    : (faTasks as IconProp)
                }
              />
            </div>
          )}
          <h4 className={`${stylingTitle && stylingTitle}`}>
            {dynamicTitle && dynamicTitle}
          </h4>
          <h4 className={classNames('my-2 text-black h-3')}>
            {type === SettingsTypes.wallets &&
              t('settings.title.wallets', { ns: 'common' })}
            {type === SettingsTypes.socialAccounts &&
              t('settings.title.social_accounts', { ns: 'common' })}
            {type === SettingsTypes.otherSettings &&
              t('settings.title.other_settings', { ns: 'common' })}
          </h4>
        </div>
        {hasTitlePlus && (
          <Button
            onClick={(e) => {
              setShowModal(true);
            }}
            type={ButtonType.backgroundNone}
            size={ButtonSize.clear}
            className={`mx-2 mb-1 flex p-0 m-0 disabled:shadow-lg cursor:pointer  `}
          >
            <Icon
              icon={faPlus as IconProp}
              shadowIcons={true}
              fontSize='text-base'
              textColor='text-gold-400'
              fontMobile='text-base'
              className='w-10 h-10 '
            />
          </Button>
        )}

        {showModal && (
          <PopUp closeModal={() => setShowModal(false)} size={PopupSize.medium}>
            {type === SettingsTypes.socialAccounts && <SocialAccounts />}
            {type === SettingsTypes.wallets && (
              <WalletsFlow closeModalMain={() => setShowModal(false)} />
            )}
          </PopUp>
        )}
      </div>
    </>
  );
};
