import React, { ReactNode } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type Settings = {
  translateArrowY?: string;
  children?: ReactNode;
};

export const SampleArrowRight: React.FC = (props: any & Settings) => {
  return (
    <div
      onClick={props.onClick}
      className={`absolute md:right-0 -right-7 top-0 bottom-0 w-10 btn btn-primary z-10 right ${
        props.children ?? ''
      }`}
    >
      <FontAwesomeIcon
        className={`absolute right-0 text-gray-400 transform top-0 ${
          props.children ?? 'translate-y-16'
        } text-3xl`}
        icon={faAngleRight as IconProp}
      ></FontAwesomeIcon>
    </div>
  );
};
