import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Frame, { Scope as TabsScope } from '../../../components/layout/Frame.js';
import ContainerCard from '../../../components/settingsCards/ContainerCard.js';


export enum SettingsTypes {
  otherSettings = 'otherSettings',
  wallets = 'wallets',
  socialAccounts = 'socialAccounts',
}

export enum ModalState {
  hasSocial = 'hasSocial',
}

export const AccountSettings: React.FC = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);

  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <Frame name={'myAccount'} scope={TabsScope.settings}>
      <div className='relative md:w-full flex items-center md:px-6 pb-8 max-w-6xl'>
        <div className='px-2 md:px-0 center flex-col w-full'>
          <section className='flex flex-col w-full'>
            <h1 className='mt-2 w-full justify-between mb-3 ml-4'>
              {t(`menu.accountSettings`, { ns: 'common' })}
            </h1>
            <ContainerCard hasTitlePlus={true} type={SettingsTypes.wallets} />
            <ContainerCard
              hasTitlePlus={true}
              type={SettingsTypes.socialAccounts}
            />
            <ContainerCard type={SettingsTypes.otherSettings} />
          </section>
        </div>
      </div>
    </Frame>
  );
};
