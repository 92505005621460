import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import discord from '../assets/images/discord.svg';
import telegram from '../assets/images/telegram.svg';
import BottomMenu from '../components/BottomMenu';
import Breadcrumb, { breadcrumbStructure } from '../components/Breadcrumb';
import { CopyButton } from '../components/CopyButton';
import { ImageUploader } from '../components/ImageUploader';
import { Navbar } from '../components/Navbar';
import RightSection from '../components/RightSection';
import Input, { InputType } from '../components/forms/Input';
import { useContextUser } from '../contexts/user';

import {
  Button,
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../components/Button';
import Loader, { LoaderType } from '../components/Loader';
import { FeedbackType, useModalContext } from '../contexts/modal-handling';
import {
  Clients,
  ContactListForm,
  ContactListLine,
} from '../models/Interfaces';
import {
  createContactList,
  handleImageSelected,
  updateContactList,
} from '../utils/functions';
import {
  broadcastContactListUrl,
  transactionalContactListUrl,
} from '../utils/menuUtils';
import { getValidationResult, validateObject } from '../utils/validation';
import { ContactListScope } from './ContactList';

enum CRUDOperation {
  create = 'create',
  update = 'update',
}
interface FormData {
  namePublic: string;
  nameInternal: string;
  description: string;
}
export const CRUContactList: React.FC<{ scope: string }> = ({ scope }) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { userData, setUser, selectedOrganizationId } = useContextUser();
  const { id } = useParams();
  const navigate = useNavigate();
  const { setModal, clearModal, setInfoModal } = useModalContext();

  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [operation, setOperation] = useState<CRUDOperation | undefined>(
    undefined,
  );
  const [origData, setOrigData] = useState<ContactListLine | undefined>(
    undefined,
  );
  const [formData, setFormData] = useState<ContactListForm>({
    namePublic: '',
    nameInternal: '',
    description: '',
    imageUrl: '',
    scope: scope,
  });
  const [formValidation, setFormValidation] = useState<FormData>({
    namePublic: '',
    nameInternal: '',
    description: '',
  });
  const breadcrumb: breadcrumbStructure[] = [
    {
      label:
        scope === ContactListScope.broadcast
          ? t('breadcrumb.broadcast', { ns: 'enumerations' })
          : t('breadcrumb.transactional', { ns: 'enumerations' }),
    },
    {
      label: t('breadcrumb.contactLists', { ns: 'enumerations' }),
      url:
        scope === ContactListScope.broadcast
          ? broadcastContactListUrl
          : transactionalContactListUrl,
    },
    {
      label:
        operation === CRUDOperation.create || operation === undefined
          ? t('breadcrumb.create', { ns: 'enumerations' })
          : formData.nameInternal,
    },
  ];
  const [copy, setCopy] = useState<string | null>(null);

  const copyToClipboard = (word: string) => {
    navigator.clipboard.writeText(word);
    setCopy(word);
  };

  //Set operation
  if (operation === undefined) {
    if (id) {
      const selectedOrganization: Clients | undefined = userData?.clients?.find(
        (a) => a.clientId === selectedOrganizationId,
      );
      setOperation(CRUDOperation.update);
      //Set form data
      const contactListEntries: ContactListLine[] | undefined = userData
        ?.clients?.length
        ? selectedOrganization?.contactLists
            ?.map((contactList) => {
              const entry: ContactListLine = {
                listId: contactList.listId,
                listName: contactList.namePublic,
                nameInternal: contactList.nameInternal,
                description: contactList.description,
                imageUrl: contactList.imageUrl,
                campaignsSent: '-',
                totalWallets: contactList?.subscribersAmount?.total
                  ? contactList.subscribersAmount.total
                  : 0,
                discordWallets: contactList?.subscribersAmount?.discord
                  ? contactList.subscribersAmount.discord
                  : 0,
                telegramWallets: contactList?.subscribersAmount?.telegram
                  ? contactList.subscribersAmount.telegram
                  : 0,
                broadcast: contactList.broadcast,
              };
              return entry;
            })
            .filter(
              (contactList: ContactListLine) => contactList?.listId === id,
            )
        : undefined;
      const contactList: ContactListLine | undefined =
        contactListEntries?.length ? contactListEntries[0] : undefined;
      if (contactList === undefined)
        navigate(
          scope === ContactListScope.broadcast
            ? broadcastContactListUrl
            : transactionalContactListUrl,
        );
      setOrigData(contactList);
      setFormData({
        namePublic: contactList?.listName ? contactList.listName : '',
        nameInternal: contactList?.nameInternal ? contactList.nameInternal : '',
        description: contactList?.description ? contactList.description : '',
        imageUrl: contactList?.imageUrl ? contactList.imageUrl : '',
        scope: scope,
      });
    } else {
      setOperation(CRUDOperation.create);
    }
  }

  //Form change events
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFormImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUploadingImage(true);
    const imageUrl = await handleImageSelected(e);
    if (imageUrl === undefined)
      setModal(
        t('fetch.upload.image.failure.title', { ns: 'common' }),
        t('fetch.upload.image.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
    else if (imageUrl === 1)
      setModal(
        t('fetch.upload.image.extensionFailure.title', { ns: 'common' }),
        t('fetch.upload.image.extensionFailure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
    else setFormData({ ...formData, imageUrl: imageUrl });
    setUploadingImage(false);
  };

  const handleSubmitForm = async () => {
    //Validate the form
    const isFormInvalid = validateForm();
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (isFormInvalid || !selectedOrganization) return;
    try {
      //Validate form
      if (
        !formData.nameInternal ||
        !formData.namePublic ||
        !formData.description
      )
        return;
      //CRUD
      setIsLoading(true);
      //Create
      if (operation === CRUDOperation.create) {
        if (!userData) {
          setModal(
            t('fetch.contactList.create.failure.title', { ns: 'common' }),
            t('fetch.contactList.create.failure.text', { ns: 'common' }),
            FeedbackType.failure,
            true,
            () =>
              navigate(
                scope === ContactListScope.broadcast
                  ? broadcastContactListUrl
                  : transactionalContactListUrl,
              ),
          );
          return;
        }
        const result = await createContactList(
          userData,
          selectedOrganizationId,
          formData,
        );

        if (result) {
          setUser && setUser(result);
          setInfoModal(
            t('contactList.listCreatedTitle', { ns: 'common' }),
            <div className='text-left py-4'>
              <ol className='list-decimal ml-7'>
                <li>
                  {t('contactList.inviteBots', { ns: 'common' })}
                  <div className='flex justify-center mb-6 mt-3'>
                    <a
                      href='https://hermesprotocol.io/r/discord-bot-invite'
                      target='_blank'
                    >
                      <Button
                        type={ButtonType.cancel}
                        size={ButtonSize.medium}
                        className='w-48 mx-4'
                      >
                        {t('social_channels.DISCORD', { ns: 'enumerations' })}
                      </Button>
                    </a>
                    <a href='https://t.me/HermesProtocolBot' target='_blank'>
                      <Button
                        type={ButtonType.cancel}
                        size={ButtonSize.medium}
                        className='w-48 mx-4'
                      >
                        {t('social_channels.TELEGRAM', { ns: 'enumerations' })}
                      </Button>
                    </a>
                  </div>
                </li>
                <li>
                  {t('contactList.useCommands', { ns: 'common' })}
                  <div className='flex items-center mt-4 mb-3'>
                    <img
                      src={discord}
                      className={classNames('inline w-7 mr-5')}
                      alt='Discord icon'
                    />
                    <div
                      className='flex items-center relative w-full cursor-pointer'
                      onClick={() =>
                        copyToClipboard(
                          t('contactList.discordCommand1', { ns: 'common' }),
                        )
                      }
                    >
                      <div className='px-2 cursor-pointer border rounded py-1 border-gray-extralight90medium text-black-transparent50 w-full'>
                        {t('contactList.discordCommand1', { ns: 'common' })}
                        <span className='ml-2 text-gray-dark'>
                          {t('contactList.discordCommand2', { ns: 'common' })}
                        </span>
                      </div>
                      <CopyButton
                        hoverText={t('copy', 'common')}
                        clickText={t('copied', 'common')}
                        isCopying={
                          copy ===
                          t('contactList.discordCommand1', { ns: 'common' })
                            ? true
                            : false
                        }
                        onCopy={copyToClipboard}
                        copiedElement={t('contactList.discordCommand1', {
                          ns: 'common',
                        })}
                      />
                    </div>
                  </div>
                  <span className='inline-block bg-gold20 rounded px-3 py-2 text-sm ml-12'>
                    <span className='flex'>
                      <span
                        className={classNames(
                          'ml-1 mr-3 bg-gold-400 text-white rounded-full h-4.5 w-4.5 cursor-pointer hover:bg-gray-light50 focus:bg-gray-light50 justify-center flex items-center text-xs',
                        )}
                      >
                        !
                      </span>
                      {t('contactList.contactListInfo', { ns: 'common' })}
                    </span>
                  </span>
                  <div className='flex items-center mt-10 mb-1'>
                    <img
                      src={telegram}
                      className={classNames('inline w-7 mr-5')}
                      alt='Telegram icon'
                    />
                    <div
                      className='flex items-center relative w-full cursor-pointer'
                      onClick={() =>
                        copyToClipboard(
                          t('contactList.telegramCommand', { ns: 'common' }),
                        )
                      }
                    >
                      <div className='px-2 cursor-pointer border rounded py-1 border-gray-extralight90medium text-black-transparent50 w-full'>
                        {t('contactList.telegramCommand', { ns: 'common' })}
                      </div>
                      <CopyButton
                        hoverText={t('copy', 'common')}
                        clickText={t('copied', 'common')}
                        isCopying={
                          copy ===
                          t('contactList.telegramCommand', { ns: 'common' })
                            ? true
                            : false
                        }
                        onCopy={copyToClipboard}
                        copiedElement={t('contactList.telegramCommand', {
                          ns: 'common',
                        })}
                      />
                    </div>
                    <div></div>
                  </div>
                </li>
              </ol>
              <p className='mt-8 text-gray-light50 max-w-xl'>
                {t('contactList.moreOnDocumentation', { ns: 'common' })}
              </p>
            </div>,
            () =>
              navigate(
                scope === ContactListScope.broadcast
                  ? broadcastContactListUrl
                  : transactionalContactListUrl,
              ),
          );
        } else
          setModal(
            t('fetch.contactList.create.failure.title', { ns: 'common' }),
            t('fetch.contactList.create.failure.text', { ns: 'common' }),
            FeedbackType.failure,
            true,
            () =>
              navigate(
                scope === ContactListScope.broadcast
                  ? broadcastContactListUrl
                  : transactionalContactListUrl,
              ),
          );
      }
      //Update
      else {
        if (!userData || !id) {
          setModal(
            t('fetch.contactList.update.failure.title', { ns: 'common' }),
            t('fetch.contactList.update.failure.text', { ns: 'common' }),
            FeedbackType.failure,
            true,
          );
          return;
        }
        const result = await updateContactList(
          userData,
          selectedOrganizationId,
          formData,
          id,
        );

        if (result) {
          setUser(result);
          setModal(
            t('fetch.contactList.update.success.title', { ns: 'common' }),
            t('fetch.contactList.update.success.text', { ns: 'common' }),
            FeedbackType.success,
            true,
          );
          setTimeout(() => {
            clearModal();
            navigate(
              scope === ContactListScope.broadcast
                ? broadcastContactListUrl
                : transactionalContactListUrl,
            );
          }, 2000);
        } else
          setModal(
            t('fetch.contactList.update.failure.title', { ns: 'common' }),
            t('fetch.contactList.update.failure.text', { ns: 'common' }),
            FeedbackType.failure,
            true,
          );
      }
    } catch (error) {
      setModal(
        '',
        `${t('error.error_occurred', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.log('Error: ', error);
    }
    setIsLoading(false);
  };
  /*Form functions*/
  const handleFormChange = async (name: string, value: string) => {
    validateForm({ [name]: value });
    setFormData({ ...formData, [name]: value });
  };
  /*Validation functions*/
  const validateForm = (fields: any = undefined) => {
    //Create updated validation (based on updated form)
    let updatedValidation;
    if (fields)
      updatedValidation = {
        ...formValidation,
        ...validateObject(t, fields, 'contactList.'),
      };
    else updatedValidation = validateObject(t, formData, 'contactList.');

    //Update the form validation
    setFormValidation({ ...formValidation, ...updatedValidation });

    //Return the result of the form validation
    return getValidationResult(updatedValidation);
  };

  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <div className='relative md:w-full flex items-center md:px-6 px-4 sm:max-w-6xl'>
          <section className='min-h-screen sm:relative w-full ml-4'>
            <div className='mb-6'>
              <Breadcrumb list={breadcrumb} />
            </div>
            <h1 className='mt-2 mb-10 w-full flex items-center mb-3'>
              <span>
                {operation === CRUDOperation.create
                  ? t(`contactList.createListTitle`, { ns: 'common' })
                  : t(`contactList.editListTitle`, { ns: 'common' })}
              </span>
              <span
                className={classNames(
                  'ml-3 bg-gray-extralight80 text-white rounded-full h-4.5 w-4.5 cursor-pointer hover:bg-gray-light50 focus:bg-gray-light50 justify-center flex items-center text-xs',
                )}
                onClick={() =>
                  setInfoModal(
                    '',
                    <div className='text-left py-4'>
                      <p className='mb-5'>
                        {t('contactList.popupMessage', { ns: 'common' })}
                      </p>
                      <div className='flex justify-center mb-4'>
                        <img
                          className='lg:max-w-xl'
                          src='/images/information-modals/contact-list.svg'
                          alt='Contact List message example'
                        />
                      </div>
                    </div>,
                  )
                }
              >
                {' '}
                i
              </span>
            </h1>
            <div className='w-3/4 mb-6 pr-7'>
              <h5 className='font-medium mb-2'>
                {t('contactList.listName', { ns: 'common' })}
              </h5>
              <div className='flex items-start'>
                {/*List name (internal)*/}
                <div className='w-1/2'>
                  <span className='text-sm text-gray-light40'>
                    {t('internal', { ns: 'common' })}
                  </span>
                  <div className='mt-1 mr-2'>
                    <Input
                      className='pr-1 w-full block h-8 border-2 border-gray-extralight60 opacity-1 h-5 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent'
                      name='nameInternal'
                      value={formData.nameInternal}
                      error={formValidation.nameInternal}
                      onChange={(e) => {
                        handleChange(e);
                        handleFormChange('nameInternal', e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/*List name (public)*/}
                <div className='w-1/2'>
                  <div className='ml-2'>
                    <span className='text-sm text-gray-light40'>
                      {t('public', { ns: 'common' })}
                    </span>
                    <div className='mt-1'>
                      <Input
                        className='w-full block h-8 border-2 border-gray-extralight60 opacity-1 h-5 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent'
                        name='namePublic'
                        value={formData.namePublic}
                        error={formValidation.namePublic}
                        onChange={(e) => {
                          handleChange(e);
                          handleFormChange('namePublic', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Description*/}
            <div className='w-3/4 mb-6 pr-7'>
              <h5 className='font-medium'>
                {t('contactList.listDescription', { ns: 'common' })}
              </h5>
              <div className='mt-2'>
                <Input
                  type={InputType.Textarea}
                  className='h-32 w-full block border-gray-extralight60 opacity-1 leading-4 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent'
                  name='description'
                  value={formData.description}
                  error={formValidation.description}
                  onChange={(e) => {
                    handleChange(e);
                    handleFormChange('description', e.target.value);
                  }}
                />
              </div>
            </div>

            {/*Image url*/}
            <ImageUploader
              imageUrl={formData.imageUrl}
              uploadingImage={uploadingImage}
              onImageSelected={handleFormImageChange}
              onRemoveImageClick={() =>
                setFormData({ ...formData, imageUrl: '' })
              }
            />
            {/*Buttons*/}
            <div className='button-wrapper mt-20 flex'>
              <div className='w-60 mr-3'>
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  justify={ButtonJustify.center}
                  fullWidth={true}
                  onClick={() => handleSubmitForm()}
                  disabled={
                    operation === CRUDOperation.create
                      ? uploadingImage ||
                        formData.namePublic === '' ||
                        formData.nameInternal === '' ||
                        formData.description === ''
                      : uploadingImage ||
                        (formData.namePublic === origData?.listName &&
                          formData.nameInternal === origData?.nameInternal &&
                          formData.description === origData?.description &&
                          formData.imageUrl === origData?.imageUrl)
                  }
                >
                  {operation === CRUDOperation.create
                    ? t(`contactList.createListBtn`, { ns: 'common' })
                    : t(`contactList.editListBtn`, { ns: 'common' })}
                </Button>
              </div>
              {operation === CRUDOperation.update && (
                <Link
                  className='flex'
                  to={
                    scope === ContactListScope.broadcast
                      ? broadcastContactListUrl
                      : transactionalContactListUrl
                  }
                >
                  <Button
                    type={ButtonType.cancel}
                    size={ButtonSize.medium}
                    justify={ButtonJustify.center}
                  >
                    {t('action_cancel', { ns: 'common' })}
                  </Button>
                </Link>
              )}
            </div>
          </section>
        </div>
      </RightSection>
      <BottomMenu />
    </>
  );
};
