import QRCode from 'qrcode';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { instanceOfUserData } from '../../utils/instanceOf';
import { SettingsTypes } from '../../views/settings/account/Account';
import { Button } from '../Button';
import Loader, { LoaderType } from '../Loader';
import { PopUp, PopupSize } from '../Popup';
import { MfaEnabling } from '../auth/MfaEnabling';
import { FeedbackModal } from '../modals';
import { FeedbackIcon } from '../modals/FeedbackModal';
import { SettingsDeleteModal } from '../modals/SettingsDeleteModal';

export enum ModalState {
  failure = 'connectWallet',
  mfa = 'mfa',
  success = 'success',
  disable = 'disable',
}

export const SecuritySettingsEdit: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { userData, setUser } = useContextUser();
  const { setModal, clearModal } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enableMfa, setEnableMfa] = useState<boolean>(
    userData?.mfaEnabled ?? false,
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [tempUserData, setTempUserData] = useState<any>(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const handleMfaSetup = async (e: any) => {
    setEnableMfa(!enableMfa);
    if (e.target.checked) {
      try {
        const connection = await fetch(
          `${import.meta.env.VITE_API_URL}/fauna/auth/mfa-setup/business`,
          {
            method: 'POST',
            body: JSON.stringify({
              userId: userData?.userId,
            }),
            credentials: 'include',
          },
        );
        const dataFromApi = await connection.json();
        if (dataFromApi.data.error) {
          setModal(
            '',
            `${t('error.error_occurred', { ns: 'common' })}`,
            FeedbackType.failure,
          );
          console.log('Error: ', dataFromApi.data.error);
        } else {
          setTempUserData(dataFromApi.data);
          QRCode.toDataURL(
            dataFromApi.data.temp_secret.otpauth_url,
            function (err, data_url) {
              setQrCode(data_url);
              handleModal(ModalState.mfa);
            },
          );
        }
      } catch (error) {
        setModal(
          '',
          `${t('error.error_occurred', { ns: 'common' })}`,
          FeedbackType.failure,
        );
        console.log('Error: ', error);
      }
    } else {
      handleModal(ModalState.disable);
    }
  };

  const handleDisable = async () => {
    try {
      const connection = await fetch(
        `${import.meta.env.VITE_API_URL}/fauna/auth/mfa-disable/business`,
        {
          method: 'POST',
          body: JSON.stringify({
            userId: userData?.userId,
          }),
          credentials: 'include',
        },
      );
      const dataFromApi = await connection.json();
      if (connection.status === 400) {
        setModal(
          '',
          `${t('error.error_occurred', { ns: 'common' })} ${t(
            'error.error_code',
            { ns: 'common' },
          )} ${dataFromApi.data.error}`,
          FeedbackType.failure,
        );
      } else if (
        typeof dataFromApi.data === 'object' &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser(dataFromApi.data);
        setEnableMfa(dataFromApi.data.enableMFA);
        setModal(
          t('modal_content.congrats', { ns: 'enumerations' }),
          t('modal_content.mfa_success', { ns: 'enumerations' }),
          FeedbackType.success,
        );
        handleCloseModal();
      } else {
        setModal(
          '',
          `${t('error.error_occurred', { ns: 'common' })}`,
          FeedbackType.failure,
        );
      }
    } catch (error) {
      setModal(
        '',
        `${t('error.error_occurred', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.log('Error: ', error);
    }
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
      clearModal();
    }, 2000);
  };

  const handleExitProcess = () => {
    setIsOpenModal(false);
    setEnableMfa(!enableMfa);
  };

  const handleLoggedInUser = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${import.meta.env.VITE_API_URL}/fauna/auth/mfa-verify/business`,
        {
          method: 'POST',
          body: JSON.stringify({
            userId: tempUserData.userId,
            token,
          }),
          credentials: 'include',
        },
      );
      const dataFromApi = await connection.json();
      if (connection.status === 400) {
        setModal(
          '',
          `${t('error.error_occurred', { ns: 'common' })} ${t(
            'error.error_code',
            { ns: 'common' },
          )} ${dataFromApi.data.error}`,
          FeedbackType.failure,
        );
        setEnableMfa(!enableMfa);
      } else if (
        typeof dataFromApi.data === 'object' &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser(dataFromApi.data);
        setModal(
          t('modal_content.congrats', { ns: 'enumerations' }),
          t('modal_content.mfa_success', { ns: 'enumerations' }),
          FeedbackType.success,
        );
        handleCloseModal();
      } else {
        setModal(
          '',
          `${t('error.error_occurred', { ns: 'common' })} ${t(
            'error.error_code',
            { ns: 'common' },
          )}`,
          FeedbackType.failure,
        );
        setEnableMfa(!enableMfa);
      }
    } catch (error) {
      setModal(
        '',
        `${t('error.error_occurred', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.log('Error: ', error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className='relative userSettings md:w-full flex items-center md:px-6 pb-8 py-5 max-w-6xl'>
        <section className='px-2 md:px-0 flex w-full justify-between'>
          <h5 className='text-sxm font-medium'>
            {t('settings.enableMFA', { ns: 'common' })}
          </h5>
          <label className='switch mb-5'>
            <input
              id='mfa-checkbox'
              type='checkbox'
              checked={enableMfa || false}
              onChange={(e) => handleMfaSetup(e)}
            />
            <span className={'slider round checked:bg-black'}></span>
          </label>
        </section>
      </div>
      {isOpenModal && (
        <>
          {modalContent === ModalState.failure && (
            <PopUp size={PopupSize.large}>
              <FeedbackModal
                title={t('modal_content.warning.title', { ns: 'enumerations' })}
                text={t('modal_content.mfa_failure.modal_title', {
                  ns: 'enumerations',
                })}
                additionalText={t(
                  'modal_content.mfa_failure.additional_title',
                  { ns: 'enumerations' },
                )}
                icon={FeedbackIcon.warning}
              />

              {/* {handleCloseModal() && <></>} */}
            </PopUp>
          )}
          {modalContent === ModalState.mfa && qrCode && (
            <PopUp size={PopupSize.largeXL} closeModal={handleExitProcess}>
              <MfaEnabling
                qrCode={qrCode}
                tempUserData={tempUserData}
                setToken={setToken}
              />
              <div className='text-right pt-5 flex justify-center'>
                <Button
                  onClick={() => handleLoggedInUser()}
                  disabled={!token || token?.length !== 6}
                  className='text-white bg-gold-400 active:bg-yellow-700 text-base px-6 py-2 rounded shadow lg:hover:shadow-lg outline-none focus:outline-none '
                >
                  Enable
                </Button>
              </div>
            </PopUp>
          )}
          {modalContent === ModalState.disable && (
            <PopUp size={PopupSize.smallxs} closeModal={handleExitProcess}>
              <SettingsDeleteModal
                id={ModalState.disable}
                title={t('settings.mfaDisable', { ns: 'common' })}
                description={t('settings.mfaMoreInfo', { ns: 'common' })}
                textBtnCancel={t('action_cancel', { ns: 'common' })}
                textBtnSubmit={t('action_disable', { ns: 'common' })}
                setShowModal={handleExitProcess}
                type={SettingsTypes.otherSettings}
                fn={handleDisable}
              />
            </PopUp>
          )}
        </>
      )}
    </>
  );
};
