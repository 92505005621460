import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { CopyButton } from '../CopyButton';

export const MfaEnabling: React.FC<{
  qrCode: string;
  tempUserData: any;
  setToken: Dispatch<SetStateAction<any>>;
}> = ({ qrCode, tempUserData, setToken }) => {
  const { t } = useTranslation(['common', 'enumerations']);

  const [copy, setCopy] = useState(false);
  const [showMfaCode, setShowMfaCode] = useState(false);

  const copyToClipboard = (word?: string) => {
    navigator.clipboard.writeText(word || '');
    setCopy(true);
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);

  return (
    <div className='flex flex-col items-center'>
      <span className='text-base'>{t(`mfa.step1`, { ns: 'common' })}</span>
      <img
        src={qrCode}
        alt='mfa'
        onClick={() => copyToClipboard(tempUserData.temp_secret.base32)}
        className='w-32 pb-2'
        title='Copy code'
      />
      <span
        className='hover:underline cursor-pointer text-sm'
        onClick={() => setShowMfaCode(!showMfaCode)}
      >
        {t(`mfa.alternative_step`, { ns: 'common' })}
        <FontAwesomeIcon
          icon={faChevronDown as IconProp}
          size='xs'
          className='text-gray-dark ml-2'
        />
      </span>
      {showMfaCode && (
        <>
          <span className='text-sm mt-4 mb-2'>
            {t(`mfa.additional_info`, { ns: 'common' })}
          </span>
          <div
            className='flex items-center relative w-full cursor-pointer'
            onClick={() => copyToClipboard(tempUserData.temp_secret.base32)}
          >
            <input
              className='cursor-pointer appearance-none outline-none focus:outline-none border text-xs rounded py-2 border-gray-extralight90medium text-black-transparent50 w-full text-center'
              readOnly
              value={tempUserData.temp_secret.base32}
            />
            <CopyButton
              hoverText={t('copy', 'common')}
              clickText={t('copied', 'common')}
              isCopying={copy}
              onCopy={copyToClipboard}
              copiedElement={tempUserData.temp_secret.base32}
            />
          </div>
        </>
      )}
      <span className='mt-6 text-base mb-2'>
        {' '}
        {t(`mfa.step2`, { ns: 'common' })}
      </span>
      <input
        onChange={(e) => setToken(e.target.value)}
        className='appearance-none outline-none focus:outline-none border text-lg rounded py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-black-transparent50 w-32 text-center'
        maxLength={6}
      />
    </div>
  );
};
