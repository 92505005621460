import { APIService } from './api.service';

export class ClientService extends APIService {
  constructor() {
    super();
  }

  public async create(data: any): Promise<any> {
    return await this._post(`/fauna/business/client`, data);
  }

  public async update(data: any, clientId: string): Promise<any> {
    return await this._patch(`/fauna/business/client/${clientId}`, data);
  }
}
