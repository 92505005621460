import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faEllipsisH,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContextUser } from '../contexts/user';
import { settingsAccountUrl } from '../utils/menuUtils';
import { Button, ButtonSize, ButtonType } from './Button';

const UserAccMenu: React.FC<{}> = ({ }) => {
  const { t } = useTranslation(['common']);
  const { userData, logout } = useContextUser();
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);
  const username: string = userData?.clientAlias
    ? userData.clientAlias
    : t('userAccountMenu.defaultUsername', { ns: 'common' });
  const profilePicture: string = userData?.userImage
    ? userData.userImage
    : '/images/account.svg';

  return (
    <div
      onClick={() => setOpenSubMenu(!openSubMenu)}
      className='w-full relative flex flex-col justify-start items-center rounded text-center text-xs font-bold transition-all border border-transparent hover:border-gold-400 hover:shadow-md'
    >
      <div
        className={`w-full absolute z-20 bottom-12 left-0 px-4 bg-white rounded shadow-md ${!openSubMenu && `hidden`
          }`}
      >
        <div
          className={`w-full flex flex-col justify-center items-center text-center`}
        >
          {/*Account Settings*/}
          <Link
            to={settingsAccountUrl}
            className='w-full flex justify-start items-center py-2 pl-3 block'
          >
            <div className='w-5 h-5'>
              <FontAwesomeIcon
                style={{ width: '100%', height: 'auto', color: 'gray' }}
                icon={faCog as IconProp}
              />
            </div>
            <Button
              type={ButtonType.backgroundNone}
              size={ButtonSize.small}
              className='w-full flex justify-center font-semibold'
            >
              {t('userAccountMenu.accountSettings', { ns: 'common' })}
            </Button>
          </Link>
          <div className='w-full border-b border-gray-light30'></div>
          {/*Logout*/}
          <div className='w-full flex justify-start items-center py-2 pl-3 block cursor-pointer'
            onClick={logout}
          >
            <div className='w-4 h-4'>
              <FontAwesomeIcon
                style={{ width: '100%', height: 'auto', color: 'gray' }}
                icon={faSignOutAlt as IconProp}
              />
            </div>
            <Button
              type={ButtonType.backgroundNone}
              size={ButtonSize.small}
              className='w-full flex justify-center font-semibold '
            >
              {t('userAccountMenu.logout', { ns: 'common' })}
            </Button>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-between items-center px-4 py-2 cursor-pointer'>
        <div className='w-full flex items-center'>
          <div className='w-8 h-8 mr-4 rounded-full overflow-hidden flex justify-center items-center'>
            <img src={profilePicture} alt='profile' />
          </div>
          <div className='w-full font-semibold text-left whitespace-nowrap overflow-hidden text-ellipsis'>
            <span>{username}</span>
          </div>
        </div>
        <div className='w-4 h-4'>
          <FontAwesomeIcon
            style={{ width: '100%', height: 'auto', color: 'gray' }}
            icon={faEllipsisH as IconProp}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAccMenu;
