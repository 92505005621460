import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faClone,
  faPen,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { breadcrumbStructure } from '../components/Breadcrumb';
import { Button, ButtonSize, ButtonType } from '../components/Button';
import { CopyButton } from '../components/CopyButton';
import Icon from '../components/Icon';
import { SearchInput } from '../components/SearchInput';
import WelcomePopupFlow from '../components/WelcomePopupFlow';
import Frame, { Scope as TabsScope } from '../components/layout/Frame';
import { FeedbackType, useModalContext } from '../contexts/modal-handling';
import { useContextUser } from '../contexts/user';
import {
  Clients,
  ContactList as ContactListInterface,
  ContactListLine,
} from '../models/Interfaces';
import { api } from '../shared';
import {
  broadcastContactListAddUrl,
  broadcastContactListEditUrl,
  transactionalContactListAddUrl,
  transactionalContactListEditUrl,
} from '../utils/menuUtils';

const calcPercentage = (total: number, part: number) => {
  if (total === 0) return '(-)';
  return `(${Math.round((part * 100) / total)}%)`;
};

export enum ContactListScope {
  broadcast = 'broadcast',
  transactional = 'transactional',
}

export const ContactList: React.FC<{ scope: ContactListScope }> = ({
  scope,
}) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setModal, setYesNoModal, clearYesNoModal } = useModalContext();
  const { userData, setUser, selectedOrganizationId } = useContextUser();
  const [isCondensed, setIsCondensed] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [copy, setCopy] = useState<string | null>(null);
  const [cList, setCList] = useState<ContactListLine[] | undefined>(undefined);
  const [filteredCList, setFilteredCList] = useState<
    ContactListLine[] | undefined
  >(undefined);

  const breadcrumb: breadcrumbStructure[] = [
    {
      label:
        scope === ContactListScope.broadcast
          ? t('breadcrumb.broadcast', { ns: 'enumerations' })
          : t('breadcrumb.transactional', { ns: 'enumerations' }),
    },
    {
      label: t('breadcrumb.contactLists', { ns: 'enumerations' }),
    },
  ];
  const copyToClipboard = (word: string) => {
    navigator.clipboard.writeText(word);
    setCopy(word);
  };

  const updateContactList = (
    searchFilter: string,
    contactLists: ContactListInterface[] | undefined = undefined,
    overwrite: boolean = false,
  ) => {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    let updatedContactLists: ContactListInterface[] | undefined;
    if (overwrite && !contactLists) updatedContactLists = [];
    else if (contactLists) updatedContactLists = contactLists;
    else if (userData?.clients?.length)
      updatedContactLists = selectedOrganization?.contactLists;

    const cList: ContactListLine[] | undefined = updatedContactLists?.map(
      (contactList) => {
        const entry: ContactListLine = {
          listId: contactList.listId,
          listName: contactList.namePublic,
          nameInternal: contactList.nameInternal,
          description: contactList.description,
          imageUrl: contactList.imageUrl,
          campaignsSent: '-',
          totalWallets: contactList?.subscribersAmount?.total
            ? contactList.subscribersAmount.total
            : 0,
          discordWallets: contactList?.subscribersAmount?.discord
            ? contactList.subscribersAmount.discord
            : 0,
          telegramWallets: contactList?.subscribersAmount?.telegram
            ? contactList.subscribersAmount.telegram
            : 0,
          broadcast: contactList.broadcast,
        };
        return entry;
      },
    );

    const fcList = cList?.filter((entry) => {
      const isResult = Object.entries(entry)?.filter(([key, value]) =>
        ['nameInternal', 'listName'].includes(key) &&
        ['number', 'string'].includes(typeof value) &&
        entry.broadcast ===
          (scope === ContactListScope.broadcast ? true : false)
          ? String(value)
              ?.toLocaleUpperCase()
              .includes(searchFilter.toLocaleUpperCase())
          : false,
      );
      return isResult?.length;
    });

    setSearchFilter(searchFilter);
    setCList(cList);
    setFilteredCList(fcList);
  };

  useEffect(() => {
    updateContactList('');
  }, [scope, selectedOrganizationId]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'searchFilter') {
      updateContactList(value);
    }
  };

  const attemptToDeleteContactList = (listId: string) => {
    setYesNoModal(
      t('deleteModal.contactList.title', { ns: 'common' }),
      t('deleteModal.contactList.text', { ns: 'common' }),
      () => {
        deleteContactList(listId);
        clearYesNoModal();
      },
      () => clearYesNoModal(),
      t('yesNoModal.delete', { ns: 'common' }),
      t('yesNoModal.cancel', { ns: 'common' }),
    );
  };

  const deleteContactList = async (listId: string) => {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (!listId || !selectedOrganization) return;
    const response = await api.contactList.delete(
      {
        clientId: selectedOrganization?.clientId,
      },
      listId,
    );

    if (response?.error) {
      setModal(
        t('fetch.contactList.delete.failure.title', { ns: 'common' }),
        t('fetch.contactList.delete.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
      console.log('Error: ', response?.error);
    } else {
      setUser(response);
      updateContactList(
        '',
        response.clients.find((c: any) => c.clientId === selectedOrganizationId)
          .contactLists,
        true,
      );
      setModal(
        t('fetch.contactList.delete.success.title', { ns: 'common' }),
        t('fetch.contactList.delete.success.text', { ns: 'common' }),
        FeedbackType.success,
        true,
      );
    }
  };

  return (
    <Frame name={'lists'} scope={TabsScope[scope]}>
      <div className='relative lg:w-full flex items-center lg:px-2 lg:px-6 pb-8 max-w-6xl'>
        <div className='px-2 lg:px-0 center flex-col w-full'>
          <section className='flex flex-col w-full'>
            <div className='flex'>
              <h1 className='mt-2 w-full justify-between mb-3 ml-4'>
                {t(`menu.contactList`, { ns: 'common' })}
              </h1>
              <Link
                to={
                  scope === ContactListScope.broadcast
                    ? broadcastContactListAddUrl
                    : transactionalContactListAddUrl
                }
              >
                <Button
                  type={ButtonType.backgroundNone}
                  size={ButtonSize.clear}
                  className={`mx-2 mb-1 flex p-0 m-0 disabled:shadow-lg cursor:pointer  `}
                >
                  <Icon
                    icon={faPlus as IconProp}
                    shadowIcons={true}
                    fontSize='text-base'
                    textColor='text-gold-400'
                    fontMobile='text-base'
                    className='w-8 h-8'
                  />
                </Button>
              </Link>
            </div>

            <div className='w-96'>
              <SearchInput
                searchFilter={searchFilter}
                handleChange={handleFormChange}
              />
            </div>

            <table className='w-full lg:table table-fixed flex flex-col text-center border-collapse border-8 border-gray-extralight15 text-gray-light50 mt-8 mb-10 ml-2'>
              <thead className='border-8 border-gray-extralight15 lg:table-header-group hidden'>
                <tr className='border-8 border-gray-extralight15'>
                  <th className='w-1/7 bg-white py-3 px-6 text-center'>
                    {t('contactList.nameInternal', { ns: 'common' })}
                  </th>
                  <th className='w-1/7 bg-white py-3  pl-6 px-2 lg:px-6 text-center'>
                    {t('contactList.listName', { ns: 'common' })}
                  </th>
                  <th className='w-1/7 bg-white py-3 px-2 lg:px-6 text-center'>
                    {t('contactList.campaignsSent', { ns: 'common' })}
                  </th>
                  <th className='w-1/7 bg-white py-3 px-2 lg:px-6 text-center'>
                    {t('contactList.subscribedWallets', { ns: 'common' })}
                  </th>
                  <th className='w-1/7 bg-white py-3 px-2 lg:px-6 text-center'>
                    {t('social_channels.DISCORD', { ns: 'enumerations' })}
                  </th>
                  <th className='w-1/7 bg-white py-3 px-2 lg:px-6 text-center'>
                    {t('social_channels.TELEGRAM', {
                      ns: 'enumerations',
                    })}
                  </th>
                  <th className='w-1/7 bg-white py-3 px-2 lg:px-6 text-center'></th>
                </tr>
              </thead>
              {cList && cList.length > 0 && (
                <tbody>
                  {filteredCList?.map((contactListLine: ContactListLine) => {
                    return (
                      <ContactListTableRow
                        scope={scope}
                        rowData={contactListLine}
                        onDelete={attemptToDeleteContactList}
                        copy={copy}
                        copyToClipboard={copyToClipboard}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>

            {filteredCList?.length === 0 && (
              <div className='mt-2 w-full mb-3 ml-4'>
                {t('contactList.noFilter', { ns: 'common' })}
              </div>
            )}
          </section>
        </div>
      </div>
      {/* Welcome Pop-up Flow */}
      <WelcomePopupFlow condensed={isCondensed} />
    </Frame>
  );
};

const ContactListTableRow: React.FC<{
  scope: string;
  rowData: ContactListLine;
  onDelete: (listId: string) => void;
  copy: string | null;
  copyToClipboard: Function;
}> = ({ scope, rowData, onDelete, copy, copyToClipboard }) => {
  const { t } = useTranslation(['common', 'enumerations']);
  return (
    <tr className='lg:table-row flex w-full flex-col border-2 border-gray-extralight15'>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white py-2 pr-2 lg:px-6 text-center'>
        <span className='lg:hidden inline-block font-bold mr-2'>
          {`${t('contactList.nameInternal', { ns: 'common' })}:`}
        </span>
        {rowData.nameInternal}
      </td>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white py-2 pl-6 pr-2 lg:px-6 text-center'>
        <span className='lg:hidden inline-block font-bold mr-2'>{`${t(
          'contactList.listName',
          { ns: 'common' },
        )}:`}</span>
        {rowData.listName}
      </td>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white py-2 px-2 lg:px-6 text-center'>
        <span className='lg:hidden inline-block font-bold mr-2'>{`${t(
          'contactList.campaignsSent',
          { ns: 'common' },
        )}:`}</span>
        {rowData.campaignsSent}
      </td>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white py-2 px-2 lg:px-6 text-center'>
        <span className='lg:hidden inline-block font-bold mr-2'>{`${t(
          'contactList.subscribedWallets',
          { ns: 'common' },
        )}:`}</span>
        {rowData.totalWallets}
      </td>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white py-2 px-2 lg:px-6 text-center'>
        <span className='lg:hidden inline-block font-bold mr-2'>{`${t(
          'social_channels.DISCORD',
          { ns: 'enumerations' },
        )}:`}</span>
        {rowData.discordWallets}
        <span className='ml-1 text-sm text-gray-light40'>
          {calcPercentage(rowData.totalWallets, rowData.discordWallets)}
        </span>
      </td>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white py-2 px-2 lg:px-6 text-center'>
        <span className='lg:hidden inline-block font-bold mr-2'>{`${t(
          'social_channels.TELEGRAM',
          { ns: 'enumerations' },
        )}:`}</span>
        {rowData.telegramWallets}
        <span className='ml-1 text-sm text-gray-light40'>
          {calcPercentage(rowData.totalWallets, rowData.telegramWallets)}
        </span>
      </td>
      <td className='lg:w-1/7 w-full lg:table-cell flex justify-center items-center bg-white lg:py-2 pb-8 px-2 lg:px-6 text-center'>
        <div className='flex justify-center'>
          {/*Edit Button*/}
          <Link
            to={`${
              scope === ContactListScope.broadcast
                ? broadcastContactListEditUrl
                : transactionalContactListEditUrl
            }/${rowData.listId}`}
          >
            <Button
              className='mx-1.5 flex p-0 m-0 '
              type={ButtonType.backgroundNone}
              size={ButtonSize.clear}
            >
              <Icon
                icon={faPen as IconProp}
                shadowIcons={true}
                textColor='text-gold-400'
                fontSize='text-xs'
              />
            </Button>
          </Link>
          <CopyButton
            hoverText={t('copy_id', 'common')}
            clickText={t('copied', 'common')}
            isCopying={copy === rowData.listId ? true : false}
            onCopy={copyToClipboard}
            copiedElement={rowData.listId}
          >
            <Button
              className='mx-1.5 flex p-0 m-0 '
              type={ButtonType.backgroundNone}
              size={ButtonSize.clear}
            >
              <Icon
                icon={faClone as IconProp}
                shadowIcons={true}
                textColor='text-gold-400'
                fontSize='text-xs'
              />
            </Button>
          </CopyButton>
          {/*Delete Button*/}
          <Button
            onClick={() => onDelete(rowData.listId)}
            className='mx-1.5 flex p-0 m-0 '
            type={ButtonType.backgroundNone}
            size={ButtonSize.clear}
          >
            <Icon
              icon={faTrash as IconProp}
              fontSize='text-xs'
              shadowIcons={true}
              textColor='text-gold-400'
            />
          </Button>
        </div>
      </td>
    </tr>
  );
};
