import classNames from 'classnames';

export enum InputType {
  Text = 'text',
  Select = 'select',
  Textarea = 'textarea',
}

export interface InputSelectOption {
  value: string;
  text: string;
}

const Input: React.FC<{
  type?: InputType;
  options?: InputSelectOption[];
  name?: string;
  value: string;
  onKeyUp?: (event: any) => void;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  error?: string;
  className?: string;
  stripDefaultClasses?: boolean;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
}> = ({
  type = InputType.Text,
  options,
  name,
  value,
  onKeyUp,
  onChange,
  error,
  className,
  stripDefaultClasses,
  placeholder,
  minLength,
  maxLength,
  disabled,
  readOnly: readonly,
}) => {
  //Generate classes
  let defaultClasses = ``;
  if (!stripDefaultClasses) {
    defaultClasses += ` w-full block pr-1 border-1 border-gray-extralight60 focus:border-gray-light50 opacity-1 appearance-none outline-none focus:outline-none focus:ring-transparent `;
    switch (type) {
      case InputType.Text:
        defaultClasses += ` h-8 `;
        break;
      case InputType.Textarea:
        defaultClasses += ` h-96 leading-4`;
        break;
      default:
        defaultClasses += `  `;
        break;
    }
  }
  //Generate other values
  const defaultMinLength = minLength ? minLength : 0;
  const defaultMaxLength = maxLength ? maxLength : 9999;

  //Generate input
  const getInput = () => {
    switch (type) {
      case InputType.Textarea:
        return (
          <textarea
            name={name}
            value={value}
            onChange={onChange}
            className={classNames(`${defaultClasses} ${className}`, {
              'border-red hover:border-red focus:border-red': error,
              'opacity-70': readonly,
            })}
            placeholder={placeholder}
            minLength={defaultMinLength}
            maxLength={defaultMaxLength}
            disabled={disabled}
            readOnly={readonly}
          />
        );
      case InputType.Select:
        return (
          <select
            name={name}
            value={value}
            onChange={onChange}
            className={`${defaultClasses} ${className} ${
              error ? 'border-red hover:border-red focus:border-red' : ''
            } ${disabled ? 'cursor-not-allowed' : ''}`}
            // placeholder={placeholder}
            disabled={disabled}
          >
            {options?.map((option) => (
              <option value={option.value}>{option.text}</option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type={type}
            name={name}
            value={value}
            onKeyUp={onKeyUp ? onKeyUp : () => {}}
            onChange={onChange}
            className={`${defaultClasses} ${className} ${
              error ? 'border-red hover:border-red focus:border-red' : ''
            }`}
            placeholder={placeholder}
            minLength={defaultMinLength}
            maxLength={defaultMaxLength}
            disabled={disabled}
          />
        );
    }
  };

  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-start relative',
      )}
    >
      {getInput()}
      {disabled && type !== InputType.Select && (
        <div className='absolute top-0 left-0 right-0 bottom-0 bg-whiteTransparent-50 cursor-not-allowed'></div>
      )}
      <small className='text-red'>{error}</small>
    </div>
  );
};

export default Input;
