import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import Loader, { LoaderType } from '../../components/Loader';
import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { api } from '../../shared';
import { instanceOfUserData } from '../../utils/instanceOf';
import Icon from '../Icon';

export const UserSettingsEdit: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { setModal } = useModalContext();
  const { userData, setUser } = useContextUser();
  const [newDataUser, setNewDataUser] = useState<string>(
    userData?.clientAlias ?? '',
  );
  const [showSave, setShowSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeAlias = async () => {
    setNewDataUser(newDataUser.trim());
    setIsLoading(true);

    if (!userData) return;

    const response = await api.user.update(
      {
        alias: newDataUser.trim(),
      },
      userData.userId,
    );

    if (typeof response === 'object' && instanceOfUserData(response)) {
      setUser(response);
      setShowSave(false);
      setModal(
        t('fetch.securitySettings.user.update.success.title', { ns: 'common' }),
        t('fetch.securitySettings.user.update.success.text', { ns: 'common' }),
        FeedbackType.success,
        true,
      );
    } else
      setModal(
        t('fetch.securitySettings.user.update.failure.title', {
          ns: 'common',
        }),
        t('fetch.securitySettings.user.update.failure.text', {
          ns: 'common',
        }),
        FeedbackType.failure,
        true,
      );

    setIsLoading(false);
  };

  const handleChange = (e: any) => {
    setNewDataUser(e.target.value);
    setShowSave(true);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className='relative userSettings md:w-full flex items-center md:px-6 pb-8 py-5 max-w-6xl'>
        <section className='px-2 md:px-0 flex flex-col w-full'>
          <div>
            <h5>{t('settings.inputLabel', { ns: 'common' })}</h5>
            <div className='relative cursor-pointer mt-3'>
              <button
                className={classNames('absolute right-0 p-2', {
                  hidden: !showSave,
                })}
                onClick={changeAlias}
              >
                <Icon
                  icon={faSave as IconProp}
                  bgContainerTransparent={true}
                  textColor='text-gold-400'
                  fontSize='text-xl'
                  className='float-right'
                />
              </button>
              <input
                id='aliasField'
                type='text'
                className='w-full block bg-gray-extralight15 h-8 border-b-2 opacity-1 h-5 border-transparent focus:border-transparent appearance-none outline-none focus:outline-none focus:ring-transparent'
                defaultValue={userData?.clientAlias ?? ''}
                onChange={(e) => handleChange(e)}
                maxLength={10}
                value={newDataUser}
              />
              <hr  />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
