import { Cookies } from 'react-cookie';

export const getCookies = () => {
  const cookies = new Cookies();
  return {
    accessToken: cookies.get('accessToken'),
    refreshToken: cookies.get('refreshToken'),
  };
};

export const setCookies = (cookieName: string, cookieValue: string) => {
  const cookies = new Cookies();

  cookies.set(cookieName, cookieValue, {
    path: '/',
    domain: import.meta.env.VITE_ORIGIN,
    secure: true,
  });
};

export const removeCookies = (cookieName?: 'accessToken' | 'refreshToken') => {
  const cookies = new Cookies();

  if (cookieName) return cookies.remove(cookieName, { path: '/' });

  cookies.remove('accessToken', { path: '/' });
  cookies.remove('refreshToken', { path: '/' });
};
