import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import RightSectionLogin from '@/components/RightSectionLogin';
import SideMenu, { ModeType } from '@/components/SideMenu';
import LoginSectionButtons from '@/components/auth/LoginSectionButtons';
import { PopUp, PopupSize } from '../components/Popup';
import { FeedbackModal } from '../components/modals';
import { FeedbackIcon } from '../components/modals/FeedbackModal';
import { useModalContext } from '../contexts/modal-handling';
import { useContextUser } from '../contexts/user';
import { ModalState } from './SignIn';

export enum LoginStep {
  connectWallet = 'connectWallet',
  verifyWallet = 'verifyWallet',
}

export const LoginPage: React.FC = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setUser, getUser } = useContextUser();
  const { state }: any = useLocation();
  const { clearModal, setAuthModal } = useModalContext();
  let dataFromHandleSocials = state;
  const searchParams = new URLSearchParams(document.location.search);
  const jwt = searchParams.get('jwt');

  const [width, setWidth] = useState(window.innerWidth);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [tempUserData, setTempUserData] = useState(null);

  if (jwt) {
    getUser();
  }

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleSetUser = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
      clearModal();
      setUser(tempUserData);
    }, 3000);
  };

  const handleCloseModal = (hasButton: boolean) => {
    if (hasButton) {
      setIsOpenModal(false);
      dataFromHandleSocials = null;
      setModalContent(null);
    } else {
      setTimeout(() => {
        setIsOpenModal(false);
        dataFromHandleSocials = null;
        setModalContent(null);
      }, 3000);
    }
  };

  useEffect(() => {
    if (dataFromHandleSocials !== null) {
      if (dataFromHandleSocials.userId) {
        setTempUserData(dataFromHandleSocials.userId);
      }
      setIsOpenModal(true);
      setModalContent(dataFromHandleSocials.modalContent);
      dataFromHandleSocials = null;
    }
  }, [dataFromHandleSocials]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  useEffect(() => handleSetUser(), [tempUserData]);

  return (
    <>
      <div className='flex flex-grow'>
        <SideMenu mode={ModeType.wide}>
          <div className='flex flex-col'>
            <h2 className='self-center text-md md:text-lg'>
              {t('login.title', { ns: 'common' })}
            </h2>
            <LoginSectionButtons />
          </div>
        </SideMenu>
        <RightSectionLogin />
        {isOpenModal && (
          <>
            {modalContent === ModalState.mfaValidate &&
              setAuthModal(
                PopupSize.large,
                true,
                undefined,
                t('modal_content.mfaValidate.modal_title', {
                  ns: 'enumerations',
                }),
                undefined,
                t('modal_content.mfaValidate.additional_title', {
                  ns: 'enumerations',
                }),
              )}
            {modalContent === ModalState.noSocial && (
              <PopUp size={PopupSize.large}>
                <FeedbackModal
                  title={t('ups', { ns: 'common' })}
                  text={t(
                    'enumerations:modal_content.user_does_not_have_social.text',
                  )}
                  additionalText={t(
                    'enumerations:modal_content.user_does_not_have_social.additional_text',
                  )}
                  icon={FeedbackIcon.warning}
                />

                {/* {handleCloseModal(false)} */}
              </PopUp>
            )}
          </>
        )}
      </div>
    </>
  );
};
