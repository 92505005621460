import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDiscord,
  faTelegram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleRight,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import google from '../../assets/images/google.png';
import { Button, ButtonSize, ButtonType } from '../Button';
import { CopyButton } from '../CopyButton';
import Icon from '../Icon';

type createObjCard = {
  type: string;
  openModal: () => void;
  setModalType: Dispatch<SetStateAction<any>>;
  getBtnId: Dispatch<SetStateAction<any>>;
  this?: string;
  numberOfWallets?: number;
  titleCard?: string;
  chainImage?: string;
  sectionTitle?: string;
  sectionText?: string;
  linkAccount?: string;
};

export const CardItem = (createObjCard: createObjCard) => {
  const { t } = useTranslation(['common', 'enumerations', '']);
  const [copy, setCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = (type: string) => {
    createObjCard.setModalType(type);
    createObjCard.getBtnId(createObjCard?.this);
    if (
      type === 'trash' &&
      createObjCard.type === 'wallets' &&
      createObjCard.numberOfWallets === 1
    )
      return;
    createObjCard.openModal();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(createObjCard.sectionText?.toString() || '');
    setCopy(true);
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);

  useEffect(() => {
    if (createObjCard.type === 'wallets') {
      setIsLoading(true);
      if (createObjCard.chainImage && createObjCard.titleCard) {
        setIsLoading(false);
      }
    }
  }, [createObjCard]);

  return (
    <>
      <div
                className={'w-full'}
      >
        <div
          className={`h-36 px-6 py-4 rounded-2xl bg-white shadow-black shadow-lg sm:mx-4 md:mx-6 md:ml-0 my-5 sm:my-2`}
        >
          <div className='flex items-center'>
            {(createObjCard.type === 'socialAccounts' &&
              createObjCard.titleCard === 'telegram' && (
                <FontAwesomeIcon
                  icon={faTelegram as IconProp}
                  className={`text-telegram text-2xl mr-2`}
                />
              )) ||
              (createObjCard.titleCard === 'twitter' && (
                <FontAwesomeIcon
                  icon={faTwitter as IconProp}
                  className={`text-twitter text-2xl mr-2 `}
                />
              )) ||
              (createObjCard.titleCard === 'discord' && (
                <FontAwesomeIcon
                  icon={faDiscord as IconProp}
                  className={`text-discord text-2xl mr-2 `}
                />
              )) ||
              (createObjCard.titleCard === 'google' && (
                <img src={google} className='w-7 mr-2'></img>
              ))}
            {createObjCard.type === 'wallets' && (
              <>
                {isLoading && <div className='loading-ring gray-dark'></div>}
                {!isLoading && (
                  <img
                    src={createObjCard.chainImage ?? './images/terra_icon.svg'}
                    className='mr-2 w-10'
                    alt='Terra Wallet'
                  />
                )}
              </>
            )}
            <p className=' font-medium flex-grow flex text-black text-base'>
              {createObjCard?.titleCard
                ? createObjCard.titleCard.slice(0, 1).toUpperCase() +
                  createObjCard.titleCard.slice(1)
                : ''}
            </p>
            {createObjCard.type !== 'otherSettings' ? (
              createObjCard.type !== 'wallets' ? (
                <Button
                  onClick={() => openModal('trash')}
                  className='mx-1.5 flex p-0 m-0 '
                  type={ButtonType.backgroundNone}
                  size={ButtonSize.clear}
                >
                  <Icon
                    icon={faTrash as IconProp}
                    fontSize='text-xs'
                    shadowIcons={true}
                    textColor='text-gold-400'
                  />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => openModal('edit')}
                    className='mx-1.5 flex p-0 m-0 '
                    type={ButtonType.backgroundNone}
                    size={ButtonSize.clear}
                  >
                    <Icon
                      icon={faPen as IconProp}
                      shadowIcons={true}
                      textColor='text-gold-400'
                      fontSize='text-xs'
                    />
                  </Button>
                  <Button
                    onClick={() => openModal('trash')}
                    type={ButtonType.backgroundNone}
                    size={ButtonSize.clear}
                    className={`mx-1.5 flex p-0 m-0 ${
                      createObjCard.numberOfWallets === 1 &&
                      'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <Icon
                      icon={faTrash as IconProp}
                      fontSize='text-xs'
                      shadowIcons={true}
                      textColor='text-gold-400'
                    />
                  </Button>
                </>
              )
            ) : (
              <NavLink
                className='mx-1.5 flex'
                id={createObjCard.this}
                to={
                  createObjCard?.linkAccount ? createObjCard.linkAccount : '/#'
                }
              >
                <Icon
                  icon={faAngleRight as IconProp}
                  textColor='text-gray-extralight90'
                  fontSize='text-2xl'
                  fontMobile='text-xl'
                />
              </NavLink>
            )}
          </div>
          <div
            className={`pt-4 ${
              createObjCard.type === 'otherSettings' && 'hidden sm:block pt-5'
            }`}
          >
            <p
              className={`text-sm ${createObjCard.type === 'wallets' && 'h-8'}`}
            >
              {createObjCard.sectionTitle}
            </p>
            {createObjCard.type === 'wallets' ? (
              <div className='flex'>
                <p className='text-sm' id='copy'>
                  {createObjCard.sectionText?.slice(0, 10)}...
                  {createObjCard.sectionText?.slice(-4)}
                </p>
                {createObjCard.sectionText && (
                  <CopyButton
                    hoverText={t('copy_address', { ns: 'common' })}
                    clickText={t('copied', { ns: 'common' })}
                    isCopying={copy}
                    onCopy={copyToClipboard}
                    copiedElement={createObjCard.sectionText}
                  />
                )}
              </div>
            ) : (
              <p className='text-sm '>{createObjCard.sectionText}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
