export const BroadcastSvg = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 13.9062C25 13.0294 24.5279 12.272 23.8333 11.8608V6.18787C23.8333 5.86316 23.5789 5 22.6667 5C22.4071 5 22.1493 5.08832 21.9382 5.26051L18.8382 7.78506C17.281 9.05197 15.3261 9.75 13.3333 9.75H6.33333C5.04453 9.75 4 10.8132 4 12.125V15.6875C4 16.9993 5.04453 18.0625 6.33333 18.0625H7.56198C7.5113 18.4514 7.4825 18.847 7.4825 19.25C7.4825 20.7258 7.8201 22.1204 8.41437 23.3669C8.60359 23.7636 9.01667 24 9.44979 24H12.1579C13.1077 24 13.6779 22.8927 13.1022 22.1238C12.5043 21.3252 12.1488 20.3291 12.1488 19.25C12.1488 18.8377 12.2079 18.4414 12.3096 18.0625H13.3333C15.3261 18.0625 17.281 18.7605 18.8378 20.0274L21.9379 22.552C22.1446 22.7204 22.4015 22.8123 22.6663 22.8125C23.5748 22.8125 23.833 21.9671 23.833 21.625V15.9521C24.5279 15.5405 25 14.7831 25 13.9062ZM21.5 19.1543L20.2951 18.1731C18.3263 16.57 15.8533 15.6875 13.3333 15.6875V12.125C15.8533 12.125 18.3263 11.2425 20.2951 9.63941L21.5 8.65824V19.1543Z'
        fill='currentColor'
      />
    </svg>
  );
};
