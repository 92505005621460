import React, { createContext, useCallback, useContext, useState } from 'react';

import { PopupSize } from '../../components/Popup';

/* Interface creation */
export interface ModalContextType {
  type: ModalType | null;
  config:
    | ModalConfig
    | InfoModalConfig
    | AuthModalConfig
    | YesNoModalConfig
    | null;
  setModal(
    title: string,
    text: string,
    type: FeedbackType,
    closeBtn?: boolean,
    onClose?: () => void,
    extraText?: string,
    popupSize?: PopupSize,
    extraJSX?: React.ReactNode,
    customIcon?: React.ReactNode,
  ): void;
  setInfoModal(
    title: string,
    children: React.ReactNode,
    onClose?: () => void,
  ): void;
  setAuthModal(
    popupSize: PopupSize,
    logo?: boolean,
    image?: string,
    title?: string,
    name?: string,
    extraTitle?: string,
    children?: React.ReactNode | void,
    description?: string,
    alignElements?: string,
    primaryBtn?: string,
    secondaryBtn?: string,
    primaryBtnOnClick?: React.MouseEventHandler,
    secondaryBtnOnClick?: React.MouseEventHandler,
  ): void;
  setYesNoModal(
    title: string,
    text: string,
    onYes: Function,
    onNo: Function,
    yesBtn?: string,
    noBtn?: string,
  ): void;
  clearModal(): void;
  clearInfoModal(): void;
  clearAuthModal(): void;
  clearYesNoModal(): void;
}

export enum ModalType {
  feedback = 'feedback',
  info = 'info',
  auth = 'auth',
  yesNo = 'yesNo',
}
export enum FeedbackType {
  failure = 'failure',
  success = 'success',
  warning = 'warning',
  custom = 'custom',
}

export interface ModalConfig {
  title?: string;
  text?: string;
  type: FeedbackType;
  closeBtn?: boolean;
  onClose?: () => void;
  extraText?: string;
  popupSize?: PopupSize;
  extraJSX?: React.ReactNode;
  customIcon?: React.ReactNode;
}
export interface InfoModalConfig {
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
}
export interface AuthModalConfig {
  popupSize: PopupSize;
  logo?: boolean;
  image?: string;
  title?: string;
  name?: string;
  extraTitle?: string;
  children?: React.ReactNode | void;
  description?: string;
  alignElements?: string;
  primaryBtn?: string;
  secondaryBtn?: string;
  primaryBtnOnClick?: React.MouseEventHandler;
  secondaryBtnOnClick?: React.MouseEventHandler;
}
export type YesNoModalConfig = {
  title: string;
  text: string;
  onYes: Function;
  onNo: Function;
  yesBtn?: string;
  noBtn?: string;
};
/* Context */
const ModalContext = createContext<ModalContextType>({
  type: null,
  config: null,
  setModal: () => {},
  setInfoModal: () => {},
  setAuthModal: () => {},
  setYesNoModal: () => {},
  clearModal: () => {},
  clearInfoModal: () => {},
  clearAuthModal: () => {},
  clearYesNoModal: () => {},
});

/* Using Context*/
export const useModalContext = () => useContext(ModalContext);

/* Provider fn */
export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [typeState, setTypeState] = useState<ModalType | null>(null);
  const [configState, setConfigState] = useState<
    ModalConfig | InfoModalConfig | AuthModalConfig | YesNoModalConfig | null
  >(null);
  const clearModal = () => {
    setTypeState(null);
    setConfigState(null);
  };
  const clearInfoModal = () => {
    setTypeState(null);
    setConfigState(null);
  };
  const clearAuthModal = () => {
    setTypeState(null);
    setConfigState(null);
  };
  const clearYesNoModal = () => {
    setTypeState(null);
    setConfigState(null);
  };
  const setModal = (
    title: string,
    text: string,
    type: FeedbackType,
    closeBtn?: boolean,
    onClose?: () => void,
    extraText?: string,
    popupSize?: PopupSize,
    extraJSX?: React.ReactNode,
    customIcon?: React.ReactNode,
  ) => {
    setTypeState(ModalType.feedback);
    setConfigState({
      title,
      text,
      type,
      closeBtn,
      onClose,
      extraText,
      popupSize,
      extraJSX,
      customIcon,
    });
  };
  const setInfoModal = (
    title: string,
    children: React.ReactNode,
    onClose?: () => void,
  ) => {
    setTypeState(ModalType.info);
    setConfigState({ title, children, onClose });
  };
  const setAuthModal = (
    popupSize: PopupSize,
    logo?: boolean,
    image?: string,
    title?: string,
    name?: string,
    extraTitle?: string,
    children?: React.ReactNode | void,
    description?: string,
    alignElements?: string,
    primaryBtn?: string,
    secondaryBtn?: string,
    primaryBtnOnClick?: React.MouseEventHandler,
    secondaryBtnOnClick?: React.MouseEventHandler,
  ) => {
    setTypeState(ModalType.auth);
    setConfigState({
      popupSize,
      logo,
      image,
      title,
      name,
      extraTitle,
      children,
      description,
      alignElements,
      primaryBtn,
      secondaryBtn,
      primaryBtnOnClick,
      secondaryBtnOnClick,
    });
  };
  const setYesNoModal = (
    title: string,
    text: string,
    onYes: Function,
    onNo: Function,
    yesBtn?: string,
    noBtn?: string,
  ) => {
    setTypeState(ModalType.yesNo);
    setConfigState({ title, text, yesBtn, onYes, noBtn, onNo });
  };

  const contextValue = {
    type: typeState,
    config: configState,
    setModal: useCallback(
      (
        title,
        text,
        type,
        closeBtn,
        onClose,
        extraText,
        popupSize,
        extraJSX,
        customIcon,
      ) =>
        setModal(
          title,
          text,
          type,
          closeBtn,
          onClose,
          extraText,
          popupSize,
          extraJSX,
          customIcon,
        ),
      [],
    ),
    clearModal: useCallback(() => clearModal(), []),
    setInfoModal: useCallback(
      (title, children, onClose) => setInfoModal(title, children, onClose),
      [],
    ),
    clearInfoModal: useCallback(() => clearInfoModal(), []),
    setAuthModal: useCallback(
      (
        logo,
        image,
        title,
        name,
        extraTitle,
        children,
        description,
        alignElements,
        primaryBtn,
        secondaryBtn,
        primaryBtnOnClick,
        secondaryBtnOnClick,
        popupSize,
      ) =>
        setAuthModal(
          logo,
          image,
          title,
          name,
          extraTitle,
          children,
          description,
          alignElements,
          primaryBtn,
          secondaryBtn,
          primaryBtnOnClick,
          secondaryBtnOnClick,
          popupSize,
        ),
      [],
    ),
    clearAuthModal: useCallback(() => clearAuthModal(), []),
    setYesNoModal: useCallback(
      (title, text, yesBtn, onYes, noBtn, onNo) =>
        setYesNoModal(title, text, yesBtn, onYes, noBtn, onNo),
      [],
    ),
    clearYesNoModal: useCallback(() => clearYesNoModal(), []),
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};
