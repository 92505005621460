import { Button, ButtonJustify, ButtonSize, ButtonType } from '../Button';

import { useTranslation } from 'react-i18next';

export type Props = {
  title: string;
  text: string;
  yesBtn?: string;
  onYes: Function;
  noBtn?: string;
  onNo: Function;
};

export const YesNoModal = ({
  title,
  text,
  yesBtn,
  onYes,
  noBtn,
  onNo,
}: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <div className='text-center px-4'>
      <h3 className=' my-4'>{title ?? 'Title'}</h3>
      <p className='text-lg mt-4'>{text ?? ''}</p>

      <div className='w-full flex justify-center items-center mt-4'>
        <div className='mx-2'>
          <Button
            type={ButtonType.cancel}
            size={ButtonSize.medium}
            justify={ButtonJustify.center}
            fullWidth={true}
            onClick={() => onNo()}
          >
            {noBtn ? noBtn : t('yesNoModal.no', { ns: 'common' })}
          </Button>
        </div>
        <div className='mx-2'>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            justify={ButtonJustify.center}
            fullWidth={true}
            onClick={() => onYes()}
          >
            {yesBtn ? yesBtn : t('yesNoModal.yes', { ns: 'common' })}
          </Button>
        </div>
      </div>
    </div>
  );
};
