import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '../../../components/Tooltip';
import { DeleteAccount } from '../../../components/settings/DeleteAccount';
import { SecuritySettingsEdit } from '../../../components/settings/SecuritySettingsEdit';
import { UserSettingsEdit } from '../../../components/settings/UserSettingsEdit';

export const OtherSettingPage: React.FC = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);


  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  return (

    <>
      <div className='px-2 mt-8 mb-6 md:mb-0 md:px-0 w-full'>
        <div
          className={`px-6 py-4 rounded-2xl bg-white shadow-black shadow-lg sm:mx-4 md:mx-6 md:ml-0 my-5 sm:my-2 h-auto w-full md:w-3/4 lg:w-2/4`}
        >
          <h4 className='mt-2 justify-between mb-4 ml-4'>
            {t(`settings.userSettings`, { ns: 'common' })}
          </h4>
          <UserSettingsEdit />
        </div>
        <Tooltip content={t('tooltip.2fa', { ns: 'common' })}>
          <div
            className={`px-6 py-4 rounded-2xl bg-white shadow-black shadow-lg sm:mx-4 md:mx-6 md:ml-0 my-5 sm:mb-2 mt-8 h-auto w-full md:w-3/4 lg:w-2/4 `}
          >
            <div className='relative'>
              <h4 className='mt-2 justify-between mb-4 ml-4'>
                {t(`settings.securitySettings`, { ns: 'common' })}
              </h4>
              <SecuritySettingsEdit />
              <div className='absolute z-10 top-0 left-0 w-full h-full cursor-not-allowed bg-whiteTransparent-50'></div>
            </div>
          </div>
        </Tooltip>
        <DeleteAccount />
      </div>
    </>

  );
};
