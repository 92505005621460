import { TabsInfo } from '../components/TabMenu';
import { Scope } from '../components/layout/Frame';
import {
  broadcastCampaignsUrl,
  broadcastContactListUrl,
  settingsAccountUrl,
  settingsBillingUrl,
  settingsGeneralUrl,
  transactionalContactListUrl,
  transactionalTemplatesUrl,
} from './menuUtils';

export const tabsInfo: Record<Scope, TabsInfo> = {
  settings: {
    general: {
      url: settingsGeneralUrl,
      name: 'views.settings.general',
    },
    billing: {
      url: settingsBillingUrl,
      name: 'views.settings.billing',
    },
    team: {
      url: '',
      name: 'views.settings.team',
      disabled: true,
    },
    myAccount: {
      url: settingsAccountUrl,
      name: 'views.settings.myAccount',
    },
  },
  broadcast: {
    lists: {
      url: broadcastContactListUrl,
      name: 'views.broadcast.lists',
    },
    campaigns: {
      url: broadcastCampaignsUrl,
      name: 'views.broadcast.campaigns',
    },
  },
  transactional: {
    lists: {
      url: transactionalContactListUrl,
      name: 'views.transactional.lists',
    },
    campaigns: {
      url: transactionalTemplatesUrl,
      name: 'views.transactional.campaigns',
      disabled: true,
    },
  },
};

