import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';

export enum PopupSize {
  smallxs = 'small-xs',
  small = 'small',
  medium = 'medium',
  large = 'large',
  largeXL = 'largeXL',
}

export const PopUp: React.FC<{
  children: React.ReactNode;
  closeModal?: Dispatch<SetStateAction<any>>;
  size?: PopupSize;
  backDrop?: boolean;
  logoOnTop?: boolean;
}> = ({
  children,
  size = PopupSize.medium,
  closeModal,
  backDrop = true,
  logoOnTop = false,
}) => {
  return (
    <>
      <div className='z-50 w-full h-full flex items-center fixed inset-0 py-3 overflow-y-scroll'>
        <div
          className='fixed inset-0 bg-black-transparentLight h-full w-full z-50'
          onClick={closeModal}
        ></div>
        <div
          className={classNames(
            'popup-inside bg-white mx-auto h-auto max-h-full rounded-xl shadow-lg flex flex-col justify-evenly items-center z-50',
            {
              'max-w-xl': size === PopupSize.large,
              'px-6':
                (size === PopupSize.large || PopupSize.medium) && !logoOnTop,
              'max-w-3xl w-full': size === PopupSize.largeXL,
              'px-10': size === PopupSize.largeXL && !logoOnTop,
              'max-w-lg': size === PopupSize.medium,
              'max-w-sm': size === PopupSize.small,
              'max-w-xs': size === PopupSize.smallxs,
              'px-3': size === PopupSize.smallxs && !logoOnTop,
              'py-6': !logoOnTop,
            },
          )}
        >
          {logoOnTop && (
            <div className='w-full py-4 px-2'>
              <div className='flex justify-start mb-4'>
                <img
                  className='w-30'
                  src='/images/HermesLogoHorizontal.svg'
                  alt='Hermes Protocol logotype'
                />
              </div>
              <hr className='mx-3' />
            </div>
          )}
          <div
            className={classNames({
              'px-6':
                (size === PopupSize.large || PopupSize.medium) && logoOnTop,
              'px-10': size === PopupSize.largeXL && logoOnTop,
              'px-3': size === PopupSize.smallxs && logoOnTop,
              'py-6': logoOnTop,
              'max-w-full': !logoOnTop,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
