import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FeedbackType, useModalContext } from '../contexts/modal-handling';
import { useContextUser } from '../contexts/user';
import { ModalType } from '../models/Enums';
import { api } from '../shared';
import { instanceOfUserData } from '../utils/instanceOf';
import { broadcastContactListUrl } from '../utils/menuUtils';
import { Button, ButtonJustify, ButtonSize, ButtonType } from './Button';
import Loader, { LoaderType } from './Loader';
import { PopUp, PopupSize } from './Popup';
import { SocialAccounts } from './modals';

enum WelcomeSteps {
  organizationName = 'organizationName',
  social = 'social',
}

const WelcomePopupFlow: React.FC<{
  condensed: boolean;
}> = ({ condensed }) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { userData, setUser, selectedOrganizationId, logout } = useContextUser();
  const { setModal } = useModalContext();
  const [width, setWidth] = useState(window.innerWidth);
  const [brandName, setBrandName] = useState<null | string>(null);
  const [enableNextButton, setEnableNextButton] = useState<boolean>(false);
  const [welcomePopupCurrentStep, setWelcomePopupCurrentStep] =
    useState<WelcomeSteps>(WelcomeSteps.organizationName);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setBrandName(inputValue);

    if (inputValue !== null && inputValue !== '') {
      setEnableNextButton(true);
    } else {
      setEnableNextButton(false);
    }
  };

  const handleGoNext = async () => {
    setIsLoading(true);
    const response = await api.client.create({
      brandName: brandName ?? import.meta.env.VITE_DEFAULT_BRAND_NAME,
    });

    if (response?.error) {
      setModal(
        '',
        `${t('error.unable_edit_client', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.log('Error: ', response.error);
    } else {
      setUser(response);
      handleBrandNamePopupState();
    }
    setIsLoading(false);

    if (
      (userData?.socials && userData.socials.length === 0) ||
      userData?.socials === null
    ) {
      setWelcomePopupCurrentStep(WelcomeSteps.social);
    } else {
      setIsOpenModal(false);
    }
  };

  const handleBrandNamePopupState = async () => {
    if (!userData) return;

    const response = await api.user.update(
      {
        noBrandNamePopup: true,
      },
      userData.userId,
    );

    if (typeof response === 'object' && instanceOfUserData(response)) {
      setUser(response);
    } else {
      setModal(
        '',
        `${t('error.error_occurred', { ns: 'common' })}`,
        FeedbackType.failure,
      );
      console.log('Error: ', response.error);
    }
  };

  useEffect(() => {
    localStorage.setItem('origin', 'on');
  }, []);

  useEffect(() => {
    if (userData?.noBrandNamePopup === false) {
      setModalType(ModalType.initialConfigFlow);
      setIsOpenModal(true);
    } else if (userData?.socials === null) {
      //verify socials
      setWelcomePopupCurrentStep(WelcomeSteps.social);
      setModalType(ModalType.initialConfigFlow);
      setIsOpenModal(true);
    }
    setIsOpenModal(true);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  const updateWidth = () => setWidth(window.innerWidth);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      {isOpenModal && modalType === ModalType.initialConfigFlow && (
        <>
          {welcomePopupCurrentStep === WelcomeSteps.organizationName && (
            <PopUp size={PopupSize.large} logoOnTop={true}>
              <>
                <div className='text-center pb-4'>
                  <h1 className='mb-10 font-medium text-xl font-md md:text-2xl mb-3 text-black text-center'>
                    {t('welcome_to', { ns: 'common' })}
                    <br />
                    {t('hermes_business', { ns: 'common' })}
                  </h1>
                  <h2 className='mb-2 text-left font-normal md:text-base text-black '>
                    {t('brand_organization_name', { ns: 'common' })}
                  </h2>
                  <div className='w-full mb-10'>
                    <input
                      onChange={(e) => handleNameChanged(e)}
                      type='text'
                      className='pr-1 w-full block h-8 border-2 border-gray-extralight60 opacity-1 h-5 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent'
                    />
                  </div>
                  <div className='flex justify-between items-end'>
                    <Link
                      to={broadcastContactListUrl}
                      onClick={handleGoNext}
                      className='text-center cursor-pointer underline font-normal text-base text-gray-light50 mb-1'
                    >
                      {t('action_skip', { ns: 'common' })}
                    </Link>
                    <div className='w-32'>
                      <Button
                        size={ButtonSize.medium}
                        type={ButtonType.primary}
                        justify={ButtonJustify.center}
                        fullWidth={true}
                        disabled={enableNextButton ? false : true}
                        onClick={handleGoNext}
                      >
                        {t('action_next', { ns: 'common' })}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            </PopUp>
          )}
          {welcomePopupCurrentStep === WelcomeSteps.social && (
            <PopUp size={PopupSize.medium} logoOnTop={true}>
              <>
                <div className='flex flex-col items-center text-center pb-4'>
                  <h1 className='mb-4 font-medium text-xl font-md md:text-2xl mb-3 text-black text-center'>
                    {t('last_step', { ns: 'common' })}
                  </h1>
                  <h2 className='mb-6 font-normal text-base md:text-lg text-black mx-4'>
                    {t('social_accounts', { ns: 'common' })}
                  </h2>
                  <div className='w-3/4 mb-8'>
                    <SocialAccounts />
                  </div>
                  <div className="text-gray-light60 font-normal text-sm">
                    {t('or_you_can', { ns: 'common' })}
                    <span onClick={logout} className="mx-2 underline cursor-pointer">
                      {t('logout2', { ns: 'common' })}
                    </span>
                    {t('instead', { ns: 'common' })}
                  </div>
                </div>
              </>
            </PopUp>
          )}
        </>
      )}
    </>
  );
};
export default WelcomePopupFlow;
