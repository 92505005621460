import { setTimeout } from 'timers';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useContextUser } from '../../contexts/user';
import { BlockchainType } from '../../models/Enums';
import { instanceOfUserData } from '../../utils/instanceOf';
import { ModalState } from '../../views/SignIn';
import { Button, ButtonSize, ButtonType } from '../Button';
import { FeedbackIcon, FeedbackModal } from './FeedbackModal';

export enum WalletSettingsState {
  ongoing = 'ongoing',
  connectWallet = 'connectWallet',
  walletVerified = 'walletVerified',
  hasWallet = 'hasWallet',
  verifyFailure = 'verifyFailure',
}

export const WalletsFlow: React.FC<{
  closeModalMain?: Dispatch<SetStateAction<any>>;
}> = ({ closeModalMain }) => {
  const { t } = useTranslation(['common']);
  const { userData, setUser } = useContextUser();

  const [width, setWidth] = useState(window.innerWidth);
  const [modalContent, setModalContent] = useState<
    ModalState | WalletSettingsState
  >(WalletSettingsState.ongoing);
  const [newWallet, setNewWallet] = useState<null | string>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [txError, setTxError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const connectWallet = async () => {
    setModalContent(WalletSettingsState.connectWallet);
    setIsOpenModal(true);
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const hasWallet = (newWallet: string): boolean => {
    const res = userData?.wallets.filter((wallet) => {
      return wallet.walletAddress === newWallet;
    });
    return (res && res.length > 0) || false;
  };

  const handleSignUp = async (
    walletAddress: string,
    signBytesResult: any,
    originalBytes: any,
    chainId: BlockchainType,
  ) => {
    setIsLoading(true);
    if (!hasWallet(walletAddress)) {
      try {
        const connection = await fetch(
          `${import.meta.env.VITE_API_URL}/fauna/wallets/business`,
          {
            method: 'POST',
            body: JSON.stringify({
              userId: userData?.userId,
              walletAddress: walletAddress,
              walletAlias: '',
              chainId,
              signBytesResult,
              originalBytes,
            }),
            credentials: 'include',
          },
        );
        const dataFromApi = await connection.json();
        if (
          typeof dataFromApi.data === 'object' &&
          instanceOfUserData(dataFromApi.data)
        ) {
          setUser(dataFromApi.data);
          setModalContent(WalletSettingsState.walletVerified);
        } else if (dataFromApi.data === 'elementExists') {
          setModalContent(WalletSettingsState.hasWallet);
        } else if (dataFromApi.data.error) {
          setTxError(dataFromApi.data.error);
          handleModal(WalletSettingsState.verifyFailure);
        } else {
          setModalContent(ModalState.failure);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      handleModal(WalletSettingsState.hasWallet);
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setTimeout(() => {
      closeModalMain && closeModalMain(false);
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <div className='my-2'>
      {modalContent === WalletSettingsState.ongoing && (
        <>
          <a
            href={`${
              import.meta.env.VITE_OAUTH_URL
            }/?origin=business&action=addWallet&userId=${userData?.userId}`}
            className='mt-4'
          >
            <Button
              type={ButtonType.secondary}
              size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
              fullWidth
              className='py-1.5'
            >
              <div className='text-gray-light60'>
                <img
                  src='/images/logo_icon.png'
                  alt='Hermes Protocol'
                  className='w-9'
                />
              </div>
              <span className='pl-6'>{t(`hermesLogin`, { ns: 'common' })}</span>
            </Button>
          </a>
        </>
      )}
      {modalContent === ModalState.failure && (
        <>
          <FeedbackModal
            title={'Warning'}
            text={t('modal_content.failure.verify_wallet', {
              ns: 'enumerations',
            })}
            icon={FeedbackIcon.warning}
          />
          <div className='text-right cursor-pointer'>
            <p
              className='text-base underline text-gold-400'
              onClick={() => {
                setIsOpenModal(false);
                connectWallet();
              }}
            >
              {t('modal_content.warning.try_again', {
                ns: 'enumerations',
              })}
            </p>
          </div>
        </>
      )}
      {modalContent === WalletSettingsState.hasWallet && (
        <>
          <FeedbackModal
            title={'Warning'}
            text={t('modal_content.has_wallet.modal_title', {
              ns: 'enumerations',
            })}
            icon={FeedbackIcon.warning}
          />

          {closeModal()}
        </>
      )}
      {modalContent === WalletSettingsState.walletVerified && (
        <>
          <FeedbackModal
            title={'Congratulations'}
            text={t('modal_content.new_wallet_added', {
              ns: 'enumerations',
            })}
            colorIcon={'bg-gold-400'}
            icon={FeedbackIcon.warning}
          />
          {closeModal()}
        </>
      )}
    </div>
  );
};
