import { faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline');

/* Spoiler */
class Spoiler extends Inline {
  static blotName = 'spoiler';
  static tagName = 'span';
  static icon = faEyeLowVision;

  static create() {
    const node = super.create();
    node.setAttribute('class', 'ql-spoiler');
    return node;
  }

  static formats() {
    return true;
  }
}

export default Spoiler;
