import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  broadcastMenuItems,
  settingsMenuItems,
  transactionalMenuItems,
  webhooksUrl,
} from '../utils/menuUtils';
import Logo from './Logo';
import OrganizationMenu from './OrganizationMenu';
import SideMenu, { ModeType } from './SideMenu';
import SideMenuItem from './SideMenuItem';
import { Tooltip } from './Tooltip';
import UserAccMenu from './UserAccMenu';
import { AirdropSvg } from './svgs/AirdropSvg';
import { BroadcastSvg } from './svgs/BroadcastSvg';
import { SettingsSvg } from './svgs/SettingsSvg';
import { TransactionalSvg } from './svgs/TransactionalSvg';
import { WebhooksSvg } from './svgs/WebhooksSvg';

export const Navbar: React.FC<{
  setIsCondensed: Dispatch<SetStateAction<any>>;
  isCondensed: boolean;
}> = ({ setIsCondensed, isCondensed }) => {
  const { t } = useTranslation(['common']);

  return (
    <SideMenu mode={isCondensed ? ModeType.condensed : ModeType.normal}>
      <Logo onClick={() => setIsCondensed(false)} condensed={isCondensed} />
      <div className='w-full flex justify-center px-4 mt-6 mb-10'>
        <OrganizationMenu />
      </div>
      <div className='overflow-x-hidden overflow-y-scroll grayCustomScrollbar h-full flex-grow'>
        <SideMenuItem
          icon={<BroadcastSvg />}
          title={t('menu.broadcast', { ns: 'common' })}
          urls={broadcastMenuItems}
          condensed={isCondensed}
        />
        <SideMenuItem
          icon={<TransactionalSvg />}
          title={t('menu.transactional', { ns: 'common' })}
          urls={transactionalMenuItems}
          condensed={isCondensed}
        />
        <Tooltip content={t('menu.coming_soon', { ns: 'common' })}>
          <SideMenuItem
            icon={<AirdropSvg />}
            title={t('menu.airdropCampaigns', { ns: 'common' })}
            urls={[]}
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <Tooltip content={t('menu.coming_soon', { ns: 'common' })}>
          <SideMenuItem
            icon={<WebhooksSvg />}
            title={t('menu.automation', { ns: 'common' })}
            urls={[webhooksUrl]}
            condensed={isCondensed}
            disabled={true}
          ></SideMenuItem>
        </Tooltip>
        <SideMenuItem
          icon={<SettingsSvg />}
          title={t('menu.settings', { ns: 'common' })}
          urls={settingsMenuItems}
          condensed={isCondensed}
        />
      </div>
      <div className='w-full flex justify-end px-6 pb-4 mt-2'>
        <UserAccMenu />
      </div>
    </SideMenu>
  );
};
