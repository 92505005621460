import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { api } from '../../shared';
import { Button, ButtonJustify, ButtonSize, ButtonType } from '../Button';
import Loader, { LoaderType } from '../Loader';
import Input, { InputType } from '../forms/Input';

const NewOrganizationModal = () => {
  const { t } = useTranslation(['common']);
  const { setModal, clearModal } = useModalContext();
  const { setUser, userData, selectOrganization, selectedOrganizationId } =
    useContextUser();

  const [newOrganizationName, setNewOrganizationName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeOrganizationName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setNewOrganizationName(e.target.value);

  const handleCreate = async () => {
    setIsLoading(true);

    const response = await api.client.create({
      brandName: newOrganizationName,
    });

    if (response?.error) {
      setModal(
        t('fetch.organization.create.failure.title', { ns: 'common' }),
        t('fetch.organization.create.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
      console.log('Error: ', response.error);
    } else {
      const clientId = response?.clientId.filter(
        (c) => !userData?.clientId.includes(c),
      )?.[0];

      if (clientId) {
        setUser(response);
        selectOrganization(clientId);
        setModal(
          t('fetch.organization.create.success.title', { ns: 'common' }),
          t('fetch.organization.create.success.text', { ns: 'common' }),
          FeedbackType.success,
        );
        setTimeout(() => clearModal(), 3000);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <form></form>
      <Input
        value={newOrganizationName}
        onChange={handleChangeOrganizationName}
        type={InputType.Text}
      />
      <div className='flex justify-center mt-4'>
        <div className='w-32'>
          <Button
            size={ButtonSize.medium}
            type={ButtonType.primary}
            justify={ButtonJustify.center}
            fullWidth={true}
            onClick={handleCreate}
            disabled={!newOrganizationName}
          >
            {t('action.create', { ns: 'common' })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewOrganizationModal;
