import { AuthService } from '../services/auth.service';
import { CampaignService } from '../services/campaign.service';
import { ClientService } from '../services/client.service';
import { ContactListService } from '../services/contactList.service';
import { FileService } from '../services/file.service';
import { SocialService } from '../services/social.service';
import { UserService } from '../services/user.service';
import { WalletService } from '../services/wallet.service';

export namespace api {
  export const auth = new AuthService();
  export const campaign = new CampaignService();
  export const client = new ClientService();
  export const contactList = new ContactListService();
  export const social = new SocialService();
  export const file = new FileService();
  export const user = new UserService();
  export const wallet = new WalletService();
}
