import React, { ReactNode, useState } from 'react';
import BottomMenu from '../../components/BottomMenu';
import Loader, { LoaderType } from '../../components/Loader';
import { Navbar } from '../../components/Navbar';
import RightSection from '../../components/RightSection';

import { TabMenu } from '../../components/TabMenu';
import { tabsInfo } from '../../utils/tabs';

type Props = {
  name: string;
  scope: Scope;
  children: ReactNode;
};

export enum Scope {
  settings = 'settings',
  broadcast = 'broadcast',
  transactional = 'transactional',
}

const Frame: React.FC<Props> = React.memo(({ name, scope, children }) => {
  const [isCondensed, setIsCondensed] = useState(false);
  const [isLoading] = useState(false);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <TabMenu name={name} tabsInfo={tabsInfo[scope]}>
          {children}
        </TabMenu>
      </RightSection>
      <BottomMenu />
    </>
  );
});

export default Frame;
