import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  XAxis,
  YAxis,
} from 'recharts';

import BottomMenu from '../../components/BottomMenu';
import { Navbar } from '../../components/Navbar';
import { PopUp, PopupSize } from '../../components/Popup';
import RightSection from '../../components/RightSection';

const data = [
  {
    name: '1-Jan',
    Sent: 900,
    Open: 40,
    'Click Rate': 35,
    'Unsubscribe Rate': 1,
  },
  {
    name: '2-Jan',
    Sent: 900,
    Open: 50,
    'Click Rate': 45,
    'Unsubscribe Rate': 5,
  },
  {
    name: '3-Jan',
    Sent: 900,
    Open: 80,
    'Click Rate': 75,
    'Unsubscribe Rate': 5,
  },
  {
    name: '4-Jan',
    Sent: 900,
    Open: 180,
    'Click Rate': 100,
    'Unsubscribe Rate': 10,
  },
  {
    name: '5-Jan',
    Sent: 900,
    Open: 200,
    'Click Rate': 170,
    'Unsubscribe Rate': 5,
  },
  {
    name: '6-Jan',
    Sent: 900,
    Open: 250,
    'Click Rate': 230,
    'Unsubscribe Rate': 10,
  },
  {
    name: '7-Jan',
    Sent: 900,
    Open: 450,
    'Click Rate': 270,
    'Unsubscribe Rate': 50,
  },
  {
    name: '8-Jan',
    Sent: 900,
    Open: 500,
    'Click Rate': 250,
    'Unsubscribe Rate': 60,
  },
  {
    name: '9-Jan',
    Sent: 900,
    Open: 600,
    'Click Rate': 490,
    'Unsubscribe Rate': 30,
  },
  {
    name: '10-Jan',
    Sent: 900,
    Open: 740,
    'Click Rate': 520,
    'Unsubscribe Rate': 40,
  },
];
export const CampaignStatistics: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [isCondensed, setIsCondensed] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [navbarWidth, setNavbarWidth] = useState(0);

  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <>
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <div className='relative md:w-full flex items-center md:px-6 px-4 sm:max-w-6xl'>
          <section className='min-h-screen sm:relative w-full ml-4'>
            <h1 className='mt-2 mb-10 w-full justify-between mb-3'>
              {t(`campaigns.campaignStatistics`, { ns: 'common' })}
            </h1>
            <AreaChart
              width={1440}
              height={300}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis dataKey='name' />
              <YAxis type='number' domain={['0', '1000']} />
              <CartesianGrid strokeDasharray='3 3' />
              <Legend verticalAlign='top' iconType='rect' height={50} />
              <ChartTooltip />
              <Area
                type='monotone'
                dataKey='Sent'
                stroke='#e8d7c5'
                fill='#e8d7c5'
              />
              <Area
                type='monotone'
                dataKey='Open'
                stroke='#cc9966'
                fill='#d7ba99'
              />
              <Area
                type='monotone'
                dataKey='Click Rate'
                stroke='#957552'
                fill='#b28c62'
              />
              <Area
                type='monotone'
                dataKey='Unsubscribe Rate'
                stroke='#6b7280'
                fill='#6b7280'
              />
            </AreaChart>
          </section>
        </div>
      </RightSection>
      <BottomMenu />
      {isOpenModal && (
        <PopUp
          size={PopupSize.largeXL}
          closeModal={() => setIsOpenModal(false)}
        >
          <div className='text-left py-4'>
            <p className='mb-6 mt-5'>
              {t('contactList.popupMessage', { ns: 'common' })}
            </p>
            <div className='flex justify-center mb-4'>
              <img
                className='max-w-xl'
                src='/images/contactList_msg_example.png'
                alt='Contact List message example'
              />
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};
