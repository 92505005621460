import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SearchInput: React.FC<{
  searchFilter: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ searchFilter, handleChange }) => {
  const { t } = useTranslation(['common']);

  return (
    <div className='relative md:w-full flex items-center pb-3 ml-4 py-5 max-w-6xl'>
      <section className=' md:px-0 flex flex-col w-full'>
        <div className='relative cursor-pointer mt-3'>
          <div className='absolute left-0 py-3 px-4 mt-0.5'>
            <FontAwesomeIcon
              icon={faSearch as IconProp}
              className={`text-gray-light50 text-sm float-right`}
            ></FontAwesomeIcon>
          </div>
          <input
            id='searchField'
            type='text'
            className='pl-10 w-full block h-8 border-2 border-gray-extralight60 opacity-1 h-5 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent'
            placeholder={t('search', { ns: 'common' })}
            name='searchFilter'
            value={searchFilter}
            onChange={handleChange}
          />
        </div>
      </section>
    </div>
  );
};
