import React from 'react';
import classNames from 'classnames';
import { Link, useMatch } from 'react-router-dom';

const BottomMenuItem: React.FC<{
  icon: React.ReactElement;
  url: string;
  disabled?: true;
}> = ({ icon, url, disabled }) => {
  const isActive = useMatch(url) != null;

  return (
    <div
      className={classNames('bottom-menu-item py-5 mx-auto', {
        'cursor-not-allowed': disabled,
      })}
    >
      {!disabled ? (
        <Link to={url} className='z-10 flex'>
          <div className='z-10 flex'>
            <div
              className={classNames(
                'icon',
                { 'text-gold-400': isActive },
                { 'text-gray-light40': !isActive },
              )}
            >
              {icon}
            </div>
          </div>
        </Link>
      ) : (
        <div className='z-10 flex'>
          <div
            className={classNames(
              'icon',
              { 'text-gold-400': isActive },
              { 'text-gray-light40': !isActive },
            )}
          >
            {icon}
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomMenuItem;
