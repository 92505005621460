export const broadcastContactListUrl = '/broadcast/contact-list';
export const broadcastContactListAddUrl = '/broadcast/contact-list/add';
export const broadcastContactListEditUrl = '/broadcast/contact-list/edit';
export const broadcastCampaignsUrl = '/broadcast/campaigns';
export const broadcastCampaignAddUrl = '/broadcast/campaign/add';
export const broadcastCampaignEditUrl = '/broadcast/campaign/edit';
export const broadcastCampaignStatisticUrl = '/broadcast/campaign/statistic';
export const transactionalContactListUrl = '/transactional/contact-list';
export const transactionalContactListAddUrl = '/transactional/contact-list/add';
export const transactionalContactListEditUrl =
  '/transactional/contact-list/edit';
export const transactionalTemplatesUrl = '/transactional/templates';
export const transactionalTemplatesAddUrl = '/transactional/templates/add';
export const transactionalTemplatesEditUrl = '/transactional/templates/edit';
export const transactionalTemplatesStatisticUrl =
  '/transactional/templates/statistic';
export const webhooksUrl = '/history';
export const settingsGeneralUrl = '/settings/general';
export const settingsBillingUrl = '/settings/billing';
export const settingsTeamUrl = '/settings/team';
export const settingsAccountUrl = '/settings/account';
export const settingsOthersUrl = '/settings/account/others';
export const pricingUrl = '/pricing';
export const hermesContactUsUrl = 'https://hermesprotocol.io/contact-us';

export const broadcastMenuItems = [
  broadcastContactListUrl, //* This is the url the navbar will use to navigate to the broadcast frame
  broadcastCampaignsUrl,
  broadcastCampaignAddUrl,
  broadcastContactListAddUrl,
  broadcastCampaignStatisticUrl,
  broadcastContactListEditUrl,
  broadcastCampaignEditUrl,
];

export const transactionalMenuItems = [
  transactionalContactListUrl, //* This is the url the navbar will use to navigate to the transactional frame
  transactionalTemplatesUrl,
  transactionalContactListAddUrl,
  transactionalTemplatesAddUrl,
  transactionalTemplatesStatisticUrl,
  transactionalContactListEditUrl,
  transactionalTemplatesEditUrl,
];

export const settingsMenuItems = [
  settingsGeneralUrl, //* This is the url the navbar will use to navigate to the settings frame
  settingsBillingUrl,
  settingsTeamUrl,
  settingsAccountUrl,
  settingsOthersUrl,
];
