import {
  broadcastContactListUrl,
  settingsAccountUrl,
  transactionalContactListUrl
} from '../utils/menuUtils';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BottomMenuItem from './BottomMenuItem';
import Logo from './Logo';
import { BroadcastSvg } from './svgs/BroadcastSvg';
import { TransactionalSvg } from './svgs/TransactionalSvg';
import { SettingsSvg } from './svgs/SettingsSvg';
import OrganizationMenuMobile from './OrganizationMenuMobile';

export enum ActiveMenu {
  dash = 'dashboard',
  notifications = 'notifications',
  feed = 'feed',
  settings = 'settings',
}

const BottomMenu: React.FC = () => {
  const { t } = useTranslation(['common']);
  return (
    <div
      className={classNames(
        'z-20 fixed bottom-0 left-0 right-0 h-26 bg-white shadow-bottom-menu lg:hidden',
      )}
    >
      <div
        className={classNames(
          'bottom-menu px-9 grid grid-cols-5 justify-between items-center',
        )}
      >
        <BottomMenuItem icon={<BroadcastSvg />} url={broadcastContactListUrl} />
        <BottomMenuItem icon={<TransactionalSvg />} url={transactionalContactListUrl} />
        {/* <Tooltip content={t("menu.coming_soon", { ns: "common" })}>
          <BottomMenuItem icon={<TransactionalSvg />} url={broadcastCampaignsUrl} disabled={true} />
        </Tooltip> */}
        <div className=" w-full h-full">
          <OrganizationMenuMobile />
      
        </div>
        {/*  <Tooltip content={t("menu.coming_soon", { ns: "common" })}>
          <BottomMenuItem icon={<WebhooksSvg />} url={webhooksUrl} disabled={true} />
        </Tooltip> */}
        <div></div>
        <BottomMenuItem icon={<SettingsSvg />} url={settingsAccountUrl} />
      </div>
    </div>
  );
};

export default BottomMenu;
