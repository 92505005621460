import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import RightSectionLogin from '../components/RightSectionLogin';
import SideMenu, { ModeType } from '../components/SideMenu';

export enum SignUpStep {
  connectWallet = 'connectWallet',
  verifyWallet = 'verifyWallet',
  signUpCompleted = 'signUpCompleted',
}

export enum ModalState {
  verify = 'verify',
  success = 'success',
  failure = 'failure',
  hasUser = 'hasUser',
  noUser = 'noUser',
  noSocial = 'noSocial',
  mfa = 'mfa',
  mfaFailure = 'mfaFailure',
  mfaValidate = 'mfaValidate',
  guestUser = 'guestUser',
}

export enum Network {
  columbus5 = 'columbus-5',
  phoenix1 = 'phoenix-1',
  pisco1 = 'pisco-1',
}

/* Sign in component receives as props from the App the global variable that holds user data, updates it and sends back */
export const SignInPage: React.FC = () => {
  const { t } = useTranslation(['common', 'enumerations']);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <>
      <div className='flex flex-grow'>
        <SideMenu mode={ModeType.wide}>
          <div></div>
          <div className='flex flex-col'>
            <h2 className='self-center text-md md:text-lg'>
              {t('signup.title', { ns: 'common' })}
            </h2>
          </div>
          <div>
            <p className='self-center static mt-10 text-sxm'>
              {t('modal_content.has_account', { ns: 'enumerations' })}
              <NavLink to='/' className={'text-gold-400'}>
                {t('login.title', { ns: 'common' })}
              </NavLink>
            </p>
          </div>
        </SideMenu>
        <RightSectionLogin />
      </div>
    </>
  );
};
