import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Badge } from './Badge';

const SideMenuItem: React.FC<{
  icon: React.ReactElement;
  title: string;
  urls: string[];
  condensed: boolean;
  disabled?: true;
  beta?: true;
}> = ({ icon, title, urls, condensed, disabled, beta }) => {
  const { t } = useTranslation(['common', 'enumerations', '']);
  const isActive = urls.includes(location.pathname);

  return (
    <div
      className={classNames(
        'side-menu-item py-3',
        { 'px-8': condensed },
        { 'px-7': !condensed },
        { active: isActive },
        { 'cursor-not-allowed': disabled },
      )}
    >
      {!disabled && (
        <Link to={urls[0]} className='z-10 flex'>
          <div className='z-10 flex w-full'>
            <div
              className={classNames(
                'icon w-4 mr-3',
                { 'text-gold-400': isActive },
                { 'text-gray-light40': !isActive },
              )}
            >
              {icon}
            </div>
            {!condensed && (
              <div className='w-full flex justify-between items-center'>
                <div
                  className={classNames(
                    'side-menu-title text-gray-light50 font-semibold ml-3',
                    { 'text-gold-400': isActive },
                  )}
                >
                  {title}
                </div>
                {beta && <Badge text={t('menu.beta', { ns: 'common' })} />}
              </div>
            )}
          </div>
        </Link>
      )}
      {disabled && (
        <div
          className={classNames(
            'icon w-4 mr-3 z-10 bg-gray flex justify-start items-center',
          )}
        >
          <div className={classNames('icon w-4 mr-3 text-gray-300')}>
            {icon}
          </div>
          {!condensed && (
            <div
              className={classNames(
                'side-menu-title font-medium ml-3 text-sxm text-gray-300',
              )}
            >
              {title}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SideMenuItem;
