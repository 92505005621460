import React, { useEffect, useState } from 'react';

import {
  AuthModalConfig,
  FeedbackType,
  ModalType,
  useModalContext,
} from '../contexts/modal-handling';
import { instanceOfAuthFeedModal } from '../utils/instanceOf';
import { PopUp, PopupSize } from './Popup';
import ModalAuth, { ModalContent as AuthModalProps } from './auth/ModalAuth';
import {
  FeedbackIcon,
  FeedbackModal,
  Props as FeedbackProps,
} from './modals/FeedbackModal';
import { InfoModal, Props as InfoModalProps } from './modals/InfoModal';
import { YesNoModal, Props as YesNoModalProps } from './modals/YesNoModal';

const ModalHandling = () => {
  const {
    type,
    config,
    clearModal,
    clearInfoModal,
    clearAuthModal,
    clearYesNoModal,
  } = useModalContext();
  const [feedbackModalConfig, setFeedbackModalConfig] = useState<
    FeedbackProps | undefined
  >(undefined);
  const [infoModalConfig, setInfoModalConfig] = useState<
    InfoModalProps | undefined
  >(undefined);
  const [authModalConfig, setAuthModalConfig] = useState<
    AuthModalProps | undefined
  >(undefined);
  const [yesNoModalConfig, setYesNoModalConfig] = useState<
    YesNoModalProps | undefined
  >(undefined);

  const onCloseDefault = () => {
    handleModalChange(undefined);
    clearModal();
    clearInfoModal();
    clearAuthModal();
    clearYesNoModal();
  };
  const getFeedbackIcon = (variation?: FeedbackType | undefined) => {
    let icon;
    switch (variation) {
      case FeedbackType.failure:
        icon = FeedbackIcon.failure;
        break;
      case FeedbackType.success:
        icon = FeedbackIcon.success;
        break;
      case FeedbackType.custom:
        icon = FeedbackIcon.custom;
        break;
      case FeedbackType.warning:
      default:
        icon = FeedbackIcon.warning;
        break;
    }
    return icon;
  };
  const handleModalChange = (type?: ModalType, config?: any) => {
    switch (type) {
      case ModalType.feedback:
        setFeedbackModalConfig({
          title: config.title,
          text: config.text ? config.text : undefined,
          closeBtn: config.closeBtn ? config.closeBtn : false,
          onClose: config.onClose
            ? () => {
                config.onClose!();
                onCloseDefault();
              }
            : () => {
                onCloseDefault();
              },
          icon: getFeedbackIcon(config?.type),
          customIcon: config.customIcon,
          additionalText: config.extraText ? config.extraText : undefined,
          additionalJsx: config.extraJSX,
        });
        break;
      case ModalType.info:
        setInfoModalConfig({
          title: config.title,
          children: React.isValidElement(config.children)
            ? config.children
            : undefined,
          onClose: config.onClose
            ? () => {
                config.onClose!();
                onCloseDefault();
              }
            : () => {
                onCloseDefault();
              },
        });
        break;
      case ModalType.auth:
        setAuthModalConfig({
          logo: config.logo,
          modalImage: config.image,
          modalTitle: config.title,
          name: config.name,
          additionalTitle: config.extraText,
          children: React.isValidElement(config.children)
            ? config.children
            : undefined,
          description: config.description,
          alignElements: config.alignElements,
          primaryButton: config.primaryBtn,
          secondaryButton: config.secondaryBtn,
          primaryButtonOnClick: config.primaryBtnOnClick,
          secondaryButtonOnClick: config.secondaryBtnOnClick,
        });
        break;
      case ModalType.yesNo:
        setYesNoModalConfig({
          title: config.title,
          text: config.text ? config.text : undefined,
          yesBtn: config.yesBtn,
          noBtn: config.noBtn,
          onYes: config.onYes,
          onNo: config.onNo,
        });
        break;
      default:
        setFeedbackModalConfig(undefined);
        setInfoModalConfig(undefined);
        setAuthModalConfig(undefined);
        setYesNoModalConfig(undefined);
    }
  };

  useEffect(() => {
    handleModalChange(type ? type : undefined, config);
  }, [config]);

  return (
    <>
      {config && (
        <>
          {feedbackModalConfig &&
            instanceOfAuthFeedModal(config) &&
            type === ModalType.feedback && (
              <PopUp
                size={(config as AuthModalConfig).popupSize || PopupSize.medium}
                closeModal={() => handleModalChange(undefined, undefined)}
              >
                <FeedbackModal {...feedbackModalConfig} />
              </PopUp>
            )}

          {infoModalConfig !== undefined && (
            <PopUp size={PopupSize.largeXL}>
              <InfoModal {...infoModalConfig} />
            </PopUp>
          )}

          {authModalConfig &&
            instanceOfAuthFeedModal(config) &&
            type === ModalType.auth && (
              <PopUp
                size={(config as AuthModalConfig).popupSize || PopupSize.medium}
              >
                <ModalAuth {...authModalConfig} />
              </PopUp>
            )}

          {yesNoModalConfig && (
            <PopUp
              size={PopupSize.medium}
              closeModal={() => handleModalChange(undefined, undefined)}
            >
              <YesNoModal {...yesNoModalConfig} />
            </PopUp>
          )}
        </>
      )}
    
    </>
  );
};
export default ModalHandling;
