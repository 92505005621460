export const AirdropSvg = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M15.6352 12.5V17.5H16.9034C17.1808 17.5 17.4463 17.5586 17.684 17.6641L22.4434 12.5H21.3219C21.3338 12.293 21.3418 12.0859 21.3418 11.875C21.3418 9.37891 20.462 7.14219 19.079 5.65195C22.6891 6.92461 24.409 9.78906 24.9797 11.7813C25.0867 12.1523 24.7538 12.5 24.3971 12.5H24.1593L18.5995 18.5273C18.7302 18.7813 18.8055 19.0703 18.8055 19.375V23.125C18.8055 24.1602 17.9535 25 16.9034 25H13.0633C12.0489 25 11.1612 24.1602 11.1612 23.125V19.375C11.1612 19.0703 11.2721 18.7813 11.4029 18.5273L5.84381 12.5H5.60485C5.21293 12.5 4.91504 12.1523 5.02196 11.7813C5.59495 9.78906 7.31205 6.92461 10.9234 5.65195C9.54037 7.14219 8.66061 9.37891 8.66061 11.875C8.66061 12.0859 8.66695 12.293 8.67924 12.5H7.55775L12.3183 17.6641C12.5561 17.5586 12.7859 17.5 13.0633 17.5H14.3315V12.5H9.94854C9.93665 12.293 9.89306 12.0859 9.89306 11.875C9.89306 9.88281 10.5588 8.12539 11.5218 6.8918C12.4848 5.65977 13.7251 5 14.9655 5C16.2772 5 17.5176 5.65977 18.4806 6.8918C19.4435 8.12539 20.0736 9.88281 20.0736 11.875C20.0736 12.0859 20.0657 12.293 20.0538 12.5H15.6352Z' />
    </svg>
  );
};
