import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { UserData } from '../../models/Interfaces';
import { api } from '../../shared';
import { FeedbackType, useModalContext } from '../modal-handling';

/* Interface creation */
export interface UserDataContext {
  userData: UserData | null;
  isLoggedIn: boolean | null;
  selectedOrganizationId: string | null;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | null>>;
  setUser(user?: UserData | null, isLoggedInStatus?: boolean): void;
  getUser(): void;
  selectOrganization(organizationId: string): void;
  logout(): void;
}

/* Context */
const UserContext = createContext<UserDataContext>({
  userData: null,
  isLoggedIn: null,
  selectedOrganizationId: null,
  setUser: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
  getUser: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
  setIsLoggedIn: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
  selectOrganization: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
  logout: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
});

/* Using Context*/
export const useContextUser = () => useContext(UserContext);

/* Provider fn */
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setModal } = useModalContext();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | null
  >(null);

  const setUser = (user: UserData | null, isLoggedInStatus?: boolean) => {
    setUserData(user);
    setIsLoggedIn(isLoggedInStatus || isLoggedIn);
  };

  const getUser = () => {
    if (userData) return;

    api.auth
      .isLoggedIn()
      .then((data: any) => {
        if (data?.error) {
          setModal(
            '',
            `${t('error.error_occurred', { ns: 'common' })}`,
            FeedbackType.failure,
          );
          console.log('Error: ', data.error);
          setIsLoggedIn(false);
          return;
        }

        setUserData(data);
        setIsLoggedIn(true);
      })
      .catch((error: any) => {
        setModal(
          '',
          `${t('error.error_occurred', { ns: 'common' })}`,
          FeedbackType.failure,
        );
        console.log('Error: ', error);
        setIsLoggedIn(false);
      });
  };

  const selectOrganization = (organizationId: string) => {
    setSelectedOrganizationId(organizationId);
  };

  const logout = async () => {
    const response = await api.auth.logout();
    if (response) {
      setIsLoggedIn(false);
      setUserData(null);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const dataUser = {
    userData: userData,
    isLoggedIn: isLoggedIn,
    selectedOrganizationId: selectedOrganizationId,
    setIsLoggedIn,
    setUser,
    getUser,
    selectOrganization,
    logout,
  };

  return (
    <UserContext.Provider value={dataUser}>{children}</UserContext.Provider>
  );
};
