import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

interface Props {
  icon: IconProp;
  bgContainerTransparent?: boolean;
  containerWidth?: number;
  containerHeight?: number;
  fontSize?: string;
  textColor?: string;
  shadowIcons?: boolean;
  fontMobile?: string;
  className?: string;
}

const Icon = ({
  icon,
  fontMobile,
  textColor,
  fontSize,
  shadowIcons,
  className,
  containerWidth,
  containerHeight,
  bgContainerTransparent,
}: Props & FontAwesomeIconProps) => {
  return (
    <div
      className={`${shadowIcons && 'shadow-icons'} ${
        bgContainerTransparent ?? 'bg-white '
      }${
        className ? className : ' w-8 h-8'
      } rounded-full flex items-center justify-center`}
    >
      <FontAwesomeIcon
        icon={icon}
        className={`${textColor} ${fontMobile ?? 'text-sm'}  md:${fontSize}`}
      ></FontAwesomeIcon>
    </div>
  );
};

export default Icon;
