import { useTranslation } from 'react-i18next';
import { Button, ButtonJustify, ButtonSize, ButtonType } from '../Button';

export type Props = {
  title?: string;
  onClose?: Function;
  children?: React.ReactNode;
};

export const InfoModal = ({ title, onClose, children }: Props) => {
  const { t } = useTranslation(['common', 'enumerations']);
  return (
    <>
      {title && (
        <div className='text-center py-4'>
          <h3 className='lg:text-xl'>{title}</h3>
        </div>
      )}
      {children}
      {onClose && (
        <div className='w-full flex justify-end'>
          <Button
            onClick={() => onClose()}
            type={ButtonType.cancel}
            size={ButtonSize.medium}
            justify={ButtonJustify.center}
          >
            {t('action_close', { ns: 'common' })}
          </Button>
        </div>
      )}
    </>
  );
};
