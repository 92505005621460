import { APIService } from './api.service';

export class SocialService extends APIService {
  constructor() {
    super();
  }

  public async delete(data: any, socialId: string): Promise<any> {
    return await this._put(`/fauna/social/${socialId}/${this.origin}`, data);
  }
}
