/*----------------------------------------------------------------*/

import { DynamicField } from '../models/Interfaces';

/*Structures*/
enum ValidationType {
  required = 'required',
  requiredSelect = 'requiredSelect',
  number = 'number',
  date = 'date',
  phone = 'phone',
  email = 'email',
  creditcardnumber = 'creditcardnumber',
  url = 'url',
}

enum ValidationFields {
  accountSettingsWebsiteURL = 'accountSettings.websiteURL',
  accountSettingsTermsURL = 'accountSettings.termsURL',
  accountSettingsPrivacyURL = 'accountSettings.privacyURL',
  billingSettingsEmailContact = 'billingSettings.emailContact',
  campaignsSubjectLink = 'campaigns.subjectLink',
  campaignsSubject = 'campaigns.subject',
  campaignsCampaignName = 'campaigns.campaignName',
  campaignsContactList = 'campaigns.contactList',
  campaignsContent = 'campaigns.content',
  campaignExtraURLsName = 'campaigns.extraURLS.name',
  campaignExtraURLsValue = 'campaigns.extraURLS.value',
  contactListInternalName = 'contactList.nameInternal',
  contactListPublicName = 'contactList.namePublic',
  contactListDescription = 'contactList.description',
}
interface ValidationRule {
  field: string;
  rules: ValidationType[];
}

export const validationRules: ValidationRule[] = [
  {
    field: ValidationFields.accountSettingsWebsiteURL,
    rules: [ValidationType.url],
  },
  {
    field: ValidationFields.accountSettingsTermsURL,
    rules: [ValidationType.url],
  },
  {
    field: ValidationFields.accountSettingsPrivacyURL,
    rules: [ValidationType.url],
  },
  {
    field: ValidationFields.billingSettingsEmailContact,
    rules: [ValidationType.email],
  },
  { field: ValidationFields.campaignsSubjectLink, rules: [ValidationType.url] },
  {
    field: ValidationFields.campaignExtraURLsValue,
    rules: [ValidationType.url, ValidationType.required],
  },
  {
    field: ValidationFields.campaignsCampaignName,
    rules: [ValidationType.required],
  },
  {
    field: ValidationFields.campaignsContactList,
    rules: [ValidationType.requiredSelect],
  },
  {
    field: ValidationFields.campaignsSubject,
    rules: [ValidationType.required],
  },
  {
    field: ValidationFields.campaignsContent,
    rules: [ValidationType.required],
  },
  {
    field: ValidationFields.campaignExtraURLsName,
    rules: [ValidationType.required],
  },
  {
    field: ValidationFields.contactListInternalName,
    rules: [ValidationType.required],
  },
  {
    field: ValidationFields.contactListPublicName,
    rules: [ValidationType.required],
  },
  {
    field: ValidationFields.contactListDescription,
    rules: [ValidationType.required],
  },
];

/*----------------------------------------------------------------*/
/*1st order functions*/
const validateRequired: (value: any) => boolean = (value) =>
  value === undefined || value === null || value === '' ? true : false;

const validateRequiredSelect: (value: any) => boolean = (value) =>
  parseInt(value) !== -1 ? false : true;

const validateNumber: (value: any) => boolean = (value) =>
  isNaN(parseInt(value)) ? true : false;

const validateDate: (value: any) => boolean = (value) =>
  !/^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/.test(value);

const validatePhone: (value: any) => boolean = (value) =>
  !/^[0-9]{9}$/.test(value);

const validateEmail: (value: any) => boolean = (value) =>
  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);

const validateCreditCardNumber: (value: any) => boolean = (value) =>
  !/^[0-9]{16}$/.test(value);

const validateURL: (value: any) => boolean = (value) =>
  !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
    value,
  );

/*----------------------------------------------------------------*/
/*2nd order functions*/
export const validate: (
  t: any,
  value: any,
  validators: ValidationType[],
) => boolean | string = (t, value, validators) => {
  if (!validators?.length) return false;
  for (let i = 0; i < validators.length; i++) {
    switch (validators[i]) {
      case ValidationType.required:
        if (validateRequired(value))
          return t('validation.required', { ns: 'common' });
        break;
      case ValidationType.requiredSelect:
        if (validateRequired(value) || validateRequiredSelect(value))
          return t('validation.requiredSelect', { ns: 'common' });
        break;
      case ValidationType.number:
        if (!validateRequired(value) && validateNumber(value))
          return t('validation.number', { ns: 'common' });
        break;
      case ValidationType.date:
        if (!validateRequired(value) && validateDate(value))
          return t('validation.date', { ns: 'common' });
        break;
      case ValidationType.phone:
        if (!validateRequired(value) && validatePhone(value))
          return t('validation.phone', { ns: 'common' });
        break;
      case ValidationType.email:
        if (!validateRequired(value) && validateEmail(value))
          return t('validation.email', { ns: 'common' });
        break;
      case ValidationType.creditcardnumber:
        if (!validateRequired(value) && validateCreditCardNumber(value))
          return t('validation.creditCard', { ns: 'common' });
        break;
      case ValidationType.url:
        if (!validateRequired(value) && validateURL(value))
          return t('validation.URL', { ns: 'common' });
        break;
    }
  }
  return false;
};

export const validateField: (t: any, name: string, value: any) => any = (
  t,
  name,
  value,
) => {
  let validationRule;
  if (name === 'campaigns.extraURLs') {
    return value.map((entry: DynamicField) => {
      return {
        name: validate(
          t,
          entry.name,
          validationRules.filter(
            (validationRule) =>
              validationRule.field === `campaigns.extraURLS.name`,
          )[0]?.rules,
        ),
        value: validate(
          t,
          entry.value,
          validationRules.filter(
            (validationRule) =>
              validationRule.field === `campaigns.extraURLS.value`,
          )[0]?.rules,
        ),
      };
    });
  } else {
    validationRule = validationRules.filter(
      (validationRule) => validationRule.field === name,
    );
    if (validationRule?.length !== 1) return false;
    validationRule = validationRule[0];

    return validate(t, value, validationRule.rules);
  }
};

export const validateObject: (
  t: any,
  obj: any,
  fieldPrefix: string,
) => { [k: string]: string | boolean } = (t, obj, fieldPrefix = ``) => {
  return Object.fromEntries(
    Object.entries(obj)?.map(([key, value]) => [
      key,
      validateField(t, `${fieldPrefix}${key}`, value),
    ]),
  );
};

export const getValidationResult = (
  updatedValidation: any,
  isArray: boolean = false,
) => {
  let validationErrors;
  if (!isArray)
    validationErrors = Object.values(updatedValidation)?.filter(
      (value) => value !== false && value !== '',
    );
  else
    validationErrors = updatedValidation?.filter((field: any) => {
      return Object.values(field)?.filter(
        (value) => value !== false && value !== '',
      ).length;
    });
  return validationErrors?.length ? true : false;
};
