import { t } from 'i18next';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChartBar,
  faEye,
  faPen,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { Button, ButtonSize, ButtonType } from '../../components/Button';
import Icon from '../../components/Icon';
import { PopUp, PopupSize } from '../../components/Popup';
import { Tooltip, TooltipStyle } from '../../components/Tooltip';
import Frame, { Scope as TabsScope } from '../../components/layout/Frame';
import { DeleteModal } from '../../components/modals';
import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { ModalType } from '../../models/Enums';
import { Campaign, CampaignLine, Clients } from '../../models/Interfaces';
import { api } from '../../shared';
import {
  broadcastCampaignAddUrl,
  broadcastCampaignEditUrl,
} from '../../utils/menuUtils';

const isFutureCampaign = (campaignDate: Date) => {
  const today = new Date();
  return today.getTime() - campaignDate.getTime() < 0;
};

interface Filters {
  searchFilter: string;
  filterCList: string;
  filterStartDate: string;
  filterEndDate: string;
}

export const Campaigns = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setModal, setYesNoModal, clearYesNoModal } = useModalContext();
  const { userData, setUser, selectedOrganizationId } = useContextUser();

  const [isCondensed, setIsCondensed] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [campaign, setCampaign] = useState<CampaignLine[] | undefined>(
    undefined,
  );
  const [filteredCampaign, setFilteredCampaign] = useState<
    CampaignLine[] | undefined
  >(undefined);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    searchFilter: '',
    filterCList: '',
    filterStartDate: '',
    filterEndDate: '',
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    updateCampaigns([name, value]);
  };

  const handleDeleteCampaign = async (campaignId: string) => {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (!campaignId || !selectedOrganization) return;

    const response = await api.campaign.delete(
      {
        clientId: selectedOrganization?.clientId,
      },
      campaignId,
    );

    if (response?.error) {
      setModal(
        t('fetch.campaign.delete.failure.title', { ns: 'common' }),
        t('fetch.campaign.delete.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
      console.log('Error: ', response.error);
    } else {
      setUser(response);
      updateCampaigns(
        ['', ''],
        response.clients.find((c: any) => c.clientId === selectedOrganizationId)
          .campaigns,
        true,
      );
      setModal(
        t('fetch.campaign.delete.success.title', { ns: 'common' }),
        t('fetch.campaign.delete.success.text', { ns: 'common' }),
        FeedbackType.success,
        true,
      );
    }
  };

  const handleOpenStatistics = () => {
    setModalType(ModalType.statistics);
    setIsOpenModal(true);
  };

  const updateCampaigns = (
    searchFilter: string[],
    campaigns: Campaign[] | undefined = undefined,
    overwrite: boolean = false,
  ) => {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (!selectedOrganization) return;

    let updatedCampaigns: Campaign[] | undefined;
    if (overwrite && !campaigns) updatedCampaigns = [];
    else if (campaigns) updatedCampaigns = campaigns;
    else updatedCampaigns = selectedOrganization?.campaigns;

    const campaign: CampaignLine[] | undefined = updatedCampaigns?.map(
      (campaign, index) => {
        const entry: CampaignLine = {
          campaignShortId: `#${index + 1}`,
          campaignInternalId: campaign.campaignId,
          campaignName:
            campaign.campaignName.length > 20
              ? `${campaign.campaignName.slice(0, 20)}...`
              : campaign.campaignName,
          contactList:
            selectedOrganization?.contactLists?.find(
              (cList) => cList.listId === campaign.contactList,
            )?.nameInternal || campaign.contactList,
          subscribers: campaign.subscribers ?? 0,
          date: new Date(campaign.draftDate),
          broadcast: campaign.broadcast,
        };
        return entry;
      },
    );

    const fCampaign = campaign?.filter((entry: any) => {
      /* Filter by search input */
      /* const searchInputFilter = Object.entries(entry)?.filter(([key, value]) =>
        ["campaignName", "contactList"].includes(key) &&
        ["number", "string"].includes(typeof value)
          ? String(value)?.includes(searchFilter[1])
          : false
      ); */
      const nameFilter = ['campaignName', 'contactList'].some((e) => {
        return String(entry[e])
          ?.toLowerCase()
          .includes(searchFilter[1].toLowerCase());
      });

      /* Filter by contact list */
      // const cListFilter = ["contactList"].some((e) => {
      //   return String(entry[e])
      //     ?.toLowerCase()
      //     .includes(searchFilter[1].toLowerCase());
      // });
      // console.log(
      //   "🚀 ~ file: Campaigns.tsx:167 ~ fCampaign ~ cListFilter:",
      //   cListFilter
      // );

      // /* Filter by start date */
      // const startDateFilter = Object.entries(entry)?.filter(([key, value]) => {
      //   return ["date"].includes(key) && new Date(searchFilter[1]) <= value;
      // });
      // console.log(
      //   "🚀 ~ file: Campaigns.tsx:173 ~ startDateFilter ~ startDateFilter:",
      //   startDateFilter
      // );

      // /* Filter by end date */
      // const endDateFilter = Object.entries(entry)?.filter(([key, value]) => {
      //   return ["date"].includes(key) && new Date(searchFilter[1]) >= value;
      // });
      // console.log(
      //   "🚀 ~ file: Campaigns.tsx:179 ~ endDateFilter ~ endDateFilter:",
      //   endDateFilter
      // );

      // return (
      //   searchInputFilter && cListFilter && startDateFilter && endDateFilter
      // );
      return nameFilter;
    });

    searchFilter[0] === 'searchFilter' && setSearchFilter(searchFilter[1]);
    setCampaign(campaign);
    setFilteredCampaign(fCampaign);
  };

  const attemptToDeleteCampaign = (campaignId: string) => {
    setYesNoModal(
      t('campaigns.delete.title', { ns: 'common' }),
      t('campaigns.delete.text', { ns: 'common' }),
      () => {
        handleDeleteCampaign(campaignId);
        clearYesNoModal();
      },
      () => clearYesNoModal(),
      t('yesNoModal.delete', { ns: 'common' }),
      t('yesNoModal.cancel', { ns: 'common' }),
    );
  };

  useEffect(() => {
    isFutureCampaign(new Date(2023, 1, 23));
  }, [selectedOrganizationId]);

  useEffect(() => {
    updateCampaigns(['', '']);
  }, [selectedOrganizationId]);

  return (
    <>
      <Frame name={'campaigns'} scope={TabsScope.broadcast}>
        <div className='relative md:w-full flex items-center md:px-2 lg:px-6 pb-8 max-w-6xl'>
          <div className='px-2 md:px-0 center flex-col w-full'>
            <section className='flex flex-col w-full'>
              <div className='flex'>
                <h1 className='mt-2 w-full justify-between mb-3 ml-4'>
                  {t(`menu.campaigns`, { ns: 'common' })}
                </h1>
                <Link to={broadcastCampaignAddUrl}>
                  <Button
                    type={ButtonType.backgroundNone}
                    size={ButtonSize.clear}
                    className={`mx-2 mb-1 flex p-0 m-0 disabled:shadow-lg cursor:pointer  `}
                  >
                    <Icon
                      icon={faPlus as IconProp}
                      shadowIcons={true}
                      fontSize='text-base'
                      textColor='text-gold-400'
                      fontMobile='text-base'
                      className='w-8 h-8'
                    />
                  </Button>
                </Link>
              </div>

              {/*<div className="w-96">
                <SearchInput
                  searchFilter={searchFilter}
                  handleChange={handleChange}
                />
              </div>

              <div className="px-4 mb-8">
                <div className="text-gray-semidark flex pb-2">
                  <FontAwesomeIcon size="1x" icon={faFilter as IconProp} className="mr-3" />
                  <h5 className="font-medium">
                    {t("filter", { ns: "common" })}
                  </h5>
                </div>
                <div className="flex items-center">
                  {campaign && (
                    <div className="w-96 mr-5">
                      <select
                        id="contactList"
                        name="filterCList"
                        onChange={(e) => handleChange(e)}
                      >
                        <option id="" value="">
                          {t("menu.contactList", { ns: "common" })}
                        </option>
                        {userData?.client?.contactLists?.map(
                          (cList: ContactList) => {
                            return (
                              <option id={cList.listId.toString()}>
                                {cList.nameInternal}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  )}

                  <div className="w-64">
                    <input
                      id="filterStartData"
                      type="date"
                      name="filterStartDate"
                      className="w-full block h-8 border-2 text-gray-light50 border-gray-extralight60 opacity-1 h-5 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent"
                      placeholder={t("search", { ns: "common" })}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <span className="px-3 text-gray-light50">
                    {t("to", { ns: "common" })}
                  </span>
                  <div className="w-64">
                    <input
                      id="filterEndData"
                      type="date"
                      name="filterEndDate"
                      className="w-full block h-8 border-2 text-gray-light50 border-gray-extralight60 opacity-1 h-5 focus:border-gray-light50 appearance-none outline-none focus:outline-none focus:ring-transparent"
                      placeholder={t("search", { ns: "common" })}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>*/}

              <table className='table-auto text-left border-collapse border-8 border-gray-extralight15 text-gray-light50 mt-8 mb-10 ml-2'>
                <thead className='border-8 border-gray-extralight15'>
                  <tr className='border-8 border-gray-extralight15'>
                    <th className='bg-white py-3 px-6 text-center hidden lg:table-cell'>
                      {t('campaigns.campaignId', { ns: 'common' })}
                    </th>
                    <th className='bg-white py-3  pl-6 px-2 lg:px-6'>
                      {t('campaigns.campaignName', { ns: 'common' })}
                    </th>
                    <th className='bg-white py-3 px-2 lg:px-6 text-center'>
                      {t('campaigns.list', { ns: 'common' })}
                    </th>
                    <th className='bg-white py-3 px-2 lg:px-6 text-center hidden lg:table-cell'>
                      {t('campaigns.subscribers', { ns: 'common' })}
                    </th>
                    <th className='bg-white py-3 px-2 lg:px-6 text-center'>
                      {t('campaigns.date', { ns: 'common' })}
                    </th>
                    <th className='bg-white py-3 px-2 lg:px-6 text-center'></th>
                  </tr>
                </thead>
                {campaign && campaign.length > 0 && (
                  <tbody>
                    {filteredCampaign?.map((campaignLine: CampaignLine) => {
                      return (
                        <CampaignTableRow
                          rowData={campaignLine}
                          onStatisticsClick={handleOpenStatistics}
                          onDeleteClick={attemptToDeleteCampaign}
                        />
                      );
                    })}
                  </tbody>
                )}
                <tbody></tbody>
              </table>

              {filteredCampaign?.length === 0 && (
                <div className='mt-2 w-full mb-3 ml-4'>
                  {t('campaigns.noFilter', { ns: 'common' })}
                </div>
              )}
            </section>
          </div>
        </div>
      </Frame>

      {/*Modals*/}

      {isOpenModal && modalType !== null && (
        <>
          {modalType === ModalType.delete && (
            <PopUp
              closeModal={() => setIsOpenModal(false)}
              size={PopupSize.smallxs}
            >
              <DeleteModal
                title={t('settings.campaignDelete', { ns: 'common' })}
                textBtnCancel={t('action_cancel', { ns: 'common' })}
                textBtnSubmit={t('action_delete', { ns: 'common' })}
                setShowModal={setIsOpenModal}
              />
            </PopUp>
          )}
          {/* {modalType === ModalType.statistics && (
            <PopUp size={PopupSize.largeXL}>
              <div className="text-left py-4 mb-10 max-w-full">
                <div className="flex justify-between items-center">
                  <h1 className="font-medium text-lg">
                    {t("campaigns.campaignStatistics", { ns: "common" })}
                  </h1>
                  <Link to={broadcastCampaignStatisticUrl}>
                    <Button
                      type={ButtonType.backgroundNone}
                      size={ButtonSize.clear}
                      className={`mx-2 flex p-0 m-0 disabled:shadow-lg cursor:pointer`}
                    >
                      <IconSettings
                        icon="external-link-alt"
                        shadowIcons={true}
                        iconStyle="fas"
                        fontSize="text-base"
                        textColor="text-gold-400 "
                        fontMobile="text-base"
                        className="w-8 h-8"
                      />
                    </Button>
                  </Link>
                </div>
                <h3 className="font-medium text-left text-gray-light50 pb-10 pt-3">
                  {campaignName}
                </h3>
                <AreaChart
                  width={650}
                  height={300}
                  data={data}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis type="number" domain={["0", "1000"]} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Legend verticalAlign="top" iconType="rect" height={50} />
                  <ChartTooltip />
                  <Area
                    type="monotone"
                    dataKey="Sent"
                    stroke="#e8d7c5"
                    fill="#e8d7c5"
                  />
                  <Area
                    type="monotone"
                    dataKey="Open"
                    stroke="#cc9966"
                    fill="#d7ba99"
                  />
                  <Area
                    type="monotone"
                    dataKey="Click Rate"
                    stroke="#957552"
                    fill="#b28c62"
                  />
                  <Area
                    type="monotone"
                    dataKey="Unsubscribe Rate"
                    stroke="#6b7280"
                    fill="#6b7280"
                  />
                </AreaChart>
              </div>
              <div className="w-full flex justify-end">
                <Button
                  onClick={() => setIsOpenModal(false)}
                  type={ButtonType.cancel}
                  size={ButtonSize.medium}
                  justify={ButtonJustify.center}
                >
                  {t("action_close", { ns: "common" })}
                </Button>
              </div>
            </PopUp>
          )} */}
        </>
      )}
    </>
  );
};

const CampaignTableRow: React.FC<{
  rowData: CampaignLine;
  onStatisticsClick: MouseEventHandler;
  onDeleteClick: (campaignId: string) => void;
}> = ({ rowData, onStatisticsClick, onDeleteClick }) => {
  return (
    <tr className='border-2 border-gray-extralight15'>
      <td className='bg-white py-2pr-2 lg:px-6 text-center hidden lg:table-cell'>
        {rowData.campaignShortId}
      </td>
      <td className='bg-white py-2 pl-6 pr-2 lg:px-6 text-left'>
        {rowData.campaignName}
      </td>
      <td className='bg-white py-2 px-2 lg:px-6 text-center'>
        {rowData.contactList}
      </td>
      <td className='bg-white py-2 px-2 lg:px-6 text-center hidden lg:table-cell'>
        {rowData.subscribers}
      </td>
      <td className='bg-white py-2 px-2 lg:px-6 text-center'>
        {rowData.date.toLocaleDateString()}
      </td>
      <td className='bg-white py-2 px-2 lg:px-6 text-center'>
        <div className='flex justify-center'>
          {isFutureCampaign(rowData.date) && (
            <Tooltip
              content={t('campaigns.noStatistics', { ns: 'common' })}
              tooltipStyle={TooltipStyle.dark}
            >
              <Button
                onClick={onStatisticsClick}
                className='mx-1.5 flex p-0 m-0 '
                type={ButtonType.backgroundNone}
                size={ButtonSize.clear}
                disabled={true}
              >
                <Icon
                  icon={faChartBar as IconProp}
                  shadowIcons={true}
                  textColor='text-gold-400'
                  fontSize='text-sm'
                />
              </Button>
            </Tooltip>
          )}
          {/* {!isFutureCampaign(rowData.date) && (
            <Button
              onClick={onStatisticsClick}
              className="mx-1.5 flex p-0 m-0 "
              type={ButtonType.backgroundNone}
              size={ButtonSize.clear}
            >
              <IconSettings
                icon="chart-bar"
                iconStyle="fas"
                shadowIcons={true}
                textColor="text-gold-400 "
                fontSize="text-sm"
              />
            </Button>
          )} */}
          <Link
            to={`${broadcastCampaignEditUrl}/${rowData.campaignInternalId}`}
          >
            <Button
              className='mx-1.5 flex p-0 m-0 '
              type={ButtonType.backgroundNone}
              size={ButtonSize.clear}
            >
              <Icon
                icon={(!rowData.broadcast ? faPen : faEye) as IconProp}
                shadowIcons={true}
                textColor='text-gold-400'
                // fontSize={!rowData.broadcast ? "text-1x" : "text-sm"}
              />
            </Button>
          </Link>
          {/*
          //Uncomment when duplicate will working
          <Link to={broadcastCampaignAddUrl}>
            <Button
              className="mx-1.5 flex p-0 m-0 "
              type={ButtonType.backgroundNone}
              size={ButtonSize.clear}
            >
              <IconSettings
                icon="clone"
                iconStyle="fas"
                shadowIcons={true}
                textColor="text-gold-400 "
                fontSize="text-xs"
              />
            </Button>
          </Link>
           */}
          <Button
            onClick={() => onDeleteClick(rowData.campaignInternalId)}
            className='mx-1.5 flex p-0 m-0 '
            type={ButtonType.backgroundNone}
            size={ButtonSize.clear}
          >
            <Icon
              icon={faTrash as IconProp}
              fontSize='text-xs'
              shadowIcons={true}
              textColor='text-gold-400'
            />
          </Button>
        </div>
      </td>
    </tr>
  );
};
