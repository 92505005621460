import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../../components/Button';
import Input from '../../components/forms/Input';
import Frame, { Scope as TabsScope } from '../../components/layout/Frame';
import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { Clients } from '../../models/Interfaces';
import { api } from '../../shared';
import { getValidationResult, validateObject } from '../../utils/validation';

export enum TopCardsTypes {
  subscribedAlerts = 'subscribedAlerts',
  wallets = 'wallets',
  socialAccounts = 'socialAccounts',
  contacts = 'contacts',
}

interface FormData {
  companyName: string;
  street1: string;
  street2: string;
  postalCode: string;
  city: string;
  country: string;
  vat: string;
  emailContact: string;
}

export const BillingSettings: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { setModal } = useModalContext();
  const { userData, setUser, selectedOrganizationId } = useContextUser();
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  /*Form variables*/
  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    street1: '',
    street2: '',
    postalCode: '',
    city: '',
    country: '',
    vat: '',
    emailContact: '',
  });

  const [formValidation, setFormValidation] = useState<FormData>({
    companyName: '',
    street1: '',
    street2: '',
    postalCode: '',
    city: '',
    country: '',
    vat: '',
    emailContact: '',
  });

  /*Form functions*/
  const handleFormChange = async (name: string, value: string) => {
    validateForm({ [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  /*Validation functions*/
  const validateForm = (fields: any = undefined) => {
    //Create updated validation (based on updated form)
    let updatedValidation;
    if (fields)
      updatedValidation = {
        ...formValidation,
        ...validateObject(t, fields, 'billingSettings.'),
      };
    else updatedValidation = validateObject(t, formData, 'billingSettings.');

    //Update the form validation
    setFormValidation({ ...formValidation, ...updatedValidation });

    //Return the result of the form validation
    return getValidationResult(updatedValidation);
  };

  /*CRUD functions*/
  const handleSaveSettings = async () => {
    //Validate the form
    const isFormInvalid = validateForm();
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (isFormInvalid || !selectedOrganization) return;
    setIsLoading(true);
    const response = await api.client.update(
      {
        companyName: formData.companyName,
        address: {
          street1: formData.street1,
          street2: formData.street2,
          postalCode: formData.postalCode,
          city: formData.city,
          country: formData.country,
        },
        billingContact: formData.emailContact,
        vat: formData.vat,
      },
      selectedOrganization.clientId,
    );

    if (response?.error) {
      setModal(
        t('fetch.billingSettings.update.failure.title', { ns: 'common' }),
        t('fetch.billingSettings.update.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
      console.log('Error: ', response.error);
    } else {
      setUser(response);
      setModal(
        t('fetch.billingSettings.update.success.title', { ns: 'common' }),
        t('fetch.billingSettings.update.success.text', { ns: 'common' }),
        FeedbackType.success,
        true,
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );
    if (selectedOrganization)
      setFormData({
        companyName: selectedOrganization?.companyName
          ? selectedOrganization.companyName
          : '',
        street1: selectedOrganization?.address?.street1
          ? selectedOrganization.address.street1
          : '',
        street2: selectedOrganization?.address?.street2
          ? selectedOrganization.address.street2
          : '',
        postalCode: selectedOrganization?.address?.postalCode
          ? selectedOrganization.address.postalCode
          : '',
        city: selectedOrganization?.address?.city
          ? selectedOrganization.address.city
          : '',
        country: selectedOrganization?.address?.country
          ? selectedOrganization.address.country
          : '',
        vat: selectedOrganization?.vat ? selectedOrganization.vat : '',
        emailContact: selectedOrganization?.billingContact
          ? selectedOrganization.billingContact
          : '',
      });
  }, [userData, selectedOrganizationId]);

  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <Frame name={'billing'} scope={TabsScope.settings}>
      <div className='relative md:w-full flex items-center md:px-6 px-4 sm:max-w-6xl'>
        <div className='sm:relative flex flex-col w-full md:w-1/2 mx-4 gap-6'>
          <h1 className='mt-2 mb-10 w-full justify-between mb-3'>
            {t(`settings.billingSettings`, { ns: 'common' })}
          </h1>

          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.companyName', { ns: 'common' })}
            </h5>
            <div className='mt-2'>
              <Input
                onChange={(e) =>
                  handleFormChange('companyName', e.target.value)
                }
                value={formData.companyName}
                error={formValidation.companyName}
                maxLength={50}
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <h5 className='font-medium '>
              {t('settings.address', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) => handleFormChange('street1', e.target.value)}
              value={formData.street1}
              error={formValidation.street1}
              placeholder={`${t('settings.street', { ns: 'common' })} 1`}
            />
            <Input
              onChange={(e) => handleFormChange('street2', e.target.value)}
              value={formData.street2}
              error={formValidation.street2}
              placeholder={`${t('settings.street', { ns: 'common' })} 2`}
            />
            <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
              <Input
                onChange={(e) => handleFormChange('postalCode', e.target.value)}
                value={formData.postalCode}
                error={formValidation.postalCode}
                className=''
                placeholder={`${t('settings.postalCode', { ns: 'common' })}`}
              />
              <Input
                onChange={(e) => handleFormChange('city', e.target.value)}
                value={formData.city}
                error={formValidation.city}
                className=''
                placeholder={`${t('settings.city', { ns: 'common' })}`}
              />
              <Input
                onChange={(e) => handleFormChange('country', e.target.value)}
                value={formData.country}
                error={formValidation.country}
                className='w-full md:w-1/3 mr-2'
                placeholder={`${t('settings.country', { ns: 'common' })}`}
              />
            </div>
          </div>
          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.vat', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) => handleFormChange('vat', e.target.value)}
              value={formData.vat}
              error={formValidation.vat}
            />
          </div>

          <div className=''>
            <h5 className='font-medium mb-2'>
              {t('settings.contact', { ns: 'common' })}
            </h5>
            <Input
              onChange={(e) =>
                handleFormChange('emailContact', e.target.value)
              }
              value={formData.emailContact}
              error={formValidation.emailContact}
              placeholder={`${t('settings.email', { ns: 'common' })}`}
            />
          </div>
          <div className='button-wrapper mt-12 md:mt-20 flex '>
            <div className='w-32 mr-3'>
              <Button
                type={ButtonType.primary}
                size={ButtonSize.medium}
                justify={ButtonJustify.center}
                fullWidth={true}
                onClick={handleSaveSettings}
              >
                {t('action_save', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
};
