import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faExclamation,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, ButtonJustify, ButtonSize, ButtonType } from '../Button';

export enum FeedbackIcon {
  success = 'success',
  failure = 'failure',
  warning = 'warning',
  custom = 'custom',
}

export type Props = {
  title?: string;
  text?: string;
  additionalText?: string;
  closeBtn?: boolean | string;
  onClose?: Function;
  icon?: FeedbackIcon;
  customIcon?: React.ReactNode;
  additionalJsx?: React.ReactNode;
  colorIcon?: string;
};

export const FeedbackModal = ({
  title,
  text,
  additionalText,
  closeBtn,
  onClose,
  icon,
  customIcon,
  additionalJsx,
  colorIcon,
}: Props) => {
  const { t } = useTranslation(['common']);
  const getIconColor = () => {
    if (colorIcon) return 'bg-gray-dark';
    else if (icon === FeedbackIcon.success) return `bg-green`;
    else if (icon === FeedbackIcon.failure) return `bg-red`;
    else if (icon === FeedbackIcon.warning) return `bg-yellow`;
    else if (icon === FeedbackIcon.custom) return `bg-gold-400`;
  };

  const getCloseButton = () => {
    if (closeBtn)
      return (
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.medium}
          justify={ButtonJustify.center}
          onClick={() => onClose && onClose()}
        >
          {typeof closeBtn === 'string'
            ? closeBtn
            : t('feedbackModal.close', { ns: 'common' })}
        </Button>
      );
  };

  return (
    <div className='text-center px-4'>
      <div className='flex grow-0 justify-center text-white'>
        <span
          className={`w-10 h-10 mb-4 leading-9 p-1 text-xl rounded-full text-center ${getIconColor()}`}
        >
          {icon === FeedbackIcon.success && (
            <FontAwesomeIcon icon={faCheck as IconProp} />
          )}
          {icon === FeedbackIcon.failure && (
            <FontAwesomeIcon icon={faTimes as IconProp} />
          )}
          {icon === FeedbackIcon.warning && (
            <FontAwesomeIcon icon={faExclamation as IconProp} />
          )}
          {icon === FeedbackIcon.custom && <>{customIcon}</>}
        </span>
      </div>
      <h3 className='mb-4'>{title ?? 'Title'}</h3>
      <p className='text-lg mb-4'>{text ?? ''}</p>
      {additionalText && <p className='text-lg mb-4'>{additionalText}</p>}
      <div className='flex justify-center items-center'>{getCloseButton()}</div>
      {additionalJsx && <>{additionalJsx}</>}
    </div>
  );
};
