import { APIService } from './api.service';

export class CampaignService extends APIService {
  constructor() {
    super();
  }

  public async create(data: any): Promise<any> {
    return await this._post(`/fauna/business/campaign`, data);
  }

  public async delete(data: any, campaignId: string): Promise<any> {
    return await this._put(`/fauna/business/campaign/${campaignId}`, data);
  }

  public async update(data: any, campaignId: string): Promise<any> {
    return await this._patch(`/fauna/business/campaign/${campaignId}`, data);
  }

  public async preview(data: any): Promise<any> {
    return await this._post(`/fauna/business/campaign/preview`, data);
  }
}
