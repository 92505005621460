import {
  Clients,
  ContactListForm,
  ContactListLine,
  CreateContactListRequest,
  UpdateContactListRequest,
  UserData,
} from '../models/Interfaces';
import { api } from '../shared';
import { ContactListScope } from '../views/ContactList';
import { instanceOfUserData } from './instanceOf';

/*----------------------------------------------------------------*/
/*Images*/
export const handleImageSelected = async (
  e: React.ChangeEvent<HTMLInputElement>,
) => {
  if (e.target.files) {
    const uploadData = new FormData();
    const files = e.target.files[0];
    const acceptedExtensions = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/webp',
    ];
    if (!acceptedExtensions.includes(files.type)) return 1;
    uploadData.append('file', files);

    const response = await api.file.upload();

    /* Having the upload url, send request directly to CF */
    try {
      const connectionToCF = await fetch(response.uploadUrl, {
        method: 'POST',
        body: uploadData,
      });
      const responseCF = await connectionToCF.json();
      if (responseCF.success) {
        const imagesArr = responseCF.result.variants;
        const imageUrl =
          imagesArr.filter(
            (i: string) => i.split('/').splice(-1)[0] === 'public',
          )[0] || imagesArr[0];

        return imageUrl;
      }
      return undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  } else return;
};
/*----------------------------------------------------------------*/
/*Contact Lists*/
export const getContactLists: (
  userData: UserData,
  selectedOrganizationId: string | null,
) => ContactListLine[] | undefined = (userData, selectedOrganizationId) => {
  const selectedOrganization: Clients | undefined = userData?.clients?.find(
    (a) => a.clientId === selectedOrganizationId,
  );
  return selectedOrganization?.contactLists?.map((contactList) => {
    const entry: ContactListLine = {
      listId: contactList.listId,
      listName: contactList.namePublic,
      nameInternal: contactList.nameInternal,
      description: contactList.description,
      imageUrl: contactList.imageUrl,
      campaignsSent: '-',
      totalWallets: contactList?.subscribersAmount?.total
        ? contactList.subscribersAmount.total
        : 0,
      discordWallets: contactList?.subscribersAmount?.discord
        ? contactList.subscribersAmount.discord
        : 0,
      telegramWallets: contactList?.subscribersAmount?.telegram
        ? contactList.subscribersAmount.telegram
        : 0,
      broadcast: contactList.broadcast,
    };
    return entry;
  });
};

export const createContactList: (
  userData: UserData,
  selectedOrganizationId: string | null,
  formData: ContactListForm,
) => Promise<UserData | null> = async (
  userData,
  selectedOrganizationId,
  formData,
) => {
  const selectedOrganization: Clients | undefined = userData?.clients?.find(
    (a) => a.clientId === selectedOrganizationId,
  );
  if (!userData || !selectedOrganization) return null;
  //Create request
  let newContactList: CreateContactListRequest = {
    userId: String(userData?.userId),
    clientId: String(selectedOrganization?.clientId),
    nameInternal: formData.nameInternal,
    namePublic: formData.namePublic,
    description: formData.description,
    broadcast:
      formData.scope === ContactListScope.broadcast || !formData.scope
        ? true
        : false,
  };
  if (formData.imageUrl) newContactList.imageUrl = formData.imageUrl;

  const response = await api.contactList.create(newContactList);
  //Return data
  if (response?.status === 200 || response?.status === 201) {
    if (typeof response === 'object' && instanceOfUserData(response))
      return response;
    else return null;
  } else return null;
};

export const updateContactList: (
  userData: UserData,
  selectedOrganizationId: string | null,
  formData: ContactListForm,
  id: string,
) => Promise<UserData | null> = async (
  userData,
  selectedOrganizationId,
  formData,
  id,
) => {
  const selectedOrganization: Clients | undefined = userData?.clients?.find(
    (a) => a.clientId === selectedOrganizationId,
  );
  if (!userData || !selectedOrganization) return;
  let updatedContactList: UpdateContactListRequest = {
    userId: String(userData.userId),
    clientId: String(selectedOrganization?.clientId),
    nameInternal: formData.nameInternal,
    namePublic: formData.namePublic,
    description: formData.description,
  };
  if (formData.imageUrl) updatedContactList.imageUrl = formData.imageUrl;

  const response = await api.contactList.update(updatedContactList, id);

  //Return data
  if (response?.status === 200 || response?.status === 201) return response;
  else return null;
};

/*----------------------------------------------------------------*/
/*Blockchains*/
export const getBlockchainDataFromDB: () => Promise<any> = async () => {
  try {
    const connection = await fetch(
      `${import.meta.env.VITE_API_URL}/fauna/notifications/`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const dataFromApi = await connection.json();
    if (dataFromApi?.error) throw new Error();
    return dataFromApi.chains;
  } catch (error) {
    console.log('Error: ', error);
    return null;
  }
};
