import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Button as ButtonCN } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { X } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import BottomMenu from '../../components/BottomMenu';
import Breadcrumb, { breadcrumbStructure } from '../../components/Breadcrumb';
import {
  Button,
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../../components/Button';
import { ImageUploader } from '../../components/ImageUploader';
import Loader, { LoaderType } from '../../components/Loader';
import { Navbar } from '../../components/Navbar';
import { PopupSize } from '../../components/Popup';
import RightSection from '../../components/RightSection';
import { InputSelectOption } from '../../components/forms/Input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '../../components/ui/form';
import { Input as InputCN } from '../../components/ui/input';
import { FeedbackType, useModalContext } from '../../contexts/modal-handling';
import { useContextUser } from '../../contexts/user';
import { Clients, ContactListLine } from '../../models/Interfaces';
import { api } from '../../shared';
import { getContactLists, handleImageSelected } from '../../utils/functions';
import { instanceOfUserData } from '../../utils/instanceOf';
import { broadcastCampaignsUrl } from '../../utils/menuUtils';
import QuillEditor from '@/components/quill-editor';

interface FormData {
  subject: string;
  subjectLink: string;
  campaignName: string;
  contactList: string;
  content: string;
  extraUrls: { name: string; url: string }[];
  imageUrls: string[];
}

export const AddCampaign: React.FC = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setModal, setInfoModal } = useModalContext();
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);
  const { userData, setUser, selectedOrganizationId } = useContextUser();
  const navigate = useNavigate();

  const form = useForm<FormData>({
    defaultValues: {
      subject: '',
      subjectLink: '',
      campaignName: '',
      contactList: '',
      content: '',
      extraUrls: [
        {
          name: '',
          url: '',
        },
      ],
      imageUrls: [],
    },
  });

  const extraUrlsFields = useFieldArray({
    control: form.control,
    name: 'extraUrls',
  });

  const [uploadingImage, setUploadingImage] = useState(false);
  const [disableDraft, setDisableDraft] = useState<boolean>(true);
  const [hasDraft, setHasDraft] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { campaignName, contactList, extraUrls } = form.getValues();

  const breadcrumb: breadcrumbStructure[] = [
    {
      label: t('breadcrumb.broadcast', { ns: 'enumerations' }),
    },
    {
      label: t('breadcrumb.campaigns', { ns: 'enumerations' }),
      url: broadcastCampaignsUrl,
    },
    { label: t('breadcrumb.create', { ns: 'enumerations' }) },
  ];

  //Get contact lists
  //Get contact list options
  const contactLists: ContactListLine[] | undefined = userData
    ? getContactLists(userData, selectedOrganizationId)
    : undefined;
  let contactListOptions: InputSelectOption[] | undefined = contactLists?.map(
    (contactList) => {
      return {
        value: contactList.listId.toString(),
        text: contactList.listName,
      };
    },
  );

  useEffect(() => {
    setNavbarWidth(document.getElementById('sideMenu')?.clientWidth || 0);
  }, [isCondensed]);

  useEffect(() => {
    if (campaignName && contactList) setDisableDraft(false);

    if (extraUrls.at(-1)?.name || extraUrls.at(-1)?.url)
      extraUrlsFields.fields.length < 5 &&
        extraUrlsFields.append(
          {
            name: '',
            url: '',
          },
          {
            shouldFocus: false,
          },
        );
  }, [form.formState, selectedOrganizationId]);

  const handleShowExtraURLInfo = () => {
    setInfoModal(
      '',
      <div className='text-left py-4'>
        <p className='mb-5'>
          {t('campaigns.popupExtraURLMessage', { ns: 'common' })}
        </p>
        <div className='flex justify-center mb-4'>
          <img
            className='lg:max-w-xl'
            src='/images/information-modals/campaign-buttons.svg'
            alt='Contact List message example'
          />
        </div>
      </div>,
    );
  };

  const handleShowInfo = () => {
    setInfoModal(
      '',
      <div className='text-left py-4'>
        <p className='mb-5'>{t('campaigns.popupMessage', { ns: 'common' })}</p>
        <div className='flex justify-center mb-4'>
          <img
            className='lg:max-w-xl'
            src='/images/information-modals/campaign.svg'
            alt='Contact List message example'
          />
        </div>
      </div>,
    );
  };

  //Form change events
  const handleFormImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUploadingImage(true);
    const imageUrl = await handleImageSelected(e);
    if (imageUrl === undefined)
      setModal(
        t('fetch.upload.image.failure.title', { ns: 'common' }),
        t('fetch.upload.image.failure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
    else if (imageUrl === 1)
      setModal(
        t('fetch.upload.image.extensionFailure.title', { ns: 'common' }),
        t('fetch.upload.image.extensionFailure.text', { ns: 'common' }),
        FeedbackType.failure,
        true,
      );
    else form.setValue('imageUrls', [imageUrl]);

    setUploadingImage(false);
  };

  async function saveDraft({
    campaignName,
    contactList,
    subject,
    subjectLink,
    content,
    imageUrls,
    extraUrls,
  }: FormData) {
    const selectedOrganization: Clients | undefined = userData?.clients?.find(
      (a) => a.clientId === selectedOrganizationId,
    );

    if (campaignName && contactList) {
      const response = await api.campaign.create({
        campaignName,
        contactList,
        subject,
        subjectLink,
        content,
        imageUrls,
        extraUrls: extraUrls.filter((a) => a.name !== '' || a.url !== ''),
        userId: userData?.userId,
        clientId: selectedOrganization?.clientId,
        broadcast: false,
      });

      if (response.status === 200 || response.status === 201) {
        if (typeof response === 'object' && instanceOfUserData(response)) {
          setUser && setUser(response);
        } else {
          setModal(
            t('fetch.campaign.create.failure.title', { ns: 'common' }),
            t('fetch.campaign.create.failure.text', { ns: 'common' }),
            FeedbackType.failure,
            true,
          );
        }
        setModal(
          t('fetch.campaign.create.success.title', { ns: 'common' }),
          t('fetch.campaign.create.success.text', { ns: 'common' }),
          FeedbackType.success,
          true,
          () => navigate(broadcastCampaignsUrl),
        );
        setHasDraft(true);
      } else
        setModal(
          t('fetch.campaign.create.failure.title', { ns: 'common' }),
          t('fetch.campaign.create.failure.text', { ns: 'common' }),
          FeedbackType.failure,
          true,
        );

      setIsLoading(false);
    } else {
      //alert para preencher todos campos
      setModal(
        t('modal_content.warning.title', { ns: 'enumerations' }),
        t('empty_fields', { ns: 'common' }),
        FeedbackType.warning,
        undefined,
        undefined,
        undefined,
        PopupSize.small,
      );
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <div className='relative md:w-full flex items-center md:px-6 px-4 sm:max-w-6xl'>
          <section className='min-h-screen sm:relative w-full ml-4'>
            <div className=' mb-6'>
              <Breadcrumb list={breadcrumb} />
            </div>
            <h1 className='mt-2 mb-10 w-full flex items-center'>
              <span>{t(`campaigns.addCampaign`, { ns: 'common' })}</span>
              <span
                className={classNames(
                  'ml-3 bg-gray-extralight80 text-white rounded-full h-4.5 w-4.5 cursor-pointer hover:bg-gray-light50 focus:bg-gray-light50 justify-center flex items-center text-xs',
                )}
                onClick={handleShowInfo}
              >
                i
              </span>
            </h1>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(saveDraft)}>
                <div className='w-full mb-6 flex'>
                  {/*Campaign name*/}
                  <div className='w-4/6 mr-5'>
                    <h5 className='font-medium'>
                      {t(`campaigns.campaignName`, { ns: 'common' })}
                      <span className='ml-1 text-sm text-gray-light40'>
                        ({t('internal', { ns: 'common' })})
                      </span>
                    </h5>
                    <FormField
                      control={form.control}
                      name='campaignName'
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className='mt-2'>
                              <InputCN {...field} />
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  {/*Contact lists*/}
                  <div className='w-2/6'>
                    <h5 className='font-medium'>
                      {t('contactList.contactList', { ns: 'common' })}
                    </h5>
                    <FormField
                      control={form.control}
                      name='contactList'
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className='mt-2'>
                              <Select
                                value={field.value}
                                disabled={!contactListOptions?.length}
                                name={field.name}
                                onValueChange={field.onChange}
                              >
                                <SelectTrigger>
                                  <SelectValue
                                    placeholder={
                                      contactListOptions?.length
                                        ? 'Select a Contact List'
                                        : 'Create a Contact List first'
                                    }
                                  />
                                </SelectTrigger>
                                <SelectContent
                                  onBlur={field.onBlur}
                                  ref={field.ref}
                                >
                                  {contactListOptions?.map((option) => (
                                    <SelectItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.text}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                <div className='w-full mb-6 flex'>
                  {/*Subject*/}
                  <div className='w-4/6 mr-5'>
                    <h5 className='font-medium'>
                      {t('campaigns.subject', { ns: 'common' })}
                      <span className='ml-1 text-sm text-gray-light40'>
                        ({t('public_facing', { ns: 'common' })})
                      </span>
                    </h5>
                    <FormField
                      control={form.control}
                      name='subject'
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className='mt-2'>
                              <InputCN {...field} />
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  {/*Subject Link*/}
                  <div className='w-2/6'>
                    <h5 className='font-medium'>
                      {t('campaigns.subjectLink', { ns: 'common' })}
                    </h5>
                    <FormField
                      control={form.control}
                      name='subjectLink'
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className='mt-2'>
                              <InputCN {...field} />
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                {/*Content*/}
                <div className='w-full mb-10'>
                  <h5 className='font-medium'>
                    {t('campaigns.content', { ns: 'common' })}
                  </h5>
                  <FormField
                    control={form.control}
                    name='content'
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className='mt-2'>
                            <QuillEditor
                              {...field}
                            />
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>

                {/*Image URLs*/}
                <ImageUploader
                  imageUrl={form.getValues().imageUrls?.[0]}
                  uploadingImage={uploadingImage}
                  onImageSelected={handleFormImageChange}
                  onRemoveImageClick={() => form.setValue('imageUrls', [])}
                />

                {/*Extra URLs*/}
                <div className='w-full mt-10 mb-6'>
                  <div className='flex items-center mb-3'>
                    <h5 className='font-medium'>
                      {t('campaigns.extraURL', { ns: 'common' })}
                    </h5>
                    <span
                      className={classNames(
                        'ml-3 bg-gray-extralight80 text-white rounded-full h-4.5 w-4.5 cursor-pointer hover:bg-gray-light50 focus:bg-gray-light50 justify-center flex items-center text-xs',
                      )}
                      onClick={handleShowExtraURLInfo}
                    >
                      i
                    </span>
                  </div>
                  <div className='w-3/4'>
                    <FormField
                      control={form.control}
                      name='extraUrls'
                      render={() => (
                        <div className='space-y-4'>
                          {extraUrlsFields.fields.map((field, idx, all) => (
                            <FormItem key={field.id}>
                              <FormControl>
                                <div className='grid grid-cols-6'>
                                  <div className='col-span-2 mr-2'>
                                    <InputCN
                                      placeholder={t('campaigns.name', {
                                        ns: 'common',
                                      })}
                                      {...form.register(
                                        `extraUrls.${idx}.name`,
                                      )}
                                    />
                                  </div>
                                  <div className='col-span-3 mr-2'>
                                    <InputCN
                                      placeholder={t(
                                        'campaigns.URLPlaceholder',
                                        {
                                          ns: 'common',
                                        },
                                      )}
                                      {...form.register(`extraUrls.${idx}.url`)}
                                    />
                                  </div>

                                  {idx !== all.length - 1 && (
                                    <div>
                                      <ButtonCN
                                        size='icon'
                                        variant='ghost'
                                        type='button'
                                        onClick={() =>
                                          extraUrlsFields.remove(idx)
                                        }
                                      >
                                        <X className='size-4' />
                                      </ButtonCN>
                                    </div>
                                  )}
                                </div>
                              </FormControl>
                            </FormItem>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                </div>

                {/*Buttons*/}
                <div className='button-wrapper mt-20'>
                  <div className='flex mb-10'>
                    <div className='w-48 mr-3'>
                      <ButtonCN
                        type='submit'
                        size='lg'
                        disabled={disableDraft || uploadingImage}
                      >
                        {t('save_draft', { ns: 'common' })}
                      </ButtonCN>
                    </div>
                    <Link className='flex' to={broadcastCampaignsUrl}>
                      <Button
                        type={ButtonType.cancel}
                        size={ButtonSize.medium}
                        justify={ButtonJustify.center}
                      >
                        {t('action_cancel', { ns: 'common' })}
                      </Button>
                    </Link>
                  </div>
                </div>
              </form>
            </Form>
          </section>
        </div>
      </RightSection>
      <BottomMenu />
    </>
  );
};
