export enum PlatformType {
  Anchor = 'zb2_0001',
  TerraSwap = 'zb2_0003',
  Astroport = 'zb2_0004',
  Mirror = 'zb2_0006',
  Loop = 'zb2_0005',
  Validators = 'zb2_0011',
  General = 'zb2_0012',
  TFM = 'zb2_0019',
  PhoenixFinance = 'zb2_0020',
  Luart = 'zb2_0008',
  RandomEarth = 'zb2_0024',
  KnowhereArt = 'zb2_0025',
  OnePlanet = 'zb2_0026',
  Talis = 'zb2_0027',
  MessierArt = 'zb2_0028',
  AllDerivatives = 'zb2_9996',
  AllDEX = 'zb2_9997',
  AllNft = 'zb2_9992',
  BackboneLabs = 'zb2_0034',
  LNS = 'zb2_0035',
}

export enum BlockchainType {
  TerraClassic = 'zb1_001',
  Terrav2 = 'zb1_002',
  Near = 'zb1_003',
  Radix = 'zb1_005',
}

export enum DomainOptions {
  uponRenewal = 'renewed',
  uponExpirationWeek = 'weekbefore',
  uponExpiration24 = 'h24before',
  uponExpirationMonth = 'monthbefore',
  uponExpiration48 = 'h48before',
  uponExpiration = 'expired',
}
export enum SocialChannel {
  //Twitter = 'TWITTER',
  Discord = 'DISCORD',
  Telegram = 'TELEGRAM',
}

export enum Social {
  discord = 'discord',
  twitter = 'twitter',
  /*     telegram = 'telegram', */
}

export enum NotAllowed {
  list = 'list',
  cancelList = 'cancelList',
  bid = 'bid',
  cancelBid = 'cancelBid',
  changeBid = 'changeBid',
  auction = 'auction',
  cancelAuction = 'cancelAuction',
}

export enum DomainChangesOptions {
  metadataChanges = 'metadataChanges',
  transfer = 'transfer',
}

export enum ScopeDragAndDrop {
  all = 'all',
  token = 'token',
  nft = 'nft',
}

export enum AvailableTlds {
  luna = 'luna',
}

export enum Status {
  inactive = '0',
  active = '1',
}

export enum ModalType {
  initialConfigFlow = 'initialConfigFlow',
  statistics = 'statistics',
  delete = 'delete',
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum TokenType {
  REFRESH = 'REFRESH',
  ACCESS = 'ACCESS',
}


export enum Plan {
  free = 'free',
  starter = 'starter',
  business = 'business',
  enterprise = 'enterprise',
}
