import { Button, ButtonSize, ButtonType } from '../Button';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextUser } from '../../contexts/user';

export const SocialAccounts = () => {
  const { t } = useTranslation(['common']);
  const { userData } = useContextUser();

  useEffect(() => {
    localStorage.setItem('origin', 'on');
  }, []);

  return (
    <div className='md:px-2 sm:px-0 md:px-4 py-3'>
      <a
        href={`${
          import.meta.env.VITE_OAUTH_URL
        }/?origin=business&action=addSocial&userId=${userData?.userId}`}
      >
        <Button type={ButtonType.secondary} size={ButtonSize.medium} fullWidth>
          <div className='text-gray-light60'>
            <FontAwesomeIcon icon={faUser as IconProp} size='2x' />
          </div>
          <span className='pl-6'>{t(`addSocials`, { ns: 'common' })}</span>
        </Button>
      </a>
    </div>
  );
};
