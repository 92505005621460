import classNames from 'classnames';
import React from 'react';

const RightSection: React.FC<{
  children: React.ReactNode;
  isCondensed: boolean;
}> = ({ children, isCondensed }) => (
  <div
    className={classNames(
      'overflow-x-hidden overflow-y-auto flex-col flex justify-between py-5 w-full lg:ml-72',
      {
        'ml-0 lg:ml-24': isCondensed,
      },
    )}
  >
    {children}
  </div>
);

export default RightSection;
