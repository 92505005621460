import React, { useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonType } from '../Button';

import { useTranslation } from 'react-i18next';

export enum TooltipContent {
  connect = 'connect-info',
  verify = 'verify-info',
}

const LoginSectionButtons: React.FC<{}> = ({}) => {
  const { t } = useTranslation(['common', 'enumerations']);

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    localStorage.setItem('origin', 'off');
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <div className='flex flex-row flex-nowrap	justify-center'>
      <div className='flex flex-col relative '>
        <div className='mt-8 w-full mb-2'>
          <a href={`${import.meta.env.VITE_OAUTH_URL}/?origin=business`}>
            <Button
              type={ButtonType.secondary}
              size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
              fullWidth
              className='py-1.5'
            >
              <div className='text-gray-light60'>
                <img
                  src='images/logo_icon.png'
                  alt='Hermes Protocol'
                  className='w-9'
                />
              </div>
              <span className='pl-6'>{t(`hermesLogin`, { ns: 'common' })}</span>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default LoginSectionButtons;
