import { AuthModalConfig } from '../contexts/modal-handling';
import { UserData } from '../models/Interfaces';

export function instanceOfUserData(object: any): object is UserData {
  return (
    'userId' in object &&
    'isLoggedIn' in object &&
    'jwtToken' in object &&
    'lastLogIn' in object &&
    'userStatus' in object &&
    'activeDate' in object &&
    'clientAlias' in object &&
    'noSocialsPopup' in object &&
    'socials' in object &&
    'wallets' in object &&
    'notifications' in object
  );
}

export function instanceOfAuthFeedModal(
  object: any,
): object is AuthModalConfig {
  return object && 'popupSize' in object;
}
