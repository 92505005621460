export const TransactionalSvg = () => {
  return (
    <svg
      width='30'
      height='33'
      viewBox='0 0 30 33'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_63_4587)'>
        <path d='M13.4141 8.75H10.625H9.21094H8.75V9.09375V10.625V12.2031V15.6797L5.00781 12.9102C5.07031 12.2031 5.43359 11.5469 6.01172 11.1211L6.875 10.4805V8.75C6.875 7.71484 7.71484 6.875 8.75 6.875H11.7422L13.6914 5.43359C14.0703 5.15234 14.5273 5 15 5C15.4727 5 15.9297 5.15234 16.3086 5.42969L18.2578 6.875H21.25C22.2852 6.875 23.125 7.71484 23.125 8.75V10.4805L23.9883 11.1211C24.5664 11.5469 24.9297 12.2031 24.9922 12.9102L21.25 15.6797V12.2031V10.625V9.09375V8.75H20.7891H19.375H16.5859H13.4141ZM5 22.5V14.457L13.5 20.7539C13.9336 21.0742 14.4609 21.25 15 21.25C15.5391 21.25 16.0664 21.0781 16.5 20.7539L25 14.457V22.5C25 23.8789 23.8789 25 22.5 25H7.5C6.12109 25 5 23.8789 5 22.5ZM11.875 11.25H18.125C18.4688 11.25 18.75 11.5312 18.75 11.875C18.75 12.2188 18.4688 12.5 18.125 12.5H11.875C11.5312 12.5 11.25 12.2188 11.25 11.875C11.25 11.5312 11.5312 11.25 11.875 11.25ZM11.875 13.75H18.125C18.4688 13.75 18.75 14.0312 18.75 14.375C18.75 14.7188 18.4688 15 18.125 15H11.875C11.5312 15 11.25 14.7188 11.25 14.375C11.25 14.0312 11.5312 13.75 11.875 13.75Z' />
      </g>
    </svg>
  );
};
