import classNames from 'classnames';
import React from 'react';

export enum ModeType {
  normal = 'normal',
  condensed = 'condensed',
  wide = 'wide',
}
/*By default it is set condensed false mode={isCondensed ? ModeType.condensed : ModeType.normal} 
no set up to change to wide*/ 

const SideMenu: React.FC<{
  mode: ModeType;
  children: React.ReactNode;
}> = ({ mode, children }) => {
  return (
    <div
      id='sideMenu'
      className={classNames(
        'min-h-screen',
        {
          'fixed hidden lg:block w-72 px-2 py-5 bg-white':
            mode == ModeType.normal,
        },
        {
          'relative block w-full lg:w-4/6 xl:w-1/2 2xl:w-35% bg-white':
            mode == ModeType.wide,
        },
      )}
    >
      <div
        className={classNames(
          `h-full`,
          {
            'absolute w-full sidemenu': mode === ModeType.wide,
          },
        )}
      >
        <div
          className={classNames(
            {
              'relative lg:flex w-full h-full flex-col justify-between':
                mode === ModeType.normal,
            },
            {
              'relative w-full h-full pt-8 sm:pt-0 flex flex-col justify-around items-center  md:pb-14':
                mode === ModeType.wide,
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
