export const WebhooksSvg = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.5 21.5C19.4004 21.5 18.5 20.5996 18.5 19.5C18.5 18.4004 19.4004 17.5 20.5 17.5C21.5996 17.5 22.5 18.4004 22.5 19.5C22.5 20.5996 21.5996 21.5 20.5 21.5Z'
        fill='currentColor'
      />
      <path
        d='M20.5 24.5C19 24.5 17.5508 23.8008 16.5996 22.6504L18.1504 21.4004C18.6992 22.0996 19.5996 22.5508 20.5 22.5508C22.1504 22.5508 23.5 21.1992 23.5 19.5508C23.5 17.9004 22.1504 16.5508 20.5 16.5508C20 16.5508 19.5 16.6992 19.0508 16.9004L18.1992 17.4004L14.6504 11L16.4004 10.0508L19.0508 14.75C19.5508 14.5996 20.0508 14.5 20.5508 14.5C23.3008 14.5 25.5508 16.75 25.5508 19.5C25.5508 22.25 23.25 24.5 20.5 24.5Z'
        fill='currentColor'
      />
      <path
        d='M10 24.5C7.25 24.5 5 22.25 5 19.5C5 17.1992 6.55078 15.25 8.75 14.6504L9.25 16.5996C7.94922 16.9492 7 18.1504 7 19.5C7 21.1504 8.34961 22.5 10 22.5C11.6504 22.5 13 21.1504 13 19.5V18.5H20.5V20.5H14.9004C14.4492 22.8008 12.4004 24.5 10 24.5Z'
        fill='currentColor'
      />
      <path
        d='M10 21.5C8.90039 21.5 8 20.5996 8 19.5C8 18.4004 8.90039 17.5 10 17.5C11.0996 17.5 12 18.4004 12 19.5C12 20.5996 11.0996 21.5 10 21.5Z'
        fill='currentColor'
      />
      <path
        d='M15.5 12.5C14.4004 12.5 13.5 11.5996 13.5 10.5C13.5 9.40039 14.4004 8.5 15.5 8.5C16.5996 8.5 17.5 9.40039 17.5 10.5C17.5 11.5996 16.5996 12.5 15.5 12.5Z'
        fill='currentColor'
      />
      <path
        d='M10.8496 20L9.15039 19L12.0996 14.1504C11.0996 13.1992 10.5 11.9004 10.5 10.5C10.5 7.75 12.75 5.5 15.5 5.5C18.25 5.5 20.5 7.75 20.5 10.5C20.5 10.9492 20.4492 11.3496 20.3496 11.75L18.4004 11.25C18.4492 11 18.5 10.75 18.5 10.5C18.5 8.84961 17.1504 7.5 15.5 7.5C13.8496 7.5 12.5 8.84961 12.5 10.5C12.5 11.5508 13.0508 12.5 13.9492 13.0508L14.8008 13.5508L10.8496 20Z'
        fill='currentColor'
      />
    </svg>
  );
};
