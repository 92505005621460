import { APIService } from './api.service';

export class FileService extends APIService {
  constructor() {
    super();
  }

  public async upload(): Promise<any> {
    return await this._get<any>(`/fauna/upload`);
  }
}
